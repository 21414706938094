import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class UserDataService {
    constructor() {
        this.data = new BehaviorSubject({});
        this.userData = this.data.asObservable();
    }
    setUserData(data) {
        this.data.next(data);
    }
}
UserDataService.ngInjectableDef = i0.defineInjectable({ factory: function UserDataService_Factory() { return new UserDataService(); }, token: UserDataService, providedIn: "root" });
