import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
export const firebaseConfigOld = {
    apiKey: 'AIzaSyAl3mr4PdEF2Wr8xgs6FqPAnkq-5rRughY',
    authDomain: 'vplay-product.firebaseapp.com',
    databaseURL: 'https://vplay-product.firebaseio.com',
    projectId: 'vplay-product',
    storageBucket: 'vplay-product.appspot.com',
    messagingSenderId: '622002878280',
    appId: '1:622002878280:web:8faeabf186d25f881ca57d'
};
export const firebaseConfig = {
    apiKey: 'AIzaSyDzqt0W2PjVF_o12HYk2iq_VMKbHONmEMk',
    authDomain: 'sign-in-with-apple-5858d.firebaseapp.com',
    // databaseURL: 'https://vplay-product.firebaseio.com',
    projectId: 'sign-in-with-apple-5858d',
    storageBucket: 'sign-in-with-apple-5858d.appspot.com',
    messagingSenderId: '773583217924',
    appId: '1:773583217924:web:6b739d3c4511f67d59dc51',
    measurementId: 'G-8DZ15PJKPQ'
};
const ɵ0 = GoogleLoginProvider.PROVIDER_ID, ɵ1 = FacebookLoginProvider.PROVIDER_ID;
const config = new AuthServiceConfig([
    {
        id: ɵ0,
        provider: new GoogleLoginProvider(environment.GoogleOAuthClientId)
    },
    {
        id: ɵ1,
        provider: new FacebookLoginProvider(environment.FacebookAppId)
    }
]);
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
export function provideConfig() {
    return config;
}
export class AppModule {
    constructor(platformId, appId, translate) {
        this.platformId = platformId;
        this.appId = appId;
        this.translate = translate;
        translate.setDefaultLang('en');
        translate.use('en');
    }
}
export { ɵ0, ɵ1 };
