<section class="inner-common-banner academias">
  <video id="banner_video"></video>
  <img class="inner-ban-background" src="{{ bannerTeasers.response.data.banner_teasers[6].poster_image }}" alt="" [ngStyle]="{ display: isBannerTextHidden ? 'none' : '' }">
  <img class="bann-log-com" src="../../../assets/images/inner-pag/aca-ban-cap-logo.png" alt="" [ngStyle]="{ display: isBannerTextHidden ? 'none' : '' }">
  <div class="inncer-common-caption" *ngIf="bannerConfiguration" [ngStyle]="{ display: isBannerTextHidden ? 'none' : '' }">
    <h2>{{ 'ACADEMIAS.MAIN_TITLE' | translate }}<span> .</span></h2>
    <h4>{{ bannerConfiguration.academias_page_title }}</h4>
    <p>{{ bannerConfiguration.academias_page_info }}</p>
    <a (click)="showBannerVideo()" class="inner-comm-btn">
      <img src="../../assets/images/svg/com-play.svg" alt="">      
      <span>{{ 'ACADEMIAS.SEE_PRESENTATION' | translate }}</span>
    </a>  
  </div>
  <div class="contrl-wrp">
    <div class="ply-wrp">
      <div *ngIf="bannerVideoPlaying" (click)="onStopButtonClick()" class="vid-contrlproperty-1-multi-property-2-pause" [ngClass]="{ 'active' : bannerVideoPlaying }"></div>
    </div>
    <div class="volume-wrp">
      <div *ngIf="bannerVideoPlaying" (click)="isBannerMuted = !isBannerMuted; onBannerSoundClick()" class="vlm-wrpproperty-1-opciones-property-2-off" [ngClass]="{ 'active': isBannerMuted }"></div>
      <div *ngIf="bannerVideoPlaying" (click)="isBannerMuted = !isBannerMuted; onBannerSoundClick()" class="vlm-wrpproperty-1-opciones-property-2-on" [ngClass]="{ 'active': !isBannerMuted }"></div>
    </div>
  </div>
</section>

<div class="common-slider bnner-sld">
  
<div class="common-slider-head">
  <h2>{{ 'ACADEMIAS.MOST_VIEWED_HEADER' | translate }}</h2>
</div>

<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
  <div [slide]="slide" [most_viewed]="true" slide ngxSlickItem class="slide" *ngFor="let slide of mostViewedCourses">
  </div>
  </ngx-slick-carousel>
</div>

<span class="section-divider aca-div"></span>

<ng-container *ngFor="let academy of academyCourses">
  <section class="content-section-wrpa aca-videos">
    <div class="content-main-ttl">
      <a routerLink="/academias-detail/{{ academy.slug }}">
        <h2>{{ academy.name }} <span>{{ academy.sport }}</span></h2>
      <a routerLink="/academias-detail/{{ academy.slug }}" class="view-ll-card">{{ 'ACADEMIAS.SEE_ALL' | translate }} <svg fill="none" height="11" viewBox="0 0 7 11" width="7" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-vwj-c8="" clip-rule="evenodd" d="M1.13133 9.45485C0.838433 9.74774 0.838433 10.2226 1.13133 10.5155C1.42422 10.8084 1.89909 10.8084 2.19199 10.5155L6.43451 6.27297L6.43463 6.27287C6.72752 5.97997 6.72752 5.5051 6.43463 5.21221C6.43101 5.20859 6.42736 5.20501 6.42369 5.20148L2.19187 0.96967C1.89898 0.676777 1.42411 0.676777 1.13121 0.96967C0.838319 1.26256 0.83832 1.73744 1.13121 2.03033L4.84353 5.74265L1.13133 9.45485Z" fill="white" fill-rule="evenodd"></path></svg></a>
      </a>
    </div>
    <div class="content-slider-wrpa">
      <div class="slider-lft-wrp">
        <a routerLink="/academias-detail/{{ academy.slug }}">
          <img src="{{ academy.thumbnail_image }}" alt="">
        </a>
        <p>{{ academy.description.substring(0, 110) }}.</p>
      </div>
      <div class="slider-rgt-wrp">
        <div class="common-slider">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig2">
            <div [slide]="slide" [place]="'content-slider'" slide ngxSlickItem class="slide" *ngFor="let slide of academy.courses">
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<!-- Skeleton start -->
<div class="skel-wrap" *ngIf="coursesLoading">
  <h2 class="skel-title skeleton-custm"></h2>
  <div class="skel-cont-wrap">
    <div class="skel-cont-left">
      <div class="skel-prof skeleton-custm"></div>
      <div class="skel-prof-desk">
        <span class="skel-prof-one skeleton-custm"></span>
        <span class="skel-prof-two skeleton-custm"></span>
        <span class="skel-prof-three skeleton-custm"></span>
      </div>
    </div>
    <div class="skel-cont-right">
      <span class="skeleton-custm"></span>
      <span class="skeleton-custm"></span>
      <span class="skeleton-custm"></span>
    </div>
  </div>
</div>
<!-- Skeleton Ends -->
