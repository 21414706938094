import { Component, OnInit, AfterViewInit, AfterContentInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/auth/auth.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit, AfterContentInit {
  date = new Date();
  year = this.date.getFullYear();
  categories: any = [];
  userValues: string;
  is_subscribed: any;
  contact: any;
  cmsLink: any = [];

  site_link: any = [];
  language: any;
  footerFlag: any = false;
  buildno: any;
  showCookieConfigurePopup: boolean;
  constructor(private apiService: ApiService,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private headerService: HeaderService,
    private cookieService: CookieService) {
      this.headerService.cookieConfigurePopup$.subscribe(data => this.showCookieConfigurePopup = data);
  }

  ngOnInit() {
    if (localStorage.getItem('currentLanguage')) {
      this.language = localStorage.getItem('currentLanguage');
    } else {
      this.language = 'es';
    }
    // const footerHeight =  window.getComputedStyle(document.getElementById('footer'));
    // const fheight = footerHeight.getPropertyValue('height');
    // document.body.style.paddingBottom = fheight;
  }
  ngAfterViewInit() {
    this.getCategories();
  }
  ngAfterContentInit(): void {
    this.footerFlag = true;
  }
  getCategories() {
    this.headerService.apiData$.subscribe(data => {
      if (data !== null) {
        this.categories = data['category'];
        this.contact = data['response'].address;
        this.cmsLink = data['response'].static_contents;
        this.site_link = data['response'].site_link;
      }
    });
    if (this.categories === null) {
      setTimeout(() => {
        this.getCategories();
      }, 1000);
    }
  }
  /**
   * Category Navigation
   */
  categoryNavigation(cat) {
    // Navigation based on the web series
    if (cat.is_web_series) {
      this.router.navigate(['series/' + cat.slug]);
    } else {
      this.router.navigate(['category/' + cat.slug]);
    }
  }

  google_play_clicked() {
    if (this.site_link.android_app_link) {
      const a = document.createElement('a');
      a.href = this.site_link.android_app_link ? this.site_link.android_app_link : undefined;
      a.click();
    }
  }

  configureCookies() {
    this.headerService.setCookieConfigurePopup(true);
    const cookies = this.cookieService.get('cookieConsent').split(',');
    if (cookies[1] === 'selected_2:1') {
      this.headerService.setConsentTwoChecked(true);
    } else if (cookies[1] === 'selected_2:0') {
      this.headerService.setConsentTwoChecked(false);
    }
  }
}
