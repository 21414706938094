import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { environment } from '../environments/environment';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { HomeComponent } from './modules/home/home.component';
import { BannerComponent } from './modules/home/banner/banner.component';
import { OwlModule } from 'ngx-owl-carousel';
import { SharedModule } from './shared/shared.module';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { WatchVideoComponent } from './modules/watch-video/watch-video.component';
import { EmbedComponent } from './modules/embed/embed.component';
import { PlayerComponent } from './modules/video-detail/player/player.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from './CustomUrlSerializer';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ContentEditableFormDirective } from './core/directives/content-editable-form.directive';
import {ErrorsModule} from './core/errors/errors.module';
import { RegisterComponent } from './modules/register/register.component';
import { PlansComponent } from './modules/plans/plans.component';
import { RegisterEmailComponent } from './modules/register-email/register-email.component';
import { RegisterPassComponent } from './modules/register-pass/register-pass.component';
import { RegisterDataComponent } from './modules/register-data/register-data.component';
import { LoginComponent } from './modules/login/login.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { SocialLoginModule as SocialLogin } from './modules/social-login/social-login.module';
import { Select2Module } from 'ng2-select2';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgDatepickerModule } from 'ng2-datepicker';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SlideComponent } from './modules/slide/slide.component';
import { InViewportModule } from 'ng-in-viewport';
import { MaestrosPageComponent } from './maestros-page/maestros-page.component';
import { MaestrosComponent } from './maestros/maestros.component';
import { MentoresComponent } from './modules/mentores/mentores.component';
import { AcademiasComponent } from './modules/academias/academias.component';
import { AcademiasDetailComponent } from './modules/academias-detail/academias-detail.component';
import { ComingSoonComponent } from './modules/coming-soon/coming-soon.component';
import { ComingsoonDetailComponent } from './modules/comingsoon-detail/comingsoon-detail.component';
import { LiveComponent } from './modules/live/live.component';
import { CollaboratorsComponent } from './modules/collaborators/collaborators.component';
import { CookieService } from 'ngx-cookie-service';

export const firebaseConfigOld = {
  apiKey: 'AIzaSyAl3mr4PdEF2Wr8xgs6FqPAnkq-5rRughY',
  authDomain: 'vplay-product.firebaseapp.com',
  databaseURL: 'https://vplay-product.firebaseio.com',
  projectId: 'vplay-product',
  storageBucket: 'vplay-product.appspot.com',
  messagingSenderId: '622002878280',
  appId: '1:622002878280:web:8faeabf186d25f881ca57d'
};

export const firebaseConfig = {
  apiKey: 'AIzaSyDzqt0W2PjVF_o12HYk2iq_VMKbHONmEMk',
  authDomain: 'sign-in-with-apple-5858d.firebaseapp.com',
  // databaseURL: 'https://vplay-product.firebaseio.com',
  projectId: 'sign-in-with-apple-5858d',
  storageBucket: 'sign-in-with-apple-5858d.appspot.com',
  messagingSenderId: '773583217924',
  appId: '1:773583217924:web:6b739d3c4511f67d59dc51',
  measurementId: 'G-8DZ15PJKPQ'
};
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.GoogleOAuthClientId)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.FacebookAppId)
  }
]);

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BannerComponent,
    NotificationComponent,
    WatchVideoComponent,
    PlayerComponent,
    EmbedComponent,
    ContentEditableFormDirective,
    RegisterComponent,
    SlideComponent,
    PlansComponent,
    RegisterEmailComponent,
    RegisterPassComponent,
    RegisterDataComponent,
    LoginComponent,
    ForgotPasswordComponent,
    MaestrosPageComponent,
    MaestrosComponent,
    MentoresComponent,
    AcademiasComponent,
    AcademiasDetailComponent,
    ComingSoonComponent,
    ComingsoonDetailComponent,
    LiveComponent,
    CollaboratorsComponent
    // ContusSliderComponent
  ],
  imports: [
    BrowserModule,
    NgDatepickerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    NgSelectModule,
    SlickCarouselModule,
    InViewportModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      closeButton: true,
      timeOut: 5000,
      autoDismiss: true,
      positionClass: 'toast-bottom-left'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    SocialLoginModule,
    SocialLogin,
    Select2Module,
    ClickOutsideModule,
    OwlModule,
    SharedModule,
    AppRoutingModule,
    LazyLoadImagesModule,
    ErrorsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [
    CookieService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    public translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
