import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../core/services/user-data.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  register_email: boolean;
  register_pass: boolean;
  register_data: boolean;
  userData: any;

  constructor(
    private userDataService: UserDataService
  ) {
    this.register_email = true;
    this.register_pass = false;
    this.register_data = false;
    this.userDataService.userData.subscribe(
      data => this.userData = data
    );
    if (this.userData.passPage) {
      this.register_email = false;
      this.register_pass = false;
      this.register_data = true;
      this.userDataService.setUserData({
        email: this.userData.email,
        name: this.userData.name,
        surname: this.userData.surname,
        passPage: false,
        id: this.userData.id,
        token: this.userData.token,
        provider: this.userData.provider,
        authToken: this.userData.authToken,
        photoUrl: this.userData.photoUrl
      });
    }
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0 });
  }

  showPassPage($event: boolean) {
    this.register_email = false;
    this.register_pass = $event;
    this.register_data = false;
  }

  showDataPage($event: boolean) {
    this.register_email = false;
    this.register_pass = false;
    this.register_data = $event;
  }

}
