<div class="registers">
  <ul>
    <li class="actives">1</li>
    <li>2</li>
    <li>3</li>
  </ul>
  <h2>{{ 'SIGN_UP.SETUP_ACCOUNT' | translate }}<span>.</span></h2>
  <h5>{{ 'SIGN_UP.LESS_LEFT' | translate }} <span>{{ 'SIGN_UP.CREATE_PASSWORD' | translate }}</span></h5>
  <div class="form">   
    <form #regPassForm="ngForm" (ngSubmit)="onSubmit(regPassForm)">
      <div class="groups">
        <label>{{ 'SIGN_UP.PASSWORD_PLACEHOLDER' | translate }}</label>
        <input type="password" name="pass" #userPass="ngModel" [(ngModel)]="pass" 
        (ngModelChange)="onChange(regPassForm)" required minlength="6" maxlength="16"/>
        <span class="error" *ngIf="userPass.errors?.required && ( touched || userPass?.touched )">
          {{ 'SIGN_UP.PASSWORD_REQUIRED_ERROR' | translate }}
        </span>
        <span class="error" *ngIf="userPass.errors?.minlength && ( touched || userPass?.touched )">
          {{ 'SIGN_UP.PASSWORD_LENGTH_ERROR' | translate }}
        </span>
        <span class="error" *ngIf="range < 2 && (touched) && !userPass.errors?.minlength && !userPass.errors?.required">
          {{ 'SIGN_UP.WEAK_PASSWORD' | translate }}
        </span>
        <div class="pass-tooltip" [ngClass]="{ 'active' : showTooltip }">
          <h3>{{ 'SIGN_UP.MUST_CONTAIN' | translate }}</h3>
          <ul>
            <li [ngClass]="{ 'active' : minChars }">{{ 'SIGN_UP.MIN_CHARS' | translate }}</li>
            <li [ngClass]="{ 'active' : lowerUpper }">{{ 'SIGN_UP.MIXED_CHARS' | translate }}</li>
            <li [ngClass]="{ 'active' : hasNumbers }">{{ 'SIGN_UP.ONE_NUMBER' | translate }}</li>
          </ul>
          <span class="outer-border"></span>
        </div>
      </div>
      <div class="groups">
        <label>{{ 'SIGN_UP.CONFIRM_PASSWORD' | translate }}</label>
        <input type="password" name="pass_verify" #userPassVerify="ngModel" [(ngModel)]="pass_verify" 
        required minlength="6" maxlength="16" (ngModelChange)="onConfirmChange(regPassForm)"/>
        <span class="error" *ngIf="userPassVerify.errors?.required && ( touched || userPassVerify?.touched )">
          {{ 'SIGN_UP.CONFIRM_PASSWORD_REQUIRED_ERROR' | translate }}
        </span>
        <span class="error" *ngIf="!userPassVerify.errors?.required && !confirmPasswordValid">
          {{ 'SIGN_UP.CONFIRM_PASSWORD_NOT_SAME' | translate }}
        </span>
      </div>
      <div class="strength">
        <h1 class="word-strong" [style.color]="bgcolor">{{ passStrength }}</h1>
        <div class="strong_contain"><h6 [style.width]="strength" [style.background]="bgcolor" class="strong"></h6></div>
      </div>
        <button type="submit" [disabled]="submitted">{{ 'SIGN_UP.NEXT' | translate }}</button>
    </form>

  </div>
</div>