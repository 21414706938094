import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private apiData = new BehaviorSubject<any>(null);
  public apiData$ = this.apiData.asObservable();
  private bannerData = new BehaviorSubject<any>(null);
  public bannerData$ = this.bannerData.asObservable();
  private newData = new BehaviorSubject<any>(null);
  public newData$ = this.newData.asObservable();
  private webseriesData = new BehaviorSubject<any>(null);
  public webseriesData$ = this.webseriesData.asObservable();
  public homeCategoryData;
  public homeCategories;
  public homeCategoryCourses;
  public bannerConfig;
  private mainmenuCategoryData = new BehaviorSubject<any>(null);
  public mainmenuCategoryData$ = this.mainmenuCategoryData.asObservable();
  private routeHeader = new BehaviorSubject<any>(null);
  public routeForHeader = this.routeHeader.asObservable();
  private notificationClicked = new BehaviorSubject<boolean>(false);
  public notificationClicked$ = this.notificationClicked.asObservable();
  private breadcrumb = new BehaviorSubject<string>('');
  public breadcrumb$ = this.breadcrumb.asObservable();
  private courseDetail = new BehaviorSubject<any>(null);
  public courseDetail$ = this.courseDetail.asObservable();
  private subscriptionPlanId = new BehaviorSubject<number>(null);
  public subscriptionPlanId$ = this.subscriptionPlanId.asObservable();
  private cookiePopup = new BehaviorSubject<boolean>(true);
  public cookiePopup$ = this.cookiePopup.asObservable();
  private cookieConfigurePopup = new BehaviorSubject<boolean>(false);
  public cookieConfigurePopup$ = this.cookieConfigurePopup.asObservable();
  private consentTwoChecked = new BehaviorSubject<boolean>(false);
  public consentTwoChecked$ = this.consentTwoChecked.asObservable();
  constructor(private apiService: ApiService) {}

  fetchData() {
    return this.apiService
      .callGetAPI('v2/footer', environment.commonApiUrl)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  setData(data) {
    this.apiData.next(data);
  }

  setHomeCategory(data) {
    this.homeCategoryData = data;
  }

  getHomeCategory() {
    return this.homeCategoryData;
  }

  setHomeCategories(data) {
    this.homeCategories = data;
  }

  getHomeCategories() {
    return this.homeCategories;
  }

  setHomeCategoryCourses(data) {
    this.homeCategoryCourses = data;
  }

  getHomeCategoryCourses() {
    return this.homeCategoryCourses;
  }

  setMainMenuCategory(data) {
    this.mainmenuCategoryData.next(data);
  }

  changeRouteForHeader(data) {
    this.routeHeader.next(data);
  }

  changeNotificationClicked(data) {
    this.notificationClicked.next(data);
  }

  changeBreadcrumb(data) {
    this.breadcrumb.next(data);
  }

  setCourseDetail(data) {
    this.courseDetail.next(data);
  }

  setsubscriptionPlanId(data) {
    this.subscriptionPlanId.next(data);
  }

  setCookiePopup(data) {
    this.cookiePopup.next(data);
  }

  getCookiePopup() {
    return this.cookiePopup;
  }

  setCookieConfigurePopup(data) {
    this.cookieConfigurePopup.next(data);
  }

  getCookieConfigurePopup() {
    return this.cookieConfigurePopup;
  }

  setConsentTwoChecked(data) {
    this.consentTwoChecked.next(data);
  }

  getConsentTwoChecked() {
    return this.consentTwoChecked;
  }

  setBannerConfig(data) {
    this.bannerConfig = data;
  }

  getBannerConfig() {
    return this.bannerConfig;
  }
}
