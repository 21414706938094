import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class PlayerService {
    constructor() {
        // Observable User details
        this.currentVideo = new BehaviorSubject('');
        this.currVideo = this.currentVideo.asObservable();
        this.videoPaymentDataVar = new BehaviorSubject('');
        this.videoPaymentInfo = this.videoPaymentDataVar.asObservable();
        this.setWatchFromBeginningFlag = new BehaviorSubject(false);
        this.fetchWatchFromBeginningFlag = this.setWatchFromBeginningFlag.asObservable();
        this.setVideoAnalyticsAPIStatus = new BehaviorSubject(true);
        this.fetchVideoAnalyticsAPIStatus = this.setVideoAnalyticsAPIStatus.asObservable();
    }
    // user details
    videoData(data) {
        this.currentVideo.next(data);
    }
    videoPaymentData(data) {
        this.videoPaymentDataVar.next(data);
    }
    setWatchFromBeginning(data) {
        this.setWatchFromBeginningFlag.next(data);
    }
    /**
     * Method to set the v2/videoanalytics endpoint response flag.
     *
     * @param boolean data (true/false)
     * @returns void
     *
     */
    setVideoAnalyticsResponseStatus(data) {
        this.setVideoAnalyticsAPIStatus.next(data);
    }
}
PlayerService.ngInjectableDef = i0.defineInjectable({ factory: function PlayerService_Factory() { return new PlayerService(); }, token: PlayerService, providedIn: "root" });
