import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../core/services/user-data.service';
import { NgForm } from '@angular/forms';
import { ApiService } from '../../core/services/api.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatepickerOptions } from 'ng2-datepicker';
import { AuthService as auth } from '../../core/auth/auth.service';
// import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../../core/services/user.service';
import {
  AuthService,
  SocialUser,
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { HeaderService } from '../../core/services/header.service';

@Component({
  selector: 'app-register-data',
  templateUrl: './register-data.component.html',
  styleUrls: ['./register-data.component.scss']
})
export class RegisterDataComponent implements OnInit {

  userData: any;
  user: any = {};
  dropDown: any;
  submitted = false;
  touched = false;
  countries: any[];
  login_type: string;
  login = false;
  authVariable: any;
  socialAuthService: any;
  ageLimit: number;
  options: DatepickerOptions = {};
  datepick = false;
  selectedCountries: any;
  sports: any[];
  selectedSports: any[] = [];
  categories: any[];
  selectedCategories: any[] = [];
  error: any = {};
  ageCalc: number;
  valueAsDate: any;
  paymentData: any = {};
  constructor(
    private authLogin: auth,
    private authService: AuthService,
    private userService: UserService,
    private userDataService: UserDataService,
    private apiService: ApiService,
    private toasterService: ToastrService,
    private router: Router,
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    this.userDataService.userData.subscribe(
      data => this.userData = data
    );
    this.fetchDropdown();
  }

  onSubmit(form: NgForm) {
    this.touched = true;
    if (form.valid && this.selectedCategories.length > 2 && this.selectedSports.length > 2 &&
      this.selectedSports.length <= 5 && this.selectedSports.length <= 5 &&
      this.selectedCountries && this.datepick !== true) {
      const date = new Date(this.user.dob);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      this.submitted = true;
      // this.login_type = this.userData.provider === undefined ? 'normal' : this.userData.provider === 'FACEBOOK' ? 'fb' : 'google+';
      this.login_type = this.userData.provider === undefined ? 'normal'
      : this.userData.provider === 'FACEBOOK' ? 'fb'
      : this.userData.providerId === 'apple.com' ? 'apple'
      : 'google+';
      this.apiService.callPostAPI(
        'v2/auth/register',
        {
          login_type: this.login_type,
          social_user_id: this.userData.id,
          token: this.userData.token,
          acesstype: 'web',
          email: this.userData.email,
          password: this.userData.password,
          password_confirmation: this.userData.password,
          name: form.value.firstName,
          surname: form.value.surName,
          age: this.user.dob ? day + '-' + month + '-' + year : '',
          country_code: this.selectedCountries.id,
          sports: this.selectedSports,
          categories: this.selectedCategories
        },
        environment.userApiUrl
      ).subscribe(
        data => {
          this.submitted = false;
          if (data['statusCode'] === 200 && data['error'] === false) {
            // this.toasterService.success('', data['message']);
            if (this.login_type === 'fb' || this.login_type === 'google+' || this.login_type === 'apple' || this.login_type === 'normal') {
              this.authVariable =  this.authLogin.login(this.userData).subscribe(
                (data) => {
                   if (data['statusCode'] === 200 && data['error'] === false) {
                    this.userService.changeLogoutHeader(false);
                     this.login = true;
                     this.userService.displayPopup('close');
                     if (
                       data['response'].name &&
                       !localStorage.getItem('currentUser')
                     ) {
                       const userDataNew = this.authLogin.encryption(data);
                       localStorage.setItem('currentUser', userDataNew);
                       if (data['response'].is_subscribed === 0) {
                        this.paymentData.subscription_plan_id = 'silver-1';
                        this.paymentData.price = 0;
                        this.paymentData.payment_method_id = 1;
                        this.paymentData.payment_type = 'net-banking';
                        this.paymentData.card_number = '';
                        this.paymentData.name = '';
                        this.paymentData.month = '';
                        this.paymentData.year = '';
                        this.paymentData.cvv = '';
                        this.paymentData.coupon_code = null;
                        this.paymentData.is_save = false;
                        this.apiService.callPostAPI(
                          'v2/subscription/add',
                          this.paymentData,
                          environment.paymentApiUrl
                        ).subscribe(
                          (data: any) => {
                            const userValues = localStorage.getItem('currentUser');
                            if (userValues) {
                              const userData = this.authLogin.decryption(userValues);
                              userData['response'].is_subscribed = 1;
                              userData['response'].subscription_plan_id = data.response.transaction.subscription_plan_id;
                              this.headerService.setsubscriptionPlanId(data.response.transaction.subscription_plan_id);
                              const userNewData = this.authLogin.encryption(userData);
                              this.asyncLocalStorage
                              .setItem('currentUser', userNewData)
                              .then(() => {
                               const nextUrl = 'plans';
                               this.router.navigate([nextUrl]);
                               this.userService.showWelcomePopup(true);
                             });
                            }
                            localStorage.setItem('transaction_id', data['response'].transaction.transaction_id);
                          },
                          error => {
                          }
                        );
                      }
                     }
                     this.authService.signOut();
                   }
                 },
                 (error) => {
                   if (this.userData.email) {
                     this.socialAuthService.unsubscribe();
                     this.toasterService.error(
                       '',
                       error.error.message ? error.error.message : error.message
                     );
                   } else {
                     this.toasterService.error(
                       '',
                       'Update your email id in social network'
                     );
                   }
                 }
               );
             } else {
              const nextUrl = 'plans';
              if (!localStorage.getItem('myUrl')) {
                if (nextUrl.indexOf('video') !== -1) {
                  window.location.reload();
                } else {
                  this.userService.showVerifyMailPopup(true);
                  this.router.navigate([nextUrl]);
                }
              } else if (localStorage.getItem('myUrl')) {
                if (data['response'].is_subscribed === 0) {
                  localStorage.setItem('subscribeUrl', window.location.pathname);
                  this.router.navigate(['subscription']);
                  localStorage.removeItem('myUrl');
                } else if (data['response'].is_subscribed === 1) {
                  this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                  localStorage.removeItem('myUrl');
                }
              }
             }
          }
        },
        error => {
          this.submitted = false;
          if (error.error.statusCode === 422) {
            if (typeof error.error.message === 'string' || error.error.message instanceof String) {
              this.toasterService.error('', error.error.message);
            } else {
              this.error = error.error.message;
            }
          } else {
            this.error = {};
            this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
          }
        }
      );
    }
  }


  fetchDropdown() {
    this.apiService.callGetAPI(
      'v2/userdata_dropdown',
      environment.userApiUrl
    ).subscribe(
      (data: any) => {
        this.datepick = false;
        this.dropDown = data.response.data;
        this.countries = this.dropDown.countries;
        this.sports = this.dropDown.sports;
        this.categories = this.dropDown.categories;
        this.ageLimit = this.dropDown.age_limit;
        const age_limit = new Date();
        age_limit.setFullYear(age_limit.getFullYear() - this.ageLimit);
        this.options = {
          minYear: 1950,
          displayFormat: 'DD-MM-YYYY',
          barTitleFormat: 'MMM, YYYY',
          placeholder: 'DD/MM/AAAA',
          useEmptyBarTitle: false,
          maxDate: age_limit,
          maxYear: age_limit.getFullYear() + 1
        };
      }
    );
  }

  onFocus() {
    this.error = {};
  }

  onChange(e) {
    // if (e.target.name === '') {
    //   this.error = [];
    //   this.datepick = true;
    //   this.error[e.target.name] = '';
    // }
    if (this.user.dob !== undefined && this.user.dob !== '') {
      const age_limit = new Date();
      age_limit.setFullYear(age_limit.getFullYear() - this.ageLimit);
      if (this.user.dob.getTime() > age_limit) {
        this.datepick = true;
      } else {
        this.datepick = false;
      }
    }
  }
  // tslint:disable-next-line:member-ordering
  asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  };

}
