import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../../core/services/api.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { UserDataService } from '../../core/services/user-data.service';

@Component({
  selector: 'app-register-email',
  templateUrl: './register-email.component.html',
  styleUrls: ['./register-email.component.scss']
})
export class RegisterEmailComponent implements OnInit {

  @Output() showPassPageEvent = new EventEmitter();

  touched = false;
  submitted = false;
  error: any = {};
  email: any;
  terms: any;

  constructor(
    private apiService: ApiService,
    private toasterService: ToastrService,
    private userDataService: UserDataService
  ) { }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    this.touched = true;
    if (form.valid) {
      this.submitted = true;
      this.apiService.callPostAPI(
        'v2/auth/check_email',
        {
          email: form.value.email
        },
        environment.userApiUrl
        )
        .subscribe (
          data => {
            this.submitted = false;
            if (data['statusCode'] === 200 && data['error'] === false) {
              this.userDataService.setUserData({
                email: form.value.email
              });
              this.showPassPage();
            }
          },
          error => {
            this.submitted = false;
            if (error.error.statusCode === 422) {
              if (typeof error.error.message === 'string' || error.error.message instanceof String) {
                this.toasterService.error('', error.error.message);
              } else {
                this.error = error.error.message;
              }
            } else {
              this.error = {};
              this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
            }
          }
      );
    }
  }

  showPassPage() {
    this.showPassPageEvent.emit(true);
  }

  onFocus() {
    this.error = {};
  }

}
