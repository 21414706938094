import * as i0 from "@angular/core";
export class UtilityService {
    constructor() {
        this.categoryDetail = [];
        this.subCategories = [];
    }
    // user details
    VplyToaster() {
        setTimeout(() => {
            [].forEach.call(document.querySelectorAll('.common-error'), function (el) {
                el.classList.remove('none');
                el.classList.add('block');
            }, 1000);
        });
        setTimeout(() => {
            [].forEach.call(document.querySelectorAll('.common-error'), function (el) {
                el.classList.add('none');
                el.classList.remove('block');
            });
        }, 5000);
    }
    VplyToasterHide() {
        [].forEach.call(document.querySelectorAll('.common-error'), function (el) {
            el.classList.add('none');
            el.classList.remove('block');
        });
    }
    setCategoryDetail(data) {
        this.categoryDetail.push(data);
    }
    getCategoryDetail() {
        return this.categoryDetail;
    }
    setSubCategories(data) {
        this.subCategories.push(data);
    }
    getSubCategories() {
        return this.subCategories;
    }
}
UtilityService.ngInjectableDef = i0.defineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(); }, token: UtilityService, providedIn: "root" });
