/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./social-login-new.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./social-login.component";
import * as i3 from "angularx-social-login";
import * as i4 from "../../core/auth/auth.service";
import * as i5 from "ngx-toastr";
import * as i6 from "@angular/router";
import * as i7 from "../../core/services/user.service";
import * as i8 from "../../core/services/api.service";
import * as i9 from "../../core/services/user-data.service";
import * as i10 from "@angular/fire/auth";
import * as i11 from "../../core/services/header.service";
var styles_SocialLoginComponent = [i0.styles];
var RenderType_SocialLoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SocialLoginComponent, data: {} });
export { RenderType_SocialLoginComponent as RenderType_SocialLoginComponent };
export function View_SocialLoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "socials"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "logins"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signInWithFB() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["height", "17px"], ["id", "Capa_1"], ["style", "enable-background:new 0 0 96.124 96.123;"], ["version", "1.1"], ["viewBox", "0 0 96.124 96.123"], ["width", "20px"], ["x", "0px"], ["xmlns", "http://www.w3.org/2000/svg"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:path", [["d", "M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803   c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654   c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246   c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z"], ["style", "\n    fill: #fff;\n"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "logins"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signInWithGoogle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "14"], ["viewBox", "0 0 14 14"], ["width", "14"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:path", [["d", "M6.99771 6.00185V8.28646H10.7708C10.615 9.26435 9.62848 11.1509 6.99771 11.1509C4.73041 11.1509 2.87849 9.273 2.87849 6.95377C2.87849 4.63454 4.73041 2.75666 6.99771 2.75666C8.28714 2.75666 9.15252 3.3105 9.64579 3.78646L11.4544 2.04704C10.2948 0.96531 8.78906 0.307617 6.99771 0.307617C3.31983 0.307617 0.351562 3.27589 0.351562 6.95377C0.351562 10.6317 3.31983 13.5999 6.99771 13.5999C10.8314 13.5999 13.3756 10.8999 13.3756 7.10954C13.3756 6.66819 13.3323 6.33935 13.2718 6.00185H6.99771Z"], ["fill", "white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "logins"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signInWIthApple() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "15"], ["viewBox", "0 0 13 15"], ["width", "13"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, ":svg:path", [["d", "M12.0548 10.5144C11.5442 10.3587 11.0856 9.99519 10.6875 9.42404C10.2894 8.85289 10.0904 8.20385 10.0904 7.49423C10.0904 6.83654 10.2808 6.23942 10.6529 5.70289C10.8606 5.4 11.1894 5.0625 11.6394 4.68173C11.3452 4.31827 11.0423 4.02404 10.7394 3.81635C10.2029 3.44423 9.58846 3.25385 8.89615 3.25385C8.47211 3.25385 7.97019 3.35769 7.39904 3.54808C6.82788 3.74712 6.4125 3.84231 6.16154 3.84231C5.9625 3.84231 5.56442 3.75577 4.96731 3.58269C4.36154 3.40962 3.85962 3.32308 3.43558 3.32308C2.44039 3.32308 1.62692 3.73846 0.977885 4.56923C0.328846 5.40865 0 6.48173 0 7.80577C0 9.225 0.432692 10.6702 1.27212 12.1587C2.12885 13.6471 2.98558 14.4 3.87692 14.4C4.1625 14.4 4.54327 14.3048 5.01923 14.1058C5.48654 13.9154 5.90192 13.8202 6.24808 13.8202C6.60288 13.8202 7.04423 13.9067 7.55481 14.0971C8.07404 14.2788 8.47211 14.374 8.76635 14.374C9.50192 14.374 10.2462 13.8115 10.9904 12.6779C11.4923 11.925 11.8385 11.1981 12.0548 10.5144ZM8.80096 0.363463C8.80096 0.302886 8.79231 0.250962 8.79231 0.190385C8.78365 0.138462 8.76635 0.077885 8.74904 0C7.74519 0.233654 7.01827 0.657692 6.58558 1.28077C6.15288 1.90385 5.92789 2.64808 5.91058 3.50481C6.31731 3.47019 6.61154 3.41827 6.81058 3.35769C7.12212 3.25385 7.43365 3.04615 7.74519 2.73462C8.10865 2.37115 8.37692 1.97308 8.55 1.54038C8.71442 1.11635 8.80096 0.71827 8.80096 0.363463Z"], ["fill", "white"]], null, null, null, null, null))], null, null); }
export function View_SocialLoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-social-login", [], null, null, null, View_SocialLoginComponent_0, RenderType_SocialLoginComponent)), i1.ɵdid(1, 245760, null, 0, i2.SocialLoginComponent, [i3.AuthService, i4.AuthService, i5.ToastrService, i6.Router, i7.UserService, i8.ApiService, i9.UserDataService, i10.AngularFireAuth, i11.HeaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SocialLoginComponentNgFactory = i1.ɵccf("app-social-login", i2.SocialLoginComponent, View_SocialLoginComponent_Host_0, {}, {}, []);
export { SocialLoginComponentNgFactory as SocialLoginComponentNgFactory };
