<div class="notification-container" (scroll)="onScroll($event)">
	<p *ngIf="notifications.length > 0" (click)="notificationRemoveAll()" class="notifi-close">{{ 'NOTIFICATION.CLEAR_ALL' | translate }}</p>
	<ul *ngIf="notifications.length > 0">
		<ng-container *ngFor="let notification of notifications let i=index">
			<li class="notify-list"
				[ngClass]="{'unread_msg': (notification.read_at === null),'' : (notification.read_at !== null)}"
				(mouseleave)="mouseout(i)">
				<div class="notify-thumb" (click)="readSingleNotification(i)"
					*ngIf="notification.type !== 'subscription' && notification.type !== 'revoke_request' && notification?.video?.thumbnail_image !== ''">
					<span class="notify-thumb-img"
						[ngStyle]="{'background-image':'url('+notification?.thumbnail_image+')'}"></span>
				</div>
				<div class="notify-content" (click)="readSingleNotification(i)">
					<p class="notify-cat">{{ 'NOTIFICATION.AVAILABLE' | translate }}</p>
					<p class="notify-msg">{{ notification.instructor_first_name }} {{ notification.instructor_last_name }} <span>{{ (notification.sport) ? notification.sport : (notification.genre ? notification.genre : notification.genre_subtitle) }}</span></p>
					<p class="notify-time">{{ notification.date }}</p>
				</div>	
				<svg class="delete-list" (click)="removeSingleNotification(i)" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M16.6773 5.36396L11.7275 10.3137L6.77779 5.36396C6.42424 5.01041 6.07068 4.65685 5.36358 5.36396C4.65647 6.07107 5.01002 6.42462 5.36358 6.77817L10.3133 11.7279L5.36358 16.6777C5.01002 17.0312 4.79789 17.5262 5.36358 18.0919C6.07068 18.799 6.42424 18.4454 6.77779 18.0919L11.7275 13.1421L16.6773 18.0919C17.0308 18.4454 17.3844 18.799 18.0915 18.0919C18.7986 17.3848 18.4451 17.0312 18.0915 16.6777L13.1418 11.7279L18.0915 6.77817C18.4451 6.42462 18.7986 6.07107 18.0915 5.36396C17.3844 4.65685 17.0308 5.01041 16.6773 5.36396Z" fill="white"/>
				</svg>
			</li>
		</ng-container>
		<div id="notification_bottom"></div>
	</ul>
	<div *ngIf="notifications.length === 0 && !loading" class="no-notification">

		<svg width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="76" cy="76" r="76" fill="#141414"/>
			<path d="M113.114 93.9764C109.836 90.203 103.059 83.6743 103.059 65.1665C103.059 51.3306 92.6357 43.0649 79.8673 40.1899V34.4998C79.8673 32.5832 79.8673 30.6665 76.017 30.6665C72.1666 30.6665 72.134 32.5832 72.134 34.5597V40.2498C59.3656 43.1248 48.9258 51.3306 48.9258 65.1665C48.9258 83.6743 42.0254 90.203 38.7471 93.9764C37.7118 95.0545 37.1941 96.492 37.3667 97.7498C37.3667 100.805 39.4372 103.5 42.8882 103.5H108.973C112.424 103.5 114.495 100.805 114.667 97.7498C114.667 96.492 114.15 95.0545 113.114 93.9764ZM47.0007 95.8332C50.6241 90.9816 56.4866 81.3983 56.6592 66.1248C56.6592 66.1248 56.6837 65.3462 56.6837 65.1665C56.6837 54.2056 65.3191 45.9998 76.017 45.9998C86.5423 45.9998 95.334 54.2056 95.334 65.1665C95.334 65.3462 95.3258 66.1248 95.3258 66.1248C95.4984 81.3983 101.377 90.9816 105.001 95.8332H47.0007ZM76.017 122.667C82.0561 122.667 86.8874 117.635 86.8874 111.167H64.974C64.974 117.635 69.8053 122.667 76.017 122.667Z" fill="#383838"/>
		</svg>			
		<h2 class="no-not-text">{{ 'NOTIFICATION.UP_TO_DATE' | translate }}</h2>
		<p class="not-para-text"><span>{{ 'NOTIFICATION.NO_NOTIFICATION' | translate }} </span> <span>{{ 'NOTIFICATION.INFORM_NEW' | translate }}</span> {{ 'NOTIFICATION.NO_MISS' | translate }}</p>
	</div>
	<div *ngIf="notifications.length === 0 && loading">
		<app-loadmore *ngIf="loading"></app-loadmore>
	</div>
</div>