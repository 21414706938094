<section class="player-pg-wrp">
  <div class="plyr-lft-set">
    <div class="plyr-lft-set-inner">
      <div class="video-plycaption" (click)="playPause()">
        <div class="video-ply-captioninner">
          <div class="comn-logo" [ngClass]="instructorType(course)">
            <img src="../../../assets/images/svg/oro.svg" class="oro" alt="">
            <img src="../../../assets/images/svg/plata.svg" class="plata" alt="">
            <img src="../../../assets/images/svg/bronce.svg" class="bronce" alt="">
          </div>
          <h2>{{ course.instructor_first_name }} {{ course.instructor_last_name }}<span>.</span></h2>
          <h4>{{ video.title }}</h4>
          <p>{{ video.description }}</p>
        </div>
      </div>
      <video id="videos"></video>
  
  <div class="grid_box" *ngFor="let relatedVideo of related_videos">
    <a href="{{ relatedVideo?.url }}" class="box" target="_blank">
      <div class="img-video">
        <img alt="thumbnail" src="{{ relatedVideo?.image_url }}" />
      </div>
      <a class="overlay-top">
        <span>{{ relatedVideo?.title }}</span>
      </a>
    </a>
  </div>
  <div class="subscribe_box" *ngIf="video?.is_subscribe_recommend === 1">
    <a href="/subscription">
      <svg
        *ngIf="recommendLogo == ''"
        xmlns="http://www.w3.org/2000/svg"
        style="margin: 33px 7px"
        width="104"
        height="47.498"
        viewBox="0 0 104 47.498"
      >
        <g
          id="logo_svg-web"
          data-name="logo svg-web"
          transform="translate(4012.462 2708.317)"
        >
          <g id="suwaidan" transform="translate(-4012.462 -2681.087)">
            <g id="Group_799" data-name="Group 799" transform="translate(0)">
              <path
                id="Path_1633"
                data-name="Path 1633"
                d="M609.454,128.46a6.45,6.45,0,0,0,3.84,1.188,3.915,3.915,0,0,0,2.022-.468,1.512,1.512,0,0,0,.789-1.383,1.45,1.45,0,0,0-.537-1.131,3.445,3.445,0,0,0-1.325-.7q-.789-.229-1.726-.583a14.471,14.471,0,0,1-1.725-.777,3.5,3.5,0,0,1-1.325-1.3,4.028,4.028,0,0,1-.537-2.137,3.68,3.68,0,0,1,1.474-3.176,5.986,5.986,0,0,1,3.622-1.074,6.932,6.932,0,0,1,4.228,1.279l-.777,2.354A5.334,5.334,0,0,0,614,119.341a3.241,3.241,0,0,0-1.76.434,1.4,1.4,0,0,0-.686,1.257,1.63,1.63,0,0,0,.743,1.394,6.007,6.007,0,0,0,1.794.834q1.051.308,2.1.708a4.252,4.252,0,0,1,1.794,1.3,3.5,3.5,0,0,1,.743,2.3,3.9,3.9,0,0,1-1.588,3.382,6.494,6.494,0,0,1-3.9,1.143,7.639,7.639,0,0,1-4.548-1.3Z"
                transform="translate(-608.7 -116.919)"
                fill="#ed4a1a"
              />
              <path
                id="Path_1634"
                data-name="Path 1634"
                d="M646.914,117.585H649.5v8.707a4.281,4.281,0,0,0,.823,2.96,3.157,3.157,0,0,0,2.422.88,3.076,3.076,0,0,0,2.377-.892,4.289,4.289,0,0,0,.822-2.948v-8.707h2.606v8.707q0,3.245-1.554,4.753a6.776,6.776,0,0,1-8.525,0q-1.554-1.508-1.554-4.753Z"
                transform="translate(-635.121 -117.379)"
                fill="#ed4a1a"
              />
              <path
                id="Path_1635"
                data-name="Path 1635"
                d="M762.5,129.035h-6.216l-1.234,3.268h-2.674l5.691-14.718h2.651L766.4,132.3h-2.674Zm-5.325-2.423h4.411l-2.194-5.736Z"
                transform="translate(-708.264 -117.379)"
                fill="#ed4a1a"
              />
              <path
                id="Path_1636"
                data-name="Path 1636"
                d="M803.4,117.585H806V132.3H803.4Z"
                transform="translate(-743.589 -117.379)"
                fill="#ed4a1a"
              />
              <path
                id="Path_1637"
                data-name="Path 1637"
                d="M822.281,117.585h4.5q3.565,0,5.153,1.68t1.588,5.656q0,7.382-6.742,7.382h-4.5Zm7.668,11.2a6.169,6.169,0,0,0,.9-3.839,6.546,6.546,0,0,0-.846-3.76q-.846-1.177-3.222-1.177h-1.92v9.873h1.92A3.907,3.907,0,0,0,829.949,128.783Z"
                transform="translate(-756.7 -117.379)"
                fill="#ed4a1a"
              />
              <path
                id="Path_1638"
                data-name="Path 1638"
                d="M870.621,129.035H864.4l-1.234,3.268H860.5l5.69-14.718h2.651l5.691,14.718h-2.674Zm-5.325-2.423h4.411l-2.194-5.736Z"
                transform="translate(-783.26 -117.379)"
                fill="#ed4a1a"
              />
              <path
                id="Path_1639"
                data-name="Path 1639"
                d="M911,117.585H913.5l6.079,9.827v-9.827h2.606V132.3h-2.491l-6.1-9.8v9.8H911Z"
                transform="translate(-818.18 -117.379)"
                fill="#ed4a1a"
              />
            </g>
          </g>
          <g id="tv" transform="translate(-4000.47 -2708.317)">
            <g id="Group_800" data-name="Group 800">
              <path
                id="Path_1640"
                data-name="Path 1640"
                d="M755.719,68.825a.551.551,0,0,1-.067,0,.578.578,0,0,1-.508-.641l1.512-12.958a3.872,3.872,0,0,0-4.124-4.311l-12.919,1.08a.578.578,0,1,1-.1-1.152l12.926-1.08a5.028,5.028,0,0,1,5.363,5.6l-1.512,12.958A.578.578,0,0,1,755.719,68.825Z"
                transform="translate(-710.771 -43.244)"
                fill="#421c75"
              />
              <path
                id="Path_1641"
                data-name="Path 1641"
                d="M649.2,69.482a.578.578,0,0,1-.57-.485L647.586,62.6c0-.013,0-.026-.005-.039a5.028,5.028,0,0,1,4.644-5.49l14.563-1.217a.578.578,0,1,1,.1,1.152l-14.57,1.217a3.872,3.872,0,0,0-3.584,4.209l1.04,6.374a.579.579,0,0,1-.478.664A.606.606,0,0,1,649.2,69.482Z"
                transform="translate(-647.557 -47.47)"
                fill="#421c75"
              />
              <path
                id="Path_1642"
                data-name="Path 1642"
                d="M682.692,169.344a.578.578,0,0,1-.017-1.156l25.955-.783a3.887,3.887,0,0,0,3.733-3.458.578.578,0,0,1,1.15.123,5.048,5.048,0,0,1-4.848,4.491l-25.955.783Z"
                transform="translate(-671.45 -121.846)"
                fill="#421c75"
              />
              <path
                id="Path_1643"
                data-name="Path 1643"
                d="M662.154,54.929a3.065,3.065,0,0,1,6.13,0"
                transform="translate(-657.649 -44.71)"
                fill="#421c75"
              />
              <path
                id="Path_1644"
                data-name="Path 1644"
                d="M659.151,38.087a.581.581,0,0,1-.4-.164l-4.792-4.681a.578.578,0,0,1,.808-.828l4.327,4.226,5.956-7.731a.578.578,0,1,1,.916.706l-6.352,8.247a.577.577,0,0,1-.417.224Z"
                transform="translate(-651.86 -28.683)"
                fill="#421c75"
              />
            </g>
          </g>
          <g id="man" transform="translate(-3987.381 -2705.889)">
            <g id="Group_802" data-name="Group 802">
              <path
                id="Path_1645"
                data-name="Path 1645"
                d="M711.3,47.723a5.587,5.587,0,1,1,5.587-5.587A5.593,5.593,0,0,1,711.3,47.723Zm0-8.624a3.037,3.037,0,1,0,3.037,3.037A3.041,3.041,0,0,0,711.3,39.1Z"
                transform="translate(-700.997 -36.55)"
                fill="#421c75"
              />
              <g
                id="Group_801"
                data-name="Group 801"
                transform="translate(0 9.777)"
              >
                <path
                  id="Path_1646"
                  data-name="Path 1646"
                  d="M705.308,128.349l3.2-10.764h2.582L706.61,132.3h-2.582l-3.2-10.4-3.177,10.4H695.07l-4.5-14.718h2.605l3.177,10.764,3.2-10.764h2.583Z"
                  transform="translate(-690.524 -102.354)"
                  fill="#421c75"
                />
                <path
                  id="Path_1647"
                  data-name="Path 1647"
                  d="M711.049,81.028H708.5V70.781H705.83l-5.091,5.746-5.091-5.746h-2.669V81.028h-2.55v-12.8h6.366l3.944,4.451,3.944-4.451h6.366Z"
                  transform="translate(-690.428 -68.231)"
                  fill="#421c75"
                />
                <rect
                  id="Rectangle_3754"
                  data-name="Rectangle 3754"
                  width="2.558"
                  height="8.32"
                  transform="translate(9.031 4.477)"
                  fill="#ed4a1a"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      <img
        alt="Logo"
        *ngIf="recommendLogo != ''"
        src="{{ recommendLogo }}"
        style="margin: 33px 7px"
        width="104"
        height="47.498"
        viewBox="0 0 104 47.498"
      />
    </a>
  </div>
  
  <div
    *ngIf="video && video.is_live === 1 && !video.hls_playlist_url && !geoStatus"
    class="webrtc-video"
  >
    <div class="player">
      <div class="video-screen">
        <video
          disablePictureInPicture
          #videoPlayer
          id="remoteVideo"
          autoplay
          controls
        ></video>
      </div>
      <div
        id="overaly-static-video"
        class="overaly-static-video"
        *ngIf="video.is_live === 1 && !video.hls_playlist_url"
        [ngStyle]="{
          opacity: showPlayerOverlay ? 1 : 0,
          visibility: showPlayerOverlay ? 'visible' : 'hidden'
        }"
      >
        <span *ngIf="playerOverlayInfo == 'unavailable'">
          {{ 'PLAYER.OVERLAY_INFO' | translate }}
        </span>
        <span *ngIf="playerOverlayInfo == 'loading'">
          {{ 'PLAYER.LOADING' | translate }}
        </span>
        <span *ngIf="playerOverlayInfo == 'finished'">
          {{ 'PLAYER.LIVE_END' | translate }}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="video && video.is_live === 1 && !geoStatus">
    <button
      type="button"
      class="open-live-chat btn"
      (click)="toggleLiveChat('open')"
      *ngIf="this.video && this.video.is_live === 1"
    >
      <svg
        height="40.422"
        viewBox="0 0 39.508 40.422"
        width="39.508"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          data-name="live chat"
          id="live_chat"
          transform="translate(-295.754 -429.522)"
        >
          <path
            d="M-7464.246,13507.794v-27.362a3.023,3.023,0,0,1,3.29-2.9c3.095.221,25.792,0,25.792,0a2.569,2.569,0,0,1,1.547,2.579c-.221,1.843,0,19.528,0,19.528l-23.213.295Z"
            data-name="Path 10178"
            fill="#ffffff"
            id="Path_10178"
            transform="translate(7760 -13048)"
          ></path>
          <path
            d="M-7455.887,13503.964h27.025v-18.183h3.172s2.1-.218,1.943,1.4,0,30.762,0,30.762l-7.448-8.419s-22.633.02-22.667,0-2.026.693-2.026-1.426Z"
            data-name="Path 10179"
            fill="#ffffff"
            id="Path_10179"
            transform="translate(7759 -13048)"
          ></path>
        </g>
      </svg>
      <span> {{ 'PLAYER.OPEN_LIVE_CHAT' | translate }}</span>
    </button>
    <!--Live Chat-->
    <div class="live-chat-app">
      <div class="live-chat-app" #scrollMe [scrollTop]="scrollMe.scrollHeight">
        <div class="livechat-header">
          <button
            href="javascript:void(0)"
            (click)="toggleLiveChat('close')"
            class="close-live-chat chat_back_btn"
          >
            <svg
              xml:space="preserve"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              height="612px"
              id="Capa_1"
              style="enable-background: new 0 0 612 612"
              version="1.1"
              viewBox="0 0 612 612"
              width="612px"
              x="0px"
              xmlns="http://www.w3.org/2000/svg"
              y="0px"
            >
              <g _ngcontent-syl-c15="">
                <g id="cross">
                  <g _ngcontent-syl-c15="">
                    <polygon
                      fill="#ffffff"
                      points="612,36.004 576.521,0.603 306,270.608 35.478,0.603 0,36.004 270.522,306.011 0,575.997 35.478,611.397
                306,341.411 576.521,611.397 612,575.997 341.459,306.011 			"
                    ></polygon>
                  </g>
                </g>
              </g>
            </svg>
          </button>
          <h3 class="live-chat-title">{{ 'PLAYER.LIVE_CHAT' | translate }}</h3>
        </div>
        <div
          class="live-chat-contnet"
          #scrollMe
          [scrollTop]="scrollMe.scrollHeight"
          [ngStyle]="{ height: livechatInputHeight }"
        >
          <ul>
            <li
              class="chat"
              *ngFor="let message of liveMessages; trackBy: trackByFn"
            >
              <img
                alt="Avator"
                class="chat-avator"
                src="{{
                  message?.photoUrl === ''
                    ? 'assets/images/user-icon.png'
                    : message?.photoUrl
                }}"
              />
              <div class="chat-message">
                <div class="chat-view">
                  <p class="chat-person">{{ message.name }}</p>
                  <span class="chat-time">{{ message.time }}</span>
                </div>
                <p class="message">
                  {{ message.text }}
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div class="live-chat-input" id="live-chat-input">
          <img
            alt="Avator"
            class="chat-avator"
            src="{{
              user?.response?.profile_picture
                ? user?.response?.profile_picture
                : 'assets/images/user-icon.png'
            }}"
          />
          <div
            *ngIf="!loginError"
            id="live-chat-comment"
            class="live-chat-text-input-field"
            appContentEditable="true"
            [attr.data-placeholder]="'PLAYER.TYPE_MESSAGE' | translate"
            spellcheck="false"
            [(ngModel)]="liveChatText"
            (keydown.enter)="onKeydown($event)"
            (keyup.enter)="sendMessageInLive(liveChatText)"
            (keyup)="changeEvent(liveChatText)"
          ></div>
  
          <div
            *ngIf="loginError"
            id="live-chat-comment"
            class="live-chat-text-input-field"
          >
            <p class="chat-error">Please Login to perform this action</p>
          </div>
  
          <a
            href="javascript:void(0)"
            class="send-comment"
            (click)="sendMessageInLive(liveChatText)"
          >
            <svg width="25.995" height="22.282" viewBox="0 0 25.995 22.282">
              <path
                d="M0,60.532,26,49.391,0,38.25v9.5l14.139,1.645L0,51.036Z"
                transform="translate(0 -38.25)"
                fill="#ed4a1a"
              />
            </svg>
          </a>
        </div>
        <div
          *ngIf="commentShortError || commentMaxlengthError || loginError"
          class="error-text"
        >
          <p class="chat-error" *ngIf="commentMaxlengthError">
            {{ 'PLAYER.COMMENT_LIMIT' | translate }}
          </p>
          <p class="chat-error" *ngIf="commentShortError">Comment is empty.</p>
        </div>
      </div>
    </div>
  </div>
  
  <div class="video-cs-blk">
    <div
      (mouseenter)="mouseOverXray = true"
      (mouseleave)="mouseOverXray = false"
      class="xray-contents xray-content-lists"
      *ngIf="showXray && xrayCastList.length > 0"
    >
      <div class="xray-heading">
        <h2 (click)="xrayListClicked()">
          X-Ray
          <span class="xray-arrow">
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 451.847 451.847">
              <g>
                <path
                  d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                    c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                    c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                />
              </g>
            </svg>
          </span>
        </h2>
      </div>
      <div class="xray-lists" *ngIf="showXrayList">
        <ul>
          <li *ngFor="let xray of xrayCastList">
            <div class="xray-image" *ngIf="xray.banner_image">
              <img alt="ThumbnailImage" [src]="xray.banner_image" />
            </div>
            <div class="xray-content" (click)="showXrayCastDetailMethod(xray)">
              <h3 class="xray-title" *ngIf="xray.name">{{ xray.name }}</h3>
              <p class="xray-para">{{ xray.description }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
  

  <!-- <button class="play-control custom-video-btn prev-video">
    <span>
    </span>
  </button> -->

  <!-- Next Button for Web series -->
  <button
    (mouseenter)="mouseOverEpisode = true"
    (mouseleave)="mouseOverEpisode = false"
    class="play-control custom-video-btn next-video"
    (click)="nextVideo()"
  >
    <span *ngIf="showEpisode && next_video">
      <!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.07883 18.0104L12.3934 10.6958C12.4552 10.634 12.4964 10.5722 12.5273 10.5001V17.4849C12.5273 17.8455 12.8261 18.1443 13.1867 18.1443H14.5053C14.8659 18.1443 15.1647 17.8455 15.1647 17.4849V2.97945C15.1647 2.61887 14.8659 2.32011 14.5053 2.32011H13.1867C12.8261 2.32011 12.5273 2.61887 12.5273 2.97945V9.96434C12.4964 9.89223 12.4552 9.83041 12.3934 9.7686L5.07883 2.45404C4.82128 2.19648 4.61523 2.2892 4.61523 2.64978V17.8146C4.61523 18.1752 4.82128 18.2679 5.07883 18.0104Z" fill="white"/>
      </svg>         -->
    </span>
  </button>
  
  <div class="initimation-subscribe" id="demo" style="display: none">
    <div class="initimation-subscribe-inner">
      <p>Subscribe to watch rest of the video</p>
      <a
        class="org-btn wave make-subscription"
        ui-sref="subscribeinfo"
        title="subscribe now"
        (click)="goToSubscription()"
        >subscribe now</a
      >
    </div>
  </div>
  
  <div class="geofence-region" id="geofence-region" *ngIf="geoStatus">
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 330 330">
      <path
        d="M165,0C74.019,0,0,74.02,0,165.001C0,255.982,74.019,330,165,330s165-74.018,165-164.999C330,74.02,255.981,0,165,0z
                  M165,300c-74.44,0-135-60.56-135-134.999C30,90.562,90.56,30,165,30s135,60.562,135,135.001C300,239.44,239.439,300,165,300z"
      />
      <path
        d="M164.998,70c-11.026,0-19.996,8.976-19.996,20.009c0,11.023,8.97,19.991,19.996,19.991
              c11.026,0,19.996-8.968,19.996-19.991C184.994,78.976,176.024,70,164.998,70z"
      />
      <path
        d="M165,140c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-90C180,146.716,173.284,140,165,140z
              "
      />
    </svg>
  
    <span>This video is not available in your region</span>
  </div>
  
  <div>
    <span
      id="online-toast"
      [style.display]="status == 'OFFLINE' ? 'block' : 'none'"
      
      ></span
    >
  </div>
  
    </div>

    <!-- Video details -->
    <div class="player-list-dtls-wrap">
      <div class="player-lft-inner-dtls">
        <h2>{{ video.title }}</h2>
        <p>{{ video.description }}</p>
      </div>
      <div class="player-rgt-inner-dtls">
        <ul class="plyr-tls-wrp">
          <li *ngIf="course" (click)="openPlaylistPopup()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.6665 2.49984C1.6665 2.0396 2.0396 1.6665 2.49984 1.6665H17.4998C17.9601 1.6665 18.3332 2.0396 18.3332 2.49984V3.33317C18.3332 3.79341 17.9601 4.1665 17.4998 4.1665H2.49984C2.0396 4.1665 1.6665 3.79341 1.6665 3.33317V2.49984Z" fill="white"/>
              <path d="M1.6665 8.33333C1.6665 7.8731 2.0396 7.5 2.49984 7.5H15.8332C16.2934 7.5 16.6665 7.8731 16.6665 8.33333V9.16667C16.6665 9.6269 16.2934 10 15.8332 10H2.49984C2.0396 10 1.6665 9.6269 1.6665 9.16667V8.33333Z" fill="white"/>
              <path d="M1.6665 14.1668C1.6665 13.7066 2.0396 13.3335 2.49984 13.3335H9.1665C9.62674 13.3335 9.99984 13.7066 9.99984 14.1668V15.0002C9.99984 15.4604 9.62674 15.8335 9.1665 15.8335H2.49984C2.0396 15.8335 1.6665 15.4604 1.6665 15.0002V14.1668Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8337 12.0837C15.8337 11.8535 15.6471 11.667 15.417 11.667L14.5837 11.667C14.3535 11.667 14.167 11.8535 14.167 12.0837V14.167H12.0837C11.8535 14.167 11.667 14.3535 11.667 14.5837V15.417C11.667 15.6471 11.8535 15.8337 12.0837 15.8337H14.167V17.917C14.167 18.1471 14.3535 18.3337 14.5837 18.3337H15.417C15.6471 18.3337 15.8337 18.1471 15.8337 17.917V15.8337H17.917C18.1471 15.8337 18.3337 15.6471 18.3337 15.417V14.5837C18.3337 14.3535 18.1471 14.167 17.917 14.167H15.8337V12.0837Z" fill="white"/>
            </svg>              
            <span>{{ 'PLAYER.MY_LIST' | translate }}</span>
          </li>
          <li *ngIf="course" (click)="openSharePopup()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 7.26207C20 7.07234 19.9219 6.89376 19.7879 6.75984L14.0737 1.04555C13.9397 0.911621 13.7612 0.833496 13.5714 0.833496C13.1808 0.833496 12.8571 1.15716 12.8571 1.54778V4.40492H10.3571C6.69643 4.40492 2.14286 5.05225 0.591518 8.90269C0.122768 10.0857 0 11.3692 0 12.6192C0 14.1817 0.78125 16.2241 1.41741 17.6527C1.54018 17.9094 1.66295 18.2665 1.83036 18.5009C1.90848 18.6125 1.98661 18.6906 2.14286 18.6906C2.36607 18.6906 2.5 18.5121 2.5 18.3C2.5 18.1214 2.45536 17.9206 2.4442 17.742C2.41071 17.2844 2.38839 16.8268 2.38839 16.3692C2.38839 11.0456 5.54688 10.1192 10.3571 10.1192H12.8571V12.9764C12.8571 13.367 13.1808 13.6906 13.5714 13.6906C13.7612 13.6906 13.9397 13.6125 14.0737 13.4786L19.7879 7.7643C19.9219 7.63037 20 7.4518 20 7.26207Z" fill="white"/>
            </svg>              
            <span>{{ 'PLAYER.SHARE' | translate }}</span>
          </li>
        </ul>
        <a target="_blank" *ngIf="isAllowedToView() && course.course_documents" href="{{ course.course_documents }}" class="plyr-downld-btn">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 6.875V0H4.125C3.50156 0 3 0.501562 3 1.125V22.875C3 23.4984 3.50156 24 4.125 24H19.875C20.4984 24 21 23.4984 21 22.875V8H14.125C13.5063 8 13 7.49375 13 6.875ZM15.5297 16.2844L12.5625 19.5C12.2531 19.8094 11.7469 19.8094 11.4375 19.5L8.475 16.2844C7.99687 15.8109 8.33437 15 9 15H11V11.25C11 10.8375 11.3375 10.5 11.75 10.5H12.25C12.6625 10.5 13 10.8375 13 11.25V15H15C15.6703 15 16.0031 15.8109 15.5297 16.2844ZM20.6719 4.92188L16.0828 0.328125C15.8719 0.117188 15.5859 0 15.2859 0H15V6H21V5.71406C21 5.41875 20.8828 5.13281 20.6719 4.92188Z" fill="white"/>
          </svg>
          <span>{{ 'PLAYER.DOWNLOAD_PDF' | translate }}</span>
        </a>
      </div>
    </div>
  </div>  
  <div class="plyr-rgt-set">
    <div class="prfile-player-wrp">
      <div *ngIf="course" class="plyr-prof-icn">
        <img src="{{ course?.profile_picture }}" alt="">
      </div>
      <div class="plyr-prof-detls" [ngClass]="courseType" routerLink="/webseries/{{ course.slug }}">
        <h4>{{ course.instructor_first_name }} {{ course.instructor_last_name }}</h4>
        <p>{{ genreSubtitle }}</p>
      </div>
    </div>
    <div class="plyr-time-detils">
      <h4>{{ course.title }}</h4>
      <ul>
        <li>
          {{ course.total_chapters + course.presentations.length }} {{ 'PLAYER.VIDEOS' | translate }}
        </li>
        <li>
          {{ course.total_duration }}
        </li>
      </ul>
    </div>
    <div class="custom-flow">
      <div class="tabss">
        <div class="tabs" *ngIf="course.trailer">
          <input type="checkbox" id="chck1" checked>
          <label class="tabs-label" for="chck1">{{ 'PLAYER.TRAILER' | translate }}</label>
          <div class="tabs-content">
            <ul>
              <li class="related-thum-wrp" routerLink="/watch/{{ course.trailer.slug }}">
                <div class="related-thum-lft">
                  <img *ngIf="course?.trailer?.thumbnail_image; else noThumb" class="thumb" src="{{ course.trailer.thumbnail_image }}" alt="">
                  <ng-template #noThumb>
                    <img class="thumb" src="../../../assets/images/no-thumbnail.png" alt="">
                  </ng-template>
                  <span class="card-timer">{{ course.trailer.video_duration }}</span>
                  <span class="continue-bar" [ngStyle]="{ 'width.%': course.trailer.is_video_completed === 1 ? '100' : course.trailer.progress_percentage }"></span>
                  <svg class="locked" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 12V8.5C8 6.5 9.61161 5 11.5 5C13.3884 5 15 6.5 15 8.5V12H8ZM19 13C19 12.5 18.5 12 18 12H17V8.5C17 5.5 14.5 3 11.5 3C8.5 3 6 5.5 6 8.5V12H5C4.5 12 4 12.5 4 13V21C4 21.5 4.5 22 5 22H18C18.5 22 19 21.5 19 21V13Z" fill="white"/>
                  </svg>
                </div>
                <div class="related-thum-rgt">
                  <p>{{ 'PLAYER.TRAILER' | translate }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="tabs" *ngIf="course.presentations.length > 0">
          <input type="checkbox" id="chck2" checked>
          <label class="tabs-label" for="chck2">{{ 'PLAYER.PRESENTATION' | translate }}</label>
          <div class="tabs-content">
            <ul>
              <li class="related-thum-wrp" *ngFor="let presentation of course.presentations" (click)="goToPlayer(presentation)">
                <div class="related-thum-lft" [class.inactive]="!isLogged">
                  <img *ngIf="presentation?.thumbnail_image; else noThumb" class="thumb" src="{{ presentation.thumbnail_image }}" alt="">
                  <ng-template #noThumb>
                    <img class="thumb" src="../../../assets/images/no-thumbnail.png" alt="">
                  </ng-template>
                  <span class="card-timer">{{ presentation.video_duration }}</span>
                  <span class="continue-bar" [ngStyle]="{ 'width.%': presentation.is_video_completed === 1 ? '100' : presentation.progress_percentage }"></span>
                  <svg class="locked" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 12V8.5C8 6.5 9.61161 5 11.5 5C13.3884 5 15 6.5 15 8.5V12H8ZM19 13C19 12.5 18.5 12 18 12H17V8.5C17 5.5 14.5 3 11.5 3C8.5 3 6 5.5 6 8.5V12H5C4.5 12 4 12.5 4 13V21C4 21.5 4.5 22 5 22H18C18.5 22 19 21.5 19 21V13Z" fill="white"/>
                  </svg>
                </div>
                <div class="related-thum-rgt">
                  <p>{{ 'PLAYER.PRESENTATION' | translate }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <ng-container *ngFor="let module of course.modules; let i = index">
          <div class="tabs" *ngIf="module.chapters.length > 0">
            <input type="checkbox" id="chck{{ i + 3 }}" checked>
            <label class="tabs-label" for="chck{{ i + 3 }}">{{ module.title }}
              <span>{{ module.module_description }}</span></label>
            <div class="tabs-content">
              <ul>
                <li class="related-thum-wrp" (click)="goToPlayer(chapter)" *ngFor="let chapter of module.chapters">
                  <div class="related-thum-lft" [class.inactive]="!isChapterAllowed(chapter)">
                    <img src="{{ chapter.thumbnail_image }}" alt="">
                    <span class="card-timer">{{ chapter.video_duration }}</span>
                    <span class="continue-bar" [ngStyle]="{ 'width.%': chapter.is_video_completed === 1 ? '100' : chapter.progress_percentage }"></span>
                    <svg class="locked" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 12V8.5C8 6.5 9.61161 5 11.5 5C13.3884 5 15 6.5 15 8.5V12H8ZM19 13C19 12.5 18.5 12 18 12H17V8.5C17 5.5 14.5 3 11.5 3C8.5 3 6 5.5 6 8.5V12H5C4.5 12 4 12.5 4 13V21C4 21.5 4.5 22 5 22H18C18.5 22 19 21.5 19 21V13Z" fill="white"/>
                    </svg>
                  </div>
                  <div class="related-thum-rgt">
                    <p>{{ chapter.title }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>