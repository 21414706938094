import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from '../core/services/api.service';
import { Router, NavigationEnd } from '@angular/router';
import videoJs from '../../assets/js/play.es.js';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { HeaderService } from '../core/services/header.service';

@Component({
  selector: 'app-maestros',
  templateUrl: './maestros.component.html',
  styleUrls: ['./maestros.component.scss']
})
export class MaestrosComponent implements OnInit {

  mostViewedCourses: any = [];
  maestrosCourses: any = [];
  bannerData = [];
  bannerVideo: any;
  bannerPlayer: any;
  decrypted: any;
  encrypted: any;
  plainText: any;
  cipherText: any;
  isBannerMuted = true;
  currentUserName: string;
  bannerTeasers: any;
  isBannerTextHidden = false;
  routeSub: any;
  bannerConfiguration: any;
  pageScolled = false;
  pageBottom = true;
  page = 1;
  new: any;
  loadingSlider: any = [];
  banner: any = [];
  bannerVideoPlaying = false;
  isLogged: Boolean = false;
  coursesLoading = true;
  slides = [
    {img: '../../../assets/images/brands/1.png'},
    {img: '../../../assets/images/brands/2.png'},
    {img: '../../../assets/images/brands/3.png'},
    {img: '../../../assets/images/brands/4.png'},
    {img: '../../../assets/images/brands/5.png'},
    {img: '../../../assets/images/brands/6.png'},
    {img: '../../../assets/images/brands/7.png'},
    {img: '../../../assets/images/brands/8.png'}
  ];
  slideConfig = {'slidesToShow': 4, 'slidesToScroll': 4, 'infinite': false, 'dots': false,
  responsive: [
    {
    breakpoint: 1200,
    settings: {
    slidesToShow: 3,
    slidesToScroll: 3
    }
    },
    {
    breakpoint: 901,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
    },
    {
      breakpoint: 501,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
  };
  slideConfig2 = {'slidesToShow': 3, 'slidesToScroll': 3, 'infinite': false, 'dots': false,
  responsive: [
    {
    breakpoint: 1200,
    settings: {
    slidesToShow: 2,
    slidesToScroll: 2
    }
    },
    {
    breakpoint: 901,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
    },
    {
      breakpoint: 501,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
  };

  @HostListener('window:scroll', ['$event'])
  onscroll() {
    if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 200)) {
      if (this.pageBottom === false) {
        this.pageBottom = true;
        this.getCourses();
      }
    }
  }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private headerService: HeaderService
  ) {
    localStorage.setItem('previousUrl', this.router.url);
    this.routeSub = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    if (this.pageScolled === false) {
      this.pageScolled = true;
      this.getCourses();
    }
    if (localStorage.getItem('currentUser')) {
      this.isLogged = true;
    }
    this.apiService.callGetAPI('v2/home-page-configuration', environment.commonApiUrl).subscribe(
      (data: any) => {
        data.response.forEach(elem => {
          const object = {
            [elem.name_slug]: elem.name_text,
          };
          this.bannerConfiguration = {
            ...this.bannerConfiguration, ...object
          };
        });
      }
    );
    this.loadingSlider.data = [];
    for (let i = 0; i <= 3; i++) {
      this.loadingSlider.data.push({
        thumbnail_image: environment.defaultImage
      });
    }
    this.headerService.newData$.subscribe((data) => {
      this.new = data;
    });
    this.apiService.callGetAPI('v2/maestros_most_viewed').subscribe(
      (data: any) => {
        this.mostViewedCourses = data.response;
      }
    );
    this.apiService.callGetAPI('v2/home_page_categories').subscribe(
      (data: any) => {
        this.bannerTeasers = data;
        this.getBannerVideo();
      }
    );
  }

  getCourses() {
    this.coursesLoading = true;
    this.apiService.callGetAPI('v2/maestros_courses?page=' + this.page).subscribe(
      (data: any) => {
        this.coursesLoading = false;
        this.maestrosCourses.push(...data.response.courses);
        if (data.response.next_page_url !== null) {
          this.pageBottom = false;
          this.page = data.response.current_page + 1;
        }
      }
    );
  }

  getBannerVideo() {
    const url = 'v2/watchvideo/' + this.bannerTeasers.response.data.banner_teasers[4].slug;
    this.apiService.callGetAPI(url).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.bannerVideo = data['response'].videos;
          this.decrypt();
        }
      },
      (error: any) => {
        if (
          error.status === 403 &&
          error &&
          error.error &&
          error.error.response &&
          error.error.response.data &&
          error.error.response.data.title
        ) {
          // this.videoTitle = error.error.response.data.title;
          return;
        }
      }
    );
  }
  hexToBinary(num) {
    const hex = num,
      bytes = [];
    for (let i = 0; i < hex.length - 1; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return String.fromCharCode.apply(String, bytes);
  }
  d2h(d) {
    return d.toString(16);
  }
  binaryToHex(num) {
    let str = '',
      i = 0;
    const tmp_len = num.length;
    let c;
    for (; i < tmp_len; i += 1) {
      c = num.charCodeAt(i);
      str += this.d2h(c);
    }
    return str;
  }
  decrypt() {
    if (typeof this.bannerVideo.passphrase !== 'undefined') {
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(this.bannerVideo.passphrase)
      });
      this.decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        CryptoJS.enc.Hex.parse(environment.crypto_key),
        {
          keySize: 256,
          iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
          mode: CryptoJS.mode.CBC
        }
      );
      const change = this.decrypted.toString(CryptoJS.enc.Utf8);
      this.plainText = parseInt(this.hexToBinary(change), 10);
      this.setTeaserOneConfig();
    }
  }
  encrypt() {
    const utf8 = CryptoJS.enc.Utf8.parse('vplayed/' + this.plainText);
    const hex_utf = utf8.toString(CryptoJS.enc.Utf8);
    const hex = this.binaryToHex(hex_utf);
    this.encrypted = CryptoJS.AES.encrypt(
      hex,
      CryptoJS.enc.Hex.parse(environment.crypto_key),
      {
        keySize: 256,
        iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
        mode: CryptoJS.mode.CBC
      }
    );
    this.cipherText = this.encrypted.ciphertext.toString(CryptoJS.enc.utf8);
    return this.cipherText;
  }
  setTeaserOneConfig() {
    const banner_video = document.getElementById('banner_video');
    videoJs.Hls.xhr.beforeRequest = (options) => {
      options.headers = [];
      if (this.bannerVideo.is_live === 0) {
        options.headers['Title'] = this.encrypt();
      }
    };
    this.bannerPlayer = videoJs(banner_video, {
      muted: this.isBannerMuted === true ? true : false,
      bigPlayButton: false,
      controls: false,
      loop: true,
      preload: 'auto',
      fluid: true,
      autoplay: false,
      progressControl: {
        seekBar: false
      },
      html5: {
        hls: {
          customDrm: {
            keys: 'MjMyOGMwYWM4MmNhM2ZjNmY4ZDcyNzI0NWQzMDgzYmUxMzU3ZjA0NGZmNWNiNGMzYTExNDJiMDkzY2YyZjNjOTAwNTM0MjE4NTUxMmM5ODlhMWYwMzI3YjQwYmY3YmY1YjE2MDBkMTYzYTA1ZTFkYzE4OWJhMjUwOGM1MTIwODksNDg3MGVjMDY0YzEwODE0MmNjMGZmYmQ2ZmZkZjFlMDhlNzU2YTZkZWYyYzc4MDQ2ODlmZTRhZTBmYzk4NTIyMSxiMmJmNWE5NzAwNGJiMzFkNmY1YTljMmM4NDQ0OTc1OQ'
          }
        }
      }
    });
    this.bannerPlayer.src({
      src: this.bannerVideo.hls_playlist_url,
      type: 'application/x-mpegURL'
    });
  }

  showBannerVideo() {
    this.isBannerTextHidden = !this.isBannerTextHidden;
    this.bannerVideoPlaying = true;
    this.bannerPlayer.play();
  }

  onStopButtonClick() {
    this.bannerPlayer.pause();
    this.isBannerTextHidden = !this.isBannerTextHidden;
    this.bannerVideoPlaying = false;
  }

  onBannerSoundClick() {
    if (this.isBannerMuted) {
      this.bannerPlayer.muted(true);
    } else {
      this.bannerPlayer.muted(false);
    }
  }

  onChapterClick(slide) {
    if (slide.type_id === 2 && !this.isLogged) {
      return;
    }
    const url = 'watch/' + slide.slug;
    this.router.navigate([url]);
  }

}
