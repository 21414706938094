import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor() { }

  private data = new BehaviorSubject({});
  userData = this.data.asObservable();

  setUserData(data: object) {
    this.data.next(data);
  }
}
