import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ApiService} from '../../core/services/api.service';
import { UserService } from '../../core/services/user.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  submitted = false;
  LoggedIn = localStorage.getItem('currentUser');
  error: any = {};
  user: any = {};

  constructor(
    private router: Router,
    private apiService: ApiService,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (this.LoggedIn) {
      this.router.navigate(['home']);
    }
  }

  forgotPassword() {
    this.submitted = true;
    this.apiService.callPostAPI(
      'v2/auth/forgotpassword',
      {
        email: this.user.email
      },
      environment.userApiUrl
    ).subscribe(
      data => {
        this.submitted = false;
        this.apiService.successResponse(data);
        this.router.navigate(['login']);
      },
      error => {
        this.submitted = false;
        this.error = this.apiService.failureResponse(error);
      }
    );
  }

}
