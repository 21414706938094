<div class="registers drops">
  <ul>
    <li>1</li>
    <li class="actives">2</li>
    <li>3</li>
  </ul>
  <h2>Queremos conocerte mejor<span>.</span></h2>
  <h5>Cuéntanos algo más sobre ti para mejorar 
     <span>tu experiencia dentro de Maestros del Deporte</span></h5>
  <div class="form">    
    <form #regDataForm="ngForm" (ngSubmit)="onSubmit(regDataForm)">
    <div class="flexer">
      <div class="groups">
        <label>Nombre</label>
        <input type="text" #firstName="ngModel" name="firstName" [(ngModel)]="userData.name" required
        (focus)="onFocus()"/>
        <span class="error" *ngIf="firstName.errors?.required && ( touched || firstName?.touched )">
          {{ 'SIGN_UP.NAME_REQUIRED' | translate }}
        </span>
        <span class="error" *ngIf="error?.firstName">
          {{ error.firstName }}
        </span>
      </div>
      <div class="groups">
        <label>Apellidos</label>
        <input type="text" #surName="ngModel" name="surName" [(ngModel)]="userData.surname" required 
        (focus)="onFocus()"/>
        <span class="error" *ngIf="surName.errors?.required && ( touched || surName?.touched )">
          {{ 'SIGN_UP.SURNAME_REQUIRED' | translate }}
        </span>
        <span class="error" *ngIf="error?.surName">
          {{ error.surName }}
        </span>
      </div>
    </div>
    <div class="flexer">
      <div class="groups date-fld">
        <label>Fecha de nacimiento</label>
        <ng-datepicker [options]="options" autocomplete="off" (click)="onChange($event)" [(ngModel)]="user.dob" #dob="ngModel"
        name="dob" class="dob">
        </ng-datepicker>
        <ng-container>
        <span class="error" *ngIf="dob.invalid  && datepick">
          DOB is invalid
        </span>
        </ng-container>
        <span class="error" *ngIf="datepick">{{ 'MY_PROFILE.D_O_B_AFE_ERROR' | translate }}</span>
      </div>
      <div class="groups rad-bx">
        <label>País</label>
        <ng-container *ngIf="countries.length > 0">
        <ng-select name="input_countries" [(ngModel)]="selectedCountries" 
        [items]="countries" bindLabel="country_name">
        </ng-select>
        </ng-container>
        <span class="error" *ngIf="!selectedCountries && touched">
          {{ 'SIGN_UP.COUNTRY_REQUIRED' | translate }}
        </span>
      </div>
    </div>
    <div class="flexer">
      <div class="groups chk-bx">
        <label>Deportes de mi interés</label>
        <span class="count-pos">{{ selectedSports.length }}</span>
        <ng-container *ngIf="sports.length > 0">
          <ng-select name="input_sports" [items]="sports" [multiple]="true" [closeOnSelect]="false"
          bindLabel="name" [(ngModel)]="selectedSports" placeholder="Deportes">
          </ng-select>
        </ng-container>
        <span class="error" *ngIf="(selectedSports.length < 3 || selectedSports.length > 5) && touched">
          {{ 'SIGN_UP.SPORTS_REQUIRED' | translate }}
        </span>
        <p>Elige un mínimo de 3 deportes</p>
      </div>
      <div class="groups chk-bx">
        <label>Temas que me interesan</label>
        <span class="count-pos">{{ selectedCategories.length }}</span>
        <ng-container *ngIf="categories.length > 0">
          <ng-select name="input_categories" [items]="categories" [multiple]="true" [closeOnSelect]="false"
          bindLabel="title" [(ngModel)]="selectedCategories" placeholder="Categorías">
          </ng-select>
        </ng-container>
        <span class="error" *ngIf="(selectedCategories.length < 3 || selectedCategories.length > 5) && touched">
          {{ 'SIGN_UP.CATEGORIES_REQUIRED' | translate }}
        </span>
        <p>Elige un mínimo de 3 categorías</p>
      </div>
    </div>
        <button type="submit" [disabled]="submitted">Siguiente</button>
    </form>

  </div>
</div>