<div class="card-comn" [class.inactive]="!isChapterAllowed(slide)" [ngClass]="instructorType(slide)" (mouseover)="playTeaserNew(slide)" (mouseleave)="resetTeaserNew(slide)">
  <div (click)="onCourseClick()" class="card-in">
    <div class="comn-logo">
      <img src="../../../assets/images/svg/oro.svg" class="oro" alt="">
      <img src="../../../assets/images/svg/plata.svg" class="plata" alt="">
      <img src="../../../assets/images/svg/bronce.svg" class="bronce" alt="">
      <img class="pildora" src="../../../../assets/images/svg/phidora-logo.svg" alt="">                     
    </div>
    <img src="{{ thumbnailImage }}" alt="" width="100%">
    <div class="card-card-det">
      <h2>{{ instructorFirstName }} {{ instructorLastName }} <span *ngIf="chapterOrder === ''">{{ slideType }} </span> <span *ngIf="chapterOrder != ''">{{ chapterOrder }}</span></h2>
      <p *ngIf="!slide.season_name; else seasonName">{{ subTitle.substring(0,45) }}{{ subTitle.length > 45 ? '...' : '' }}</p>
      <ng-template #seasonName>
        <p >{{ slideTitle }}</p>
      </ng-template>
    </div>
    <div class="trailer-set" *ngIf="!slide.season_name">
      <video *ngIf="place" id="trailer-video-{{ place }}-{{ slide.slug }}"></video>
      <video *ngIf="!place"id="trailer-video-{{ slide.slug }}"></video>

      <img src="{{ thumbnailImage }}" alt="" *ngIf="!isTrailerHovered">
      <div class="trailer-card-det">
        <h2>{{ instructorFirstName }} {{ instructorLastName }} <span >{{ slideType }} </span></h2>
        <p>{{ slideTitle }}</p>
        <h6>{{ subTitle.substring(0,45) }}{{ subTitle.length > 45 ? '...' : '' }} </h6>
      </div>      
    </div>
    <span class="card-timer">{{ slide.video_duration?slide.video_duration.substring(3,8):'00:00' }}</span>
    <span class="continue-bar" [ngStyle]="{ 'width.%': slide.is_video_completed === 1 ? '100' : slide.progress_percentage }"></span>
    <svg class="locked" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 12V8.5C8 6.5 9.61161 5 11.5 5C13.3884 5 15 6.5 15 8.5V12H8ZM19 13C19 12.5 18.5 12 18 12H17V8.5C17 5.5 14.5 3 11.5 3C8.5 3 6 5.5 6 8.5V12H5C4.5 12 4 12.5 4 13V21C4 21.5 4.5 22 5 22H18C18.5 22 19 21.5 19 21V13Z" fill="white"/>
    </svg>
    <div class="thumbnl-new">
      <img *ngIf="!this.slide.instructor_type_id" class="" src="{{ thumbnailInnerImage }}" alt="">
    </div>    
  </div>
  <!-- <div *ngIf="!most_viewed" class="volume-wrp" [ngClass]="isTrailerMuted === false ? 'active' : ''">
    <span class="volume-mute" (click)="isTrailerMuted = !isTrailerMuted; soundTrailer()"></span>
    <span class="volume-unmute" (click)="isTrailerMuted = !isTrailerMuted; soundTrailer()"></span>
  </div> -->
  <div *ngIf="slide.trailer" class="volume-wrp" [ngClass]="isTrailerMuted === false ? 'active' : ''">
    <span class="volume-mute" (click)="isTrailerMuted = !isTrailerMuted; soundTrailer()"></span>
    <span class="volume-unmute" (click)="isTrailerMuted = !isTrailerMuted; soundTrailer()"></span>
  </div>
</div>