import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class UserService {
    constructor() {
        // Observable User details
        this.currentUser = new BehaviorSubject(localStorage.getItem('currentUser'));
        this.localUser = this.currentUser.asObservable();
        // Observable popup details
        this.popup = new BehaviorSubject('close');
        this.display = this.popup.asObservable();
        // Observable popup details
        this.sharePopup = new BehaviorSubject('close');
        this.displayShare = this.sharePopup.asObservable();
        // Observable popup details
        this.url = new BehaviorSubject('home');
        this.nextPage = this.url.asObservable();
        this.welcomePopup = new BehaviorSubject(false);
        this.showPopup = this.welcomePopup.asObservable();
        this.verifyMailPopup = new BehaviorSubject(false);
        this.verifyPopup = this.verifyMailPopup.asObservable();
        this.showLogoutHeader = new BehaviorSubject(true);
        this.getLogoutHeader = this.showLogoutHeader.asObservable();
    }
    changeLogoutHeader(data) {
        this.showLogoutHeader.next(data);
    }
    // user details
    userData(data) {
        this.currentUser.next(data);
    }
    // login, register and forget password popup
    displayPopup(data) {
        this.popup.next(data);
    }
    // login, register and forget password popup
    displaySharePopup(data) {
        this.sharePopup.next(data);
    }
    // redirect next page
    redirectUrl(data) {
        this.url.next(data);
    }
    showWelcomePopup(data) {
        this.welcomePopup.next(data);
    }
    showVerifyMailPopup(data) {
        this.verifyMailPopup.next(data);
    }
}
UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(); }, token: UserService, providedIn: "root" });
