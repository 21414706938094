import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserDataService } from '../../core/services/user-data.service';
import { NgForm } from '@angular/forms';
import { ApiService } from '../../core/services/api.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register-pass',
  templateUrl: './register-pass.component.html',
  styleUrls: ['./register-pass.component.scss']
})
export class RegisterPassComponent implements OnInit {

  pass: string;
  strength: string;
  range: number;
  bgcolor: string;
  email: string;
  userData: any;
  error: any = {};
  showTooltip = false;
  minChars = false;
  lowerUpper = false;
  hasNumbers = false;
  confirmPasswordValid = false;

  submitted = false;
  touched = false;
  passStrength: string;
  pass_verify: any;

  constructor(
    private userDataService: UserDataService,
    private apiService: ApiService,
    private toasterService: ToastrService
  ) {
    this.strength = '0%';
    this.bgcolor = '#979797';
    this.range = 0;
    this.passStrength = 'Seguridad';
  }

  @Output() showDataPageEvent = new EventEmitter();

  ngOnInit() {
    this.userDataService.userData.subscribe(
      data => this.userData = data
    );
  }

  onSubmit(form: NgForm) {
    this.touched = true;
    if (this.range < 2 || !this.hasNumbers || !this.lowerUpper) {
      return;
    }
    if (form.valid && this.confirmPasswordValid) {
      this.submitted = true;
      // this.apiService.callPostAPI(
      //   'v2/auth/check_email',
      //   {
      //     email: form.value.email
      //   },
      //   environment.userApiUrl
      //   )
      //   .subscribe (
      //     data => {
      //       this.submitted = false;
      //       if (data['statusCode'] === 200 && data['error'] === false) {
      //         this.userData.password = form.value.pass;
      //         this.userDataService.setUserData(this.userData);
      //         this.showDataPage();
      //       }
      //     },
      //     error => {
      //       this.submitted = false;
      //       if (error.error.statusCode === 422) {
      //         if (typeof error.error.message === 'string' || error.error.message instanceof String) {
      //           this.toasterService.error('', error.error.message);
      //         } else {
      //           this.error = error.error.message;
      //         }
      //       } else {
      //         this.error = {};
      //         this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
      //       }
      //     }
      // );
      this.userData.password = form.value.pass;
      this.userDataService.setUserData(this.userData);
      this.showDataPage();
    }
  }

  onChange(form: NgForm) {
    if (this.pass.length > 0) {
      this.showTooltip = true;
    } else {
      this.showTooltip = false;
    }
    if (form.value.pass === form.value.pass_verify) {
      this.confirmPasswordValid = true;
    } else {
      this.confirmPasswordValid = false;
    }
    this.range = this.checkStrength(this.pass);
    this.strength = this.range * 25 + '%';
    this.minChars = this.pass.length >= 6;
    this.lowerUpper = this.checkLowerUpper(this.pass);
    this.hasNumbers = this.checkNumbers(this.pass);
    this.bgcolor = (this.range === 0) ? '#979797' :
                   (this.range === 1) ? '#CD1E1A' :
                   (this.range === 2) ? '#DD9F02' :
                   (this.range === 3) ? '#30C951' :
                   (this.range === 4) ? '#30C951' : '';
    this.passStrength = (this.range === 0) ? 'Seguridad' :
                        (this.range === 1) ? 'Débil' :
                        (this.range === 2) ? 'Media' :
                        (this.range === 3) ? 'Fuerte' :
                        (this.range === 4) ? 'Muy Fuerte' : '';
  }

  checkNumbers(pwd: any) {
    const numbers = /[0-9]+/.test(pwd);
    if (numbers) {
      return true;
    } else {
      return false;
    }
  }

  checkLowerUpper(pwd: any) {
    const lowerLetters = /[a-z]+/.test(pwd);
    const upperLetters = /[A-Z]+/.test(pwd);
    if (lowerLetters && upperLetters) {
      return true;
    } else {
      return false;
    }
  }

  checkStrength(pwd: any) {
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(pwd);
    const upperLetters = /[A-Z]+/.test(pwd);
    const numbers = /[0-9]+/.test(pwd);
    const symbols = regex.test(pwd);
    const checks = [lowerLetters, upperLetters, numbers, symbols];
    let passed = 0;
    for (const check of checks) {
      passed += check === true ? 1 : 0;
    }
    return (pwd.length < 6) ?  0 :  passed;
  }

  showDataPage() {
    this.showDataPageEvent.emit(true);
  }

  onFocus() {
    this.error = {};
  }

  onConfirmChange(form: NgForm) {
    if (form.value.pass === form.value.pass_verify) {
      this.confirmPasswordValid = true;
    } else {
      this.confirmPasswordValid = false;
    }
  }

}
