import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../core/services/api.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth/auth.service';
import { SubscriptionModel } from '../../modules/subscription/subscription.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../core/services/user.service';
import { HeaderService } from '../../core/services/header.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

  loggedIn = localStorage.getItem('currentUser');
  paymentData: any = {};
  DEVICE_RESTRICTION_TYPE: string;
  is_subscribed: any;
  subscriptionPlans: SubscriptionModel;
  subscriptionFeatures: any = {};
  welcomePopup: boolean;
  continueSwitch = false;
  showAlert = false;
  selectedPlan: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private toasterService: ToastrService,
    private userService: UserService,
    private headerService: HeaderService,
    private translate: TranslateService
  ) {
    this.fetchPlans();
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.userService.showPopup.subscribe(
      data => this.welcomePopup = data
    );
  }

  changePlan(plan: any) {
    if (!this.loggedIn) {
      this.router.navigate(['register']);
      return;
    }
    if (plan.is_subscribe === 1) {
      let sub_msg;
      this.translate.get('SUBSCRIPTION.ALREADY_SUBSCRIBED').subscribe(res => sub_msg = res );
      return this.toasterService.warning('', sub_msg);
    }
    this.apiService.callGetAPI('v2/activesubscription', environment.paymentApiUrl).subscribe(
      data => {
        if (!this.is_subscribed) {
          this.subscribe(plan);
        } else {
          if (data['response'].subscribed_plan.amount > plan.amount) {
            this.selectedPlan = plan;
            this.showAlert = true;
          } else {
            this.subscribe(plan);
          }
        }
      }
    );
  }

  subscribe(plan: any) {
    if (plan.amount === 0) {
      this.paymentData.subscription_plan_id = plan.slug;
      this.paymentData.price = plan.amount;
      this.paymentData.payment_method_id = 1;
      this.paymentData.payment_type = 'net-banking';
      this.paymentData.card_number = '';
      this.paymentData.name = '';
      this.paymentData.month = '';
      this.paymentData.year = '';
      this.paymentData.cvv = '';
      this.paymentData.coupon_code = null;
      this.paymentData.is_save = false;
      this.apiService.callPostAPI(
        'v2/subscription/add',
        this.paymentData,
        environment.paymentApiUrl
      ).subscribe(
        (data: any) => {
          const userValues = localStorage.getItem('currentUser');
          if (userValues) {
            const userData = this.authService.decryption(userValues);
            userData['response'].is_subscribed = 1;
            userData['response'].subscription_plan_id = data.response.transaction.subscription_plan_id;
            const userNewData = this.authService.encryption(userData);
            localStorage.setItem('currentUser', userNewData);
            this.headerService.setsubscriptionPlanId(data.response.transaction.subscription_plan_id);
          }
          localStorage.setItem('transaction_id', data['response'].transaction.transaction_id);
          this.router.navigate(['home']);
        },
        error => {
          this.router.navigate(['/subscription/payment-info/failure']);
          this.apiService.failureResponse(error, true);
        }
      );
    } else {
      this.fetchCards(plan);
    }
  }

  fetchCards(plan) {
    if (this.loggedIn) {
      this.router.navigate(['/checkout/svod/' + plan.slug]);
    } else {
      this.router.navigate(['register']);
    }
  }

  fetchPlans() {
    this.apiService
      .callGetAPI('v2/subscriptions', environment.paymentApiUrl)
      .subscribe(
        (data: any) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.DEVICE_RESTRICTION_TYPE = data.response.device_restriction_type;
            this.is_subscribed = data.response.subscription.find(
              (e) => e.is_subscribe
            );
            this.subscriptionPlans = data.response.subscription;
            this.subscriptionFeatures = data.response.subscription_features_web;
          } else {
            this.apiService.failureResponse(data, true);
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

}
