<section class="inner-common-banner">
  <video id="banner_video"></video>
  <img class="inner-ban-background" src="{{ bannerTeasers.response.data.banner_teasers[4].poster_image }}" alt="" [ngStyle]="{ display: isBannerTextHidden ? 'none' : '' }">
  <img class="bann-log-com" src="../../assets/images/inner-pag/ban-cap-logo.png" alt="" [ngStyle]="{ display: isBannerTextHidden ? 'none' : '' }">
  <div class="inncer-common-caption" *ngIf="bannerConfiguration" [ngStyle]="{ display: isBannerTextHidden ? 'none' : '' }">
    <h2>{{ 'MAESTROS.MAIN_TITLE' | translate }}<span> .</span></h2>
    <h4>{{ bannerConfiguration.maestros_page_title }}</h4>
    <p>{{ bannerConfiguration.maestros_page_info }}</p>
    <a (click)="showBannerVideo()" style="cursor: pointer;" class="inner-comm-btn">
      <img src="../../assets/images/svg/com-play.svg" alt="">      
      <span>{{ 'MAESTROS.SEE_PRESENTATION' | translate }}</span>
    </a>
  </div>
  <div class="contrl-wrp">
    <div class="ply-wrp">
      <div *ngIf="bannerVideoPlaying" (click)="onStopButtonClick()" class="vid-contrlproperty-1-multi-property-2-pause" [ngClass]="{ 'active' : bannerVideoPlaying }"></div>
    </div>
    <div class="volume-wrp">
      <div *ngIf="bannerVideoPlaying" (click)="isBannerMuted = !isBannerMuted; onBannerSoundClick()" class="vlm-wrpproperty-1-opciones-property-2-off" [ngClass]="{ 'active': isBannerMuted }"></div>
      <div *ngIf="bannerVideoPlaying" (click)="isBannerMuted = !isBannerMuted; onBannerSoundClick()" class="vlm-wrpproperty-1-opciones-property-2-on" [ngClass]="{ 'active': !isBannerMuted }"></div>
    </div>
  </div>
</section>

<section *ngIf="mostViewedCourses.length === 0" class="small-vplay container skeleton-loader common-slider bnner-sld">
  <div class="tittle-blk"></div>
  <div class="cs-slider">
    <app-contus-slider #contusSlider [id]="1" url="loadingSlider" (previousPages)="previous($event)"
      (nextPageCall)="next($event)" [total]="0" [length]="new.length" class="small-slider" [options]="{
        nav: true,
        loop: false,
        margin: 10,
        dots: false,
        stagePadding: 20,
        responsive: {
          '0': { items: 2, slideBy: 2, nav: false, margion: 10 },
          '600': { items: 3, slideBy: 3, nav: false, margion: 10 },
          '1000': { items: 6, slideBy: 6, margion: 10 },
          '1500': { items: 7, slideBy: 7, margion: 10 }
        }
      }">
      <app-loader-card class="item" *ngFor="let video of loadingSlider.data; let i = index"></app-loader-card>
    </app-contus-slider>
  </div>
</section>

<div class="common-slider bnner-sld" *ngIf="mostViewedCourses.length > 0">
  <div class="common-slider-head">
    <h2>{{ 'MAESTROS.MOST_VIEWED_HEADER' | translate }}</h2>
  </div>
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div [slide]="slide" [most_viewed]="true" slide ngxSlickItem class="slide" *ngFor="let slide of mostViewedCourses">
    </div>
  </ngx-slick-carousel>
</div>

<span class="section-divider"></span>

<ng-container *ngFor="let course of maestrosCourses">
  <section class="content-section-wrpa">
    <div class="content-main-ttl">
      <a routerLink="/webseries/{{ course.slug }}">
        <h2>{{ course.instructor_first_name }} {{ course.instructor_last_name }} <span>{{ course.sport_name }}</span></h2>
        <a class="view-ll-card">{{ 'MAESTROS.VIEW_COURSE' | translate }} <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.13181 9.45485C0.838921 9.74774 0.838921 10.2226 1.13181 10.5155C1.42471 10.8084 1.89958 10.8084 2.19247 10.5155L6.435 6.27297L6.43512 6.27287C6.72801 5.97997 6.72801 5.5051 6.43512 5.21221C6.4315 5.20859 6.42785 5.20501 6.42418 5.20148L2.19236 0.96967C1.89947 0.676777 1.42459 0.676777 1.1317 0.96967C0.838808 1.26256 0.838808 1.73744 1.1317 2.03033L4.84402 5.74265L1.13181 9.45485Z" fill="white"/>
          </svg>
        </a>
      </a>
    </div>
    <div class="content-slider-wrpa">
      <div class="slider-lft-wrp">
        <a routerLink="/webseries/{{ course.slug }}">
          <img src="{{ course?.profile_picture }}" alt="">
        </a>
        <p>{{ course.instructor_description.substring(0, 80) }}{{ course.instructor_description.length > 80 ? '...' : '' }}</p>
      </div>
      <div class="slider-rgt-wrp">
        <div class="common-slider">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig2">
              <div ngxSlickItem class="card-comn course-trailer oro-active" (click)="onChapterClick(course.trailer)" *ngIf="course?.trailer">
                  <div class="card-in">
                    <div class="comn-logo">
                      <img alt="" class="oro" src="../../../assets/images/svg/oro.svg">
                      <img alt="" class="plata" src="../../../assets/images/svg/plata.svg">
                      <img alt="" class="bronce" src="../../../assets/images/svg/bronce.svg">
                      <img class="pildora" src="../../../../assets/images/svg/phidora-logo.svg" alt="">
                    </div>
                    <img *ngIf="course?.trailer?.thumbnail_image; else noThumb" class="thumb" src="{{ course.trailer.thumbnail_image }}" alt="">
                    <ng-template #noThumb>
                      <img class="thumb" src="../../../assets/images/no-thumbnail.png" alt="">
                    </ng-template>
                    <div class="card-card-det">
                      <h2>{{ course.instructor_first_name }} {{ course.instructor_last_name }}<span>{{ 'COURSE.TRAILER' | translate }} </span>
                      </h2>
                      <p>{{ course.trailer.title.substring(0, 80) }}</p>
                    </div>
                    <div class="trailer-set">
                      <video id="trailer-video-lorem-ipsum"></video>
                    <img *ngIf="course?.trailer?.thumbnail_image; else noThumb" class="thumb" src="{{ course.trailer.thumbnail_image }}" alt="">
                    <ng-template #noThumb>
                      <img class="thumb" src="../../../assets/images/no-thumbnail.png" alt="">
                    </ng-template>
                    <div class="trailer-card-det">
                      <h2>{{ course.instructor_first_name }} {{ course.instructor_last_name }}<span>{{ 'COURSE.TRAILER' | translate }} </span>
                      </h2>
                      <p>{{ course.subtitle }}</p>
                      <h6>{{ course.trailer.title.substring(0, 80) }}</h6>
                    </div>
                  </div>
                  <span class="card-timer">{{ course.trailer.video_duration?course.trailer.video_duration.substring(3,8):'00:00' }}</span>
                  <span class="continue-bar" [ngStyle]="{ 'width.%': course.trailer.is_video_completed === 1 ? '100' : course.trailer.progress_percentage }"></span>
                  <svg class="locked" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M8 12V8.5C8 6.5 9.61161 5 11.5 5C13.3884 5 15 6.5 15 8.5V12H8ZM19 13C19 12.5 18.5 12 18 12H17V8.5C17 5.5 14.5 3 11.5 3C8.5 3 6 5.5 6 8.5V12H5C4.5 12 4 12.5 4 13V21C4 21.5 4.5 22 5 22H18C18.5 22 19 21.5 19 21V13Z" fill="white"></path></svg>
                  <div class="thumbnl-new"></div>
                </div>
                <div class="volume-wrp" ng-reflect-klass="volume-wrp" ng-reflect-ng-class="">
                  <span class="volume-mute"></span>
                  <span class="volume-unmute"></span>
                </div>
              </div>
              <ng-container *ngIf="course?.presentations.length > 0">
                <div ngxSlickItem class="card-comn course-presentation oro-active" [class.inactive]="!this.isLogged" (click)="onChapterClick(presentation)" *ngFor="let presentation of course.presentations">
                  <div class="card-in">
                    <div class="comn-logo">
                      <img alt="" class="oro" src="../../../assets/images/svg/oro.svg">
                      <img alt="" class="plata" src="../../../assets/images/svg/plata.svg">
                      <img alt="" class="bronce" src="../../../assets/images/svg/bronce.svg">
                      <img class="pildora" src="../../../../assets/images/svg/phidora-logo.svg" alt="">
                    </div>
                    <img *ngIf="presentation?.thumbnail_image; else noThumb" class="thumb" src="{{ presentation.thumbnail_image }}" alt="">
                    <ng-template #noThumb>
                      <img class="thumb" src="../../../assets/images/no-thumbnail.png" alt="">
                    </ng-template>
                    <div class="card-card-det">
                      <h2>{{ course.instructor_first_name }} {{ course.instructor_last_name }}<span >{{ 'COURSE.PRESENTATION' | translate }} </span>
                      </h2>
                      <p>{{ presentation.title.substring(0, 80) }}</p>
                    </div>
                    <div class="trailer-set">
                      <video id="trailer-video-lorem-ipsum"></video>
                    <img *ngIf="presentation?.thumbnail_image; else noThumb" class="thumb" src="{{ presentation.thumbnail_image }}" alt="">
                    <ng-template #noThumb>
                      <img class="thumb" src="../../../assets/images/no-thumbnail.png" alt="">
                    </ng-template>
                    <div class="trailer-card-det">
                      <h2>{{ course.instructor_first_name }} {{ course.instructor_last_name }} <span >{{ 'COURSE.PRESENTATION' | translate }} </span>
                      </h2>
                      <p >{{ course.subtitle }}</p>
                      <h6 >{{ presentation.title.substring(0, 80) }}</h6>
                    </div>
                  </div>
                  <span class="card-timer">{{ presentation.video_duration?presentation.video_duration.substring(3,8):'00:00' }}</span>
                  <span class="continue-bar" [ngStyle]="{ 'width.%': presentation.is_video_completed === 1 ? '100' : presentation.progress_percentage }"></span>
                  <svg class="locked" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M8 12V8.5C8 6.5 9.61161 5 11.5 5C13.3884 5 15 6.5 15 8.5V12H8ZM19 13C19 12.5 18.5 12 18 12H17V8.5C17 5.5 14.5 3 11.5 3C8.5 3 6 5.5 6 8.5V12H5C4.5 12 4 12.5 4 13V21C4 21.5 4.5 22 5 22H18C18.5 22 19 21.5 19 21V13Z" fill="white"></path></svg>
                  <div class="thumbnl-new"></div>
                  </div>
                  <div class="volume-wrp" ng-reflect-klass="volume-wrp" ng-reflect-ng-class="">
                    <span class="volume-mute"></span>
                    <span class="volume-unmute"></span>
                  </div>
                </div>
              </ng-container>
            <div [slide]="slide" [course]="course" slide ngxSlickItem class="slide" [chapterNumber]="i+1" *ngFor="let slide of course.chapters; let i = index">
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<!-- Skeleton start -->
<div class="skel-wrap" *ngIf="coursesLoading">
  <h2 class="skel-title skeleton-custm"></h2>
  <div class="skel-cont-wrap">
    <div class="skel-cont-left">
      <div class="skel-prof skeleton-custm"></div>
      <div class="skel-prof-desk">
        <span class="skel-prof-one skeleton-custm"></span>
        <span class="skel-prof-two skeleton-custm"></span>
        <span class="skel-prof-three skeleton-custm"></span>
      </div>
    </div>
    <div class="skel-cont-right">
      <span class="skeleton-custm"></span>
      <span class="skeleton-custm"></span>
      <span class="skeleton-custm"></span>
    </div>
  </div>
</div>
<!-- Skeleton Ends -->