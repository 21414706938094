<div class="registers">
  <ul *ngIf="welcomePopup">
    <li>1</li>
    <li>2</li>
    <li class="actives">3</li>
  </ul>
  <h2>{{ 'SUBSCRIPTION.SELECT_PLAN' | translate }}<span>.</span></h2>
  <div class="main-par">
    <div class="plan-wrpa">
      <table class="table">
        <thead>
          <tr class="active">
              <th style="background:#000" colspan="3"></th>
              <th *ngFor="let subscriptionPlan of subscriptionPlans; let i = index" class="plan{{i+1}}" [ngClass]="subscriptionPlan.is_subscribe === 1 ? 'active' : ''">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g>
                  <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path d="M238.933,0C106.974,0,0,106.974,0,238.933s106.974,238.933,238.933,238.933s238.933-106.974,238.933-238.933    C477.726,107.033,370.834,0.141,238.933,0z M238.933,443.733c-113.108,0-204.8-91.692-204.8-204.8s91.692-204.8,204.8-204.8    s204.8,91.692,204.8,204.8C443.611,351.991,351.991,443.611,238.933,443.733z" fill="#5bbc21" data-original="#000000" style=""/>
                    </g>
                  </g>
                  <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path d="M370.046,141.534c-6.614-6.388-17.099-6.388-23.712,0v0L187.733,300.134l-56.201-56.201    c-6.548-6.78-17.353-6.967-24.132-0.419c-6.78,6.548-6.967,17.353-0.419,24.132c0.137,0.142,0.277,0.282,0.419,0.419    l68.267,68.267c6.664,6.663,17.468,6.663,24.132,0l170.667-170.667C377.014,158.886,376.826,148.082,370.046,141.534z" fill="#5bbc21" data-original="#000000" style=""/>
                    </g>
                  </g>
                  </g>
                </svg>
                <div>
                  <h3>{{ (subscriptionPlan.name).substring(0, (subscriptionPlan.name).indexOf('-')) }}</h3>
                  <p *ngIf="subscriptionPlan.amount === 0; else elseBlock">GRATIS</p>
                  <ng-template #elseBlock><p>{{ subscriptionPlan.amount }} €</p></ng-template>
                  <h5>{{ subscriptionPlan.type }}</h5>
                  <label style="cursor: pointer;" (click)="changePlan(subscriptionPlan)">quiero unirme</label>
                </div>
              </th>
          </tr>
        </thead>
        <tbody>
          <tr class="empty">
            <td colspan="3"></td>
            <td><div>&nbsp;</div></td>
            <td><div>&nbsp;</div></td>
            <td><div>&nbsp;</div></td>
          </tr>
          <tr *ngFor="let feature of subscriptionFeatures">
            <td colspan="3"><div>{{ feature.feature }}</div></td>
            <ng-container *ngIf="feature.bronze_devices > 0 || feature.silver_devices > 0 || feature.gold_devices > 0; else NotDevice">
              <td *ngIf="feature.bronze_devices > 0; else DeviceEmpty">
                <div><h6>{{ feature.bronze_devices }}</h6></div>
              </td>
              <td *ngIf="feature.silver_devices > 0; else DeviceEmpty">
                <div><h6>{{ feature.silver_devices }}</h6></div>
              </td>
              <td *ngIf="feature.gold_devices > 0; else DeviceEmpty">
                <div><h6>{{ feature.gold_devices }}</h6></div>
              </td>
              <ng-template #DeviceEmpty>
                <td><div><h6>&nbsp;</h6></div></td>
              </ng-template>
            </ng-container>
            <ng-template #NotDevice>
              <td *ngIf="feature.bronze === 1; else BronzeEmpty"><div><h6><img src="../../../assets/images/ticks.png" alt=""></h6></div></td>
              <ng-template #BronzeEmpty>
                <td><div><h6>&nbsp;</h6></div></td>
              </ng-template>
              <td *ngIf="feature.silver === 1; else SilverEmpty"><div><h6><img src="../../../assets/images/ticks.png" alt=""></h6></div></td>
              <ng-template #SilverEmpty>
                <td><div><h6>&nbsp;</h6></div></td>
              </ng-template>
              <td *ngIf="feature.gold === 1; else GoldEmpty"><div><h6><img src="../../../assets/images/ticks.png" alt=""></h6></div></td>
              <ng-template #GoldEmpty>
                <td><div><h6>&nbsp;</h6></div></td>
              </ng-template>
            </ng-template>
          </tr>
          <!-- <tr *ngFor="let feature of subscriptionFeatures">
            <td colspan="3"><div>{{ feature.feature }}</div></td>
            <td *ngFor="let plan of subscriptionPlans">
              <div>
                <ng-container *ngIf="feature.plans.plan.includes(plan.slug); else elseBlock">
                  <h6>
                    <img src="../../../assets/images/ticks.png"/>
                  </h6>
                </ng-container>
                <ng-template #elseBlock>&nbsp;</ng-template>
              </div>
            </td>
          </tr> -->
          <!-- <tr>
            <td colspan="3">
              <div>Contenido exclusivo de las formaciones.</div>
            </td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
          </tr>
          <tr>
            <td colspan="3">
              <div>Multidispositivo: portátil, teléfono, tableta y TV*</div>
            </td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
          </tr>
          <tr>
            <td colspan="3">
              <div>Disponible en HD</div>
            </td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
          </tr>
          <tr>
            <td colspan="3">
              <div>Todos los cursos y píldoras de formación que desees</div>
            </td>
            <td><div><h6>&nbsp;</h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
          </tr>
          <tr>
            <td colspan="3">
              <div>Descarga apuntes en PDF de los cursos</div>
            </td>
            <td><div><h6>&nbsp;</h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
          </tr>
          <tr>
            <td colspan="3">
              <div>Pantallas que se pueden ver al mismo tiempo</div>
            </td>
            <td><div><h6>&nbsp;</h6></div></td>
            <td><div><h6>1</h6></div></td>
            <td><div><h6>2</h6></div></td>
          </tr>
          <tr>
            <td colspan="3">
              <div>Certificado oficial de la universidad al finalizar cada curso</div>
            </td>
            <td><div><h6>&nbsp;</h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
            <td><div><h6><img src="../../../assets/images/ticks.png"/></h6></div></td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="plan-alert-pop-wrap" *ngIf="showAlert">
  <div class="plan-alert-inner">
    <h6>{{ 'SUBSCRIPTION.CHANGE_CONFIRM' | translate }}</h6>
    <div class="plan-alert-btns">
      <a (click)="showAlert = false" class="common-btn">{{ 'SUBSCRIPTION.OK' | translate }}</a>
    </div>
    <svg (click)="showAlert = false" class="close" width="12" height="12" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6773 1.36396L7.72754 6.31371L2.77779 1.36396C2.42424 1.01041 2.07068 0.656854 1.36358 1.36396C0.656471 2.07107 1.01002 2.42462 1.36358 2.77817L6.31333 7.72792L1.36358 12.6777C1.01002 13.0312 0.797893 13.5262 1.36358 14.0919C2.07068 14.799 2.42424 14.4454 2.77779 14.0919L7.72754 9.14214L12.6773 14.0919C13.0308 14.4454 13.3844 14.799 14.0915 14.0919C14.7986 13.3848 14.4451 13.0312 14.0915 12.6777L9.14175 7.72792L14.0915 2.77817C14.4451 2.42462 14.7986 2.07107 14.0915 1.36396C13.3844 0.656854 13.0308 1.01041 12.6773 1.36396Z" fill="white"/>
    </svg>
  </div>
</div>