<section class="main-banner">  
  <video id="banner_video"></video>
  <div class="main-banner-caption" *ngIf="bannerConfiguration" [ngClass]="currentUserName != undefined ? 'active' : ''">
    <div class="banner-cap-content">
      <h4>{{ 'BANNER.GREETING' | translate }}, {{ currentUserName }}</h4>
      <h1>{{ bannerConfiguration.banner_title }}<span>.</span></h1>
      <h3>{{ bannerConfiguration.banner_info_1 }}</h3>
      <p>{{ this.bannerConfiguration.banner_info_2_p1 }} <span routerLink="/plans">{{ this.bannerConfiguration.banner_info_plan }} €</span> {{ this.bannerConfiguration.banner_info_2_p2 }}</p>
      <a routerLink="/register" class="common-bt">
        {{ 'BANNER.JOIN_FREE' | translate }}
      </a>
      <h6>*{{ bannerConfiguration.register_button_subtitle }}</h6>
    </div>
  </div>  
<svg class="scroll-dwn-icn" width="68" height="9" viewBox="0 0 68 9" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M66.8823 1L33.9412 8L1 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<div class="volume-wrp" [ngClass]="isBannerMuted === false ? 'active' : ''">
  <span class="volume-mute" (click)="isBannerMuted = !isBannerMuted; soundBanner()"></span>
  <span class="volume-unmute" (click)="isBannerMuted = !isBannerMuted; soundBanner()"></span>
</div>
</section>