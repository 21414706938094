import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class VideoShareService {
    constructor() {
        this.popup = new BehaviorSubject('close');
        this.display = this.popup.asObservable();
        this.getSliderMovedItems = new BehaviorSubject('');
        this.sliderMovedItems = this.getSliderMovedItems.asObservable();
        this.getSliderRowItemsCount = new BehaviorSubject('');
        this.sliderRowItemsCount = this.getSliderRowItemsCount.asObservable();
    }
    // video share popup
    displayPopup(data) {
        this.popup.next(data);
    }
    setSliderMovedItems(data) {
        this.getSliderMovedItems.next(data);
    }
    setSliderRowItems(data) {
        this.getSliderRowItemsCount.next(data);
    }
}
VideoShareService.ngInjectableDef = i0.defineInjectable({ factory: function VideoShareService_Factory() { return new VideoShareService(); }, token: VideoShareService, providedIn: "root" });
