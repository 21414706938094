<div class="container">
  <div class="registers forgot-wrp">
    <span class="bck">
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.36396 2.39354C7.75448 2.00301 7.75448 1.36985 7.36396 0.979324C6.97344 0.5888 6.34027 0.588801 5.94975 0.979324L0.301897 6.62717C0.298891 6.63011 0.2959 6.63306 0.292922 6.63604C0.151797 6.77716 0.0616706 6.94998 0.0225434 7.13157C-0.0466589 7.45253 0.0434575 7.80096 0.292892 8.05039C0.296577 8.05408 0.300283 8.05773 0.304011 8.06134L5.94978 13.7071C6.3403 14.0976 6.97347 14.0976 7.36399 13.7071C7.75451 13.3166 7.75451 12.6834 7.36399 12.2929L2.4143 7.3432L7.36396 2.39354Z" fill="white"/>
      </svg>
      <h6 routerLink="/login">{{ 'FORGOT_PASSWORD.BACK' | translate }}</h6>      
      </span>
    <h2>{{ 'FORGOT_PASSWORD.TITLE' | translate }}</h2>
    <h5>{{ 'FORGOT_PASSWORD.NO_WORRY' | translate }} <span>{{ 'FORGOT_PASSWORD.DESC' | translate }}</span></h5>
    <div class="form">      
      <form (ngSubmit)="forgotPassword()" #form="ngForm" autocomplete="nope">
        <div class="groups">
          <label>{{ 'FORGOT_PASSWORD.EMAIL' | translate }}</label>
          <input name="email" type="email" [(ngModel)]="user.email" #email="ngModel" required
          pattern=".+@.+\..+"/>
          <span class="error"
          *ngIf="!error?.email && email.invalid && email.touched && email.errors?.required">
          {{ 'FORGET_PASSWORD.EMAIL_VALID_ERROR_REQUIRED' | translate }}
        </span>
        <span class="error" *ngIf="!error?.email && email.errors?.pattern">
          {{ 'FORGET_PASSWORD.EMAIL_VALID_ERROR' | translate }}
        </span>
        <span class="error" *ngIf="error?.email">
          {{ 'FORGET_PASSWORD.EMAIL_NOT_REGISTERED' | translate }}
        </span>
        </div>        
          <button type="submit" [disabled]="submitted">{{ 'FORGOT_PASSWORD.SEND' | translate }}</button>
      </form>
    </div>
  </div>
</div>