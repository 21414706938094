import { Component, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent implements OnInit {
  notifications: any = [];
  page = 0;
  removeSingleNotify: any = [];
  @Output() notifyRead = new EventEmitter;
  final = false;
  flag = false;
  loading = false;
  @HostListener('scroll', ['$event'])
  onScroll(event) {
    const el = document.getElementById('notification_bottom');
    if (this.isElementInViewport(el) && this.flag === false && this.final === false) {
      this.flag = true;
      this.getNotifications();
        }
  }

  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.getNotifications();
      }

  isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
  }

  getNotifications() {
    this.loading = true;
    this.page += 1;
    this.apiService.callGetAPI('v2/notifications?page=' + this.page, environment.notificationApiUrl)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            if (this.page === 1) {
              this.notifications = data['response'].data;
            } else {
              this.notifications = this.notifications.concat(data['response'].data);
            }
          }
          this.final = (data['response'].next_page_url === null) ? true : false;
          this.flag = false;
          this.loading = false;
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }

  notificationRemoveAll() {
    this.apiService.callGetAPI('v2/notification/remove_all', environment.notificationApiUrl)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.notifications = [];
          }
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }

  mouseover(index) {
    this.removeSingleNotify[index] = true;
  }

  mouseout(index) {
    this.removeSingleNotify[index] = false;
  }

  removeSingleNotification(index) {
    this.apiService.callGetAPI('v2/notification/remove/' + this.notifications[index]._id, environment.notificationApiUrl)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.notifications.splice(index, 1);
          }
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }

  readSingleNotification(index) {
    if (this.notifications[index].read_at === null) {
      this.apiService.callGetAPI('v2/notification/read/' + this.notifications[index]._id, environment.notificationApiUrl)
        .subscribe(
          data => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              this.notifications[index].read_at = 'read';
              this.notificationRead(index);
            }
          },
          error => {
            this.apiService.failureResponse(error, true);
          });
    } else {
      this.notificationRead(index);
    }
  }

  notificationRead(index) {
    this.notifyRead.emit();
    this.router.navigate(['webseries/' + this.notifications[index].slug]);
  }
}
