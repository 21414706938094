import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class HeaderService {
    constructor(apiService) {
        this.apiService = apiService;
        this.apiData = new BehaviorSubject(null);
        this.apiData$ = this.apiData.asObservable();
        this.bannerData = new BehaviorSubject(null);
        this.bannerData$ = this.bannerData.asObservable();
        this.newData = new BehaviorSubject(null);
        this.newData$ = this.newData.asObservable();
        this.webseriesData = new BehaviorSubject(null);
        this.webseriesData$ = this.webseriesData.asObservable();
        this.mainmenuCategoryData = new BehaviorSubject(null);
        this.mainmenuCategoryData$ = this.mainmenuCategoryData.asObservable();
        this.routeHeader = new BehaviorSubject(null);
        this.routeForHeader = this.routeHeader.asObservable();
        this.notificationClicked = new BehaviorSubject(false);
        this.notificationClicked$ = this.notificationClicked.asObservable();
        this.breadcrumb = new BehaviorSubject('');
        this.breadcrumb$ = this.breadcrumb.asObservable();
        this.courseDetail = new BehaviorSubject(null);
        this.courseDetail$ = this.courseDetail.asObservable();
        this.subscriptionPlanId = new BehaviorSubject(null);
        this.subscriptionPlanId$ = this.subscriptionPlanId.asObservable();
        this.cookiePopup = new BehaviorSubject(true);
        this.cookiePopup$ = this.cookiePopup.asObservable();
        this.cookieConfigurePopup = new BehaviorSubject(false);
        this.cookieConfigurePopup$ = this.cookieConfigurePopup.asObservable();
        this.consentTwoChecked = new BehaviorSubject(false);
        this.consentTwoChecked$ = this.consentTwoChecked.asObservable();
    }
    fetchData() {
        return this.apiService
            .callGetAPI('v2/footer', environment.commonApiUrl)
            .pipe(map((data) => {
            return data;
        }));
    }
    setData(data) {
        this.apiData.next(data);
    }
    setHomeCategory(data) {
        this.homeCategoryData = data;
    }
    getHomeCategory() {
        return this.homeCategoryData;
    }
    setHomeCategories(data) {
        this.homeCategories = data;
    }
    getHomeCategories() {
        return this.homeCategories;
    }
    setHomeCategoryCourses(data) {
        this.homeCategoryCourses = data;
    }
    getHomeCategoryCourses() {
        return this.homeCategoryCourses;
    }
    setMainMenuCategory(data) {
        this.mainmenuCategoryData.next(data);
    }
    changeRouteForHeader(data) {
        this.routeHeader.next(data);
    }
    changeNotificationClicked(data) {
        this.notificationClicked.next(data);
    }
    changeBreadcrumb(data) {
        this.breadcrumb.next(data);
    }
    setCourseDetail(data) {
        this.courseDetail.next(data);
    }
    setsubscriptionPlanId(data) {
        this.subscriptionPlanId.next(data);
    }
    setCookiePopup(data) {
        this.cookiePopup.next(data);
    }
    getCookiePopup() {
        return this.cookiePopup;
    }
    setCookieConfigurePopup(data) {
        this.cookieConfigurePopup.next(data);
    }
    getCookieConfigurePopup() {
        return this.cookieConfigurePopup;
    }
    setConsentTwoChecked(data) {
        this.consentTwoChecked.next(data);
    }
    getConsentTwoChecked() {
        return this.consentTwoChecked;
    }
    setBannerConfig(data) {
        this.bannerConfig = data;
    }
    getBannerConfig() {
        return this.bannerConfig;
    }
}
HeaderService.ngInjectableDef = i0.defineInjectable({ factory: function HeaderService_Factory() { return new HeaderService(i0.inject(i1.ApiService)); }, token: HeaderService, providedIn: "root" });
