import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class PlaylistAddService {
    constructor() {
        this.popup = new BehaviorSubject('close');
        this.display = this.popup.asObservable();
    }
    // playlist popup
    displayPopup(data) {
        this.popup.next(data);
    }
}
PlaylistAddService.ngInjectableDef = i0.defineInjectable({ factory: function PlaylistAddService_Factory() { return new PlaylistAddService(); }, token: PlaylistAddService, providedIn: "root" });
