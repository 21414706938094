import { Component, OnInit, OnDestroy } from '@angular/core';
import disableScroll from 'disable-scroll';
import {
  AuthService,
  SocialUser,
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { AuthService as auth } from '../../core/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';
import { ApiService } from '../../core/services/api.service';
import { environment } from '../../../environments/environment';
import { UserDataService } from '../../core/services/user-data.service';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { HeaderService } from '../../core/services/header.service';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login-new.component.html',
  styleUrls: ['./social-login-new.component.scss']
})
export class SocialLoginComponent implements OnInit, OnDestroy {
  user: SocialUser;
  userApple: any;
  login = false;
  authVariable: any;
  socialAuthService: any;
  paymentData: any = {};
  auth = firebase.auth();
  constructor(
    private authService: AuthService,
    private authLogin: auth,
    private toasterService: ToastrService,
    private router: Router,
    private userService: UserService,
    private apiService: ApiService,
    private userDataService: UserDataService,
    private afAuth: AngularFireAuth,
    private headerService: HeaderService
  ) {}
  ngOnDestroy(): void {
    if (this.socialAuthService) {
      this.socialAuthService.unsubscribe();
    }
  }

  ngOnInit() {}

  signInWithGoogle(): void {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((response) => {
        this.socailLogin('google');
      })
      .catch((error) => {});
  }

  signInWithFB(): void {
    this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((response) => {
        this.socailLogin('facebook');
      })
      .catch((error) => {});
  }

  signInWIthApple() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.OAuthProvider('apple.com');
      provider.addScope('name');
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        resolve(res);
        this.socailLogin('apple', res.user.providerData[0]);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  signOut(): void {
    this.authService.signOut();
  }

  socailLogin(login_type, appleData = null): void {
    this.login = false;
    if (login_type === 'apple' && appleData !== null) {
      this.userApple = appleData;
      if (this.userApple) {
        if (this.userApple.uid !== undefined && !this.login) {
          this.apiService.callPostAPI(
            'v2/auth/check_email',
            {
              email: this.userApple.email
            },
            environment.userApiUrl
          )
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.userApple.displayName) {
                  this.userApple.name = this.userApple.displayName;
                } else {
                  this.userApple.name = this.userApple.email;
                }
                this.userDataService.setUserData({
                  email: this.userApple.email,
                  name: this.userApple.name,
                  surname: '',
                  passPage: true,
                  id: this.userApple.uid,
                  token: this.userApple.uid,
                  provider: this.userApple.providerId,
                  authToken: this.userApple.uid,
                  photoUrl: this.userApple.photoUrl
                });
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                this.router.navigate(['register']));
              }
            },
            error => {
              if (error.error.statusCode === 422) {
                if (this.userApple.displayName) {
                  this.userApple.name = this.userApple.displayName;
                } else {
                  this.userApple.name = this.userApple.email;
                }
                this.authVariable =  this.authLogin.login(this.userApple).subscribe(
                  (data) => {
                    if (data['statusCode'] === 200 && data['error'] === false) {
                      this.login = true;
                      this.userService.changeLogoutHeader(false);
                      this.userService.displayPopup('close');
                      if (
                        data['response'].name &&
                        !localStorage.getItem('currentUser')
                      ) {
                        const userData = this.authLogin.encryption(data);
                        localStorage.setItem('currentUser', userData);
                        this.asyncLocalStorage
                          .setItem('currentUser', userData)
                          .then(() => {
                            if (window.location.pathname.indexOf('video') !== -1) {
                              window.location.reload();
                            } else {
                              if (data['response'].is_subscribed === 0) {
                                this.paymentData.subscription_plan_id = 'silver-1';
                                this.paymentData.price = 0;
                                this.paymentData.payment_method_id = 1;
                                this.paymentData.payment_type = 'net-banking';
                                this.paymentData.card_number = '';
                                this.paymentData.name = '';
                                this.paymentData.month = '';
                                this.paymentData.year = '';
                                this.paymentData.cvv = '';
                                this.paymentData.coupon_code = null;
                                this.paymentData.is_save = false;
                                this.apiService.callPostAPI(
                                  'v2/subscription/add',
                                  this.paymentData,
                                  environment.paymentApiUrl
                                ).subscribe(
                                  (data: any) => {
                                    const userValues = localStorage.getItem('currentUser');
                                    if (userValues) {
                                      const userData = this.authLogin.decryption(userValues);
                                      userData['response'].is_subscribed = 1;
                                      const userNewData = this.authLogin.encryption(userData);
                                      localStorage.setItem('currentUser', userNewData);
                                    }
                                    localStorage.setItem('transaction_id', data['response'].transaction.transaction_id);
                                    this.headerService.setsubscriptionPlanId(data.response.transaction.subscription_plan_id);
                                    this.userService.showWelcomePopup(true);
                                  },
                                  error => {
                                  }
                                );
                              }
                              this.userService.nextPage.subscribe((nextUrl) => {
                                this.router.navigate([nextUrl]);
                              });
                            }
                          });
                        disableScroll.off();
                      }
                      this.authService.signOut();
                    }
                  },
                  (error) => {
                    if (this.userApple.email) {
                      this.toasterService.error(
                        '',
                        error.error.message ? error.error.message : error.message
                      );
                    } else {
                      this.toasterService.error(
                        '',
                        'Update your email id in social network'
                      );
                    }
                  }
                );
              }
            }
          );
        }
      }
    } else {
      this.socialAuthService = this.authService.authState.subscribe((user) => {
        this.user = JSON.parse(JSON.stringify(user));
        if (this.user) {
          if (this.user.id !== undefined && !this.login) {
            this.apiService.callPostAPI(
              'v2/auth/check_email',
              {
                email: user.email
              },
              environment.userApiUrl
            )
            .subscribe (
              data => {
                if (data['statusCode'] === 200 && data['error'] === false) {
                  this.userDataService.setUserData({
                    email: user.email,
                    name: user.firstName,
                    surname: user.lastName,
                    passPage: true,
                    id: user.id,
                    token: user.authToken,
                    provider: user.provider,
                    authToken: user.authToken,
                    photoUrl: user.photoUrl
                  });
                  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                  this.router.navigate(['register']));
                }
              },
              error => {
                if (error.error.statusCode === 422) {
                  this.authVariable =  this.authLogin.login(this.user).subscribe(
                    (data) => {
                      if (data['statusCode'] === 200 && data['error'] === false) {
                        this.login = true;
                        this.userService.changeLogoutHeader(false);
                        this.userService.displayPopup('close');
                        if (
                          data['response'].name &&
                          !localStorage.getItem('currentUser')
                        ) {
                          const userData = this.authLogin.encryption(data);
                          localStorage.setItem('currentUser', userData);
                          this.asyncLocalStorage
                            .setItem('currentUser', userData)
                            .then(() => {
                              if (window.location.pathname.indexOf('video') !== -1) {
                                window.location.reload();
                              } else {
                                if (data['response'].is_subscribed === 0) {
                                  this.paymentData.subscription_plan_id = 'silver-1';
                                  this.paymentData.price = 0;
                                  this.paymentData.payment_method_id = 1;
                                  this.paymentData.payment_type = 'net-banking';
                                  this.paymentData.card_number = '';
                                  this.paymentData.name = '';
                                  this.paymentData.month = '';
                                  this.paymentData.year = '';
                                  this.paymentData.cvv = '';
                                  this.paymentData.coupon_code = null;
                                  this.paymentData.is_save = false;
                                  this.apiService.callPostAPI(
                                    'v2/subscription/add',
                                    this.paymentData,
                                    environment.paymentApiUrl
                                  ).subscribe(
                                    data => {
                                      const userValues = localStorage.getItem('currentUser');
                                      if (userValues) {
                                        const userData = this.authLogin.decryption(userValues);
                                        userData['response'].is_subscribed = 1;
                                        const userNewData = this.authLogin.encryption(userData);
                                        localStorage.setItem('currentUser', userNewData);
                                      }
                                      localStorage.setItem('transaction_id', data['response'].transaction.transaction_id);
                                      this.userService.showWelcomePopup(true);
                                    },
                                    error => {
                                    }
                                  );
                                }
                                this.userService.nextPage.subscribe((nextUrl) => {
                                  this.router.navigate([nextUrl]);
                                });
                              }
                            });
                          disableScroll.off();
                        }
                        this.authService.signOut();
                      }
                    },
                    (error) => {
                      if (this.user.email) {
                        this.socialAuthService.unsubscribe();
                        this.toasterService.error(
                          '',
                          error.error.message ? error.error.message : error.message
                        );
                      } else {
                        this.toasterService.error(
                          '',
                          'Update your email id in social network'
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        }
      });
    }
  }
  // tslint:disable-next-line:member-ordering
  asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  };
}
