<footer id="footer" *ngIf="footerFlag">
  <div class="container">
    <div *ngIf="contact" class="footer-lnks-wrap">
      <ul class="socil-links">
        <li *ngIf="site_link?.fb_link">
          <a href="{{ site_link.fb_link }}" target="_blank">
            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2497 0H8.37467C7.10385 0 5.88507 0.526784 4.98645 1.46447C4.08784 2.40215 3.58301 3.67392 3.58301 5V8H0.708008V12H3.58301V20H7.41634V12H10.2913L11.2497 8H7.41634V5C7.41634 4.73478 7.51731 4.48043 7.69703 4.29289C7.87675 4.10536 8.12051 4 8.37467 4H11.2497V0Z" fill="#C9CBCD"/>
            </svg>     
          </a>     
        </li>
        <li *ngIf="site_link?.instagram_link">
          <a href="{{ site_link.instagram_link }}" target="_blank">
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.292 2H6.70866C4.06229 2 1.91699 4.23858 1.91699 7V17C1.91699 19.7614 4.06229 22 6.70866 22H16.292C18.9384 22 21.0837 19.7614 21.0837 17V7C21.0837 4.23858 18.9384 2 16.292 2Z" stroke="#D3D8DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.3332 11.37C15.4515 12.2022 15.3153 13.0522 14.9439 13.799C14.5725 14.5458 13.985 15.1514 13.2648 15.5297C12.5446 15.9079 11.7284 16.0396 10.9323 15.9059C10.1363 15.7723 9.4009 15.3801 8.83077 14.7852C8.26064 14.1902 7.88479 13.4229 7.7567 12.5922C7.6286 11.7615 7.75478 10.9099 8.11728 10.1584C8.47978 9.40685 9.06014 8.79374 9.77583 8.40624C10.4915 8.01874 11.3061 7.87658 12.1036 8C12.9172 8.12588 13.6704 8.52146 14.2519 9.1283C14.8335 9.73515 15.2126 10.5211 15.3332 11.37Z" stroke="#D3D8DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.7705 6.5H16.7805" stroke="#D3D8DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>     
          </a>     
        </li>
        <li *ngIf="site_link?.linkedin_link">
          <a href="{{ site_link.linkedin_link }}" target="_blank">
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.333 8C16.858 8 18.3205 8.63214 19.3989 9.75736C20.4772 10.8826 21.083 12.4087 21.083 14V21H17.2497V14C17.2497 13.4696 17.0477 12.9609 16.6883 12.5858C16.3289 12.2107 15.8413 12 15.333 12C14.8247 12 14.3372 12.2107 13.9777 12.5858C13.6183 12.9609 13.4163 13.4696 13.4163 14V21H9.58301V14C9.58301 12.4087 10.1888 10.8826 11.2671 9.75736C12.3455 8.63214 13.808 8 15.333 8Z" fill="#C9CBCD"/>
              <path d="M5.75033 9H1.91699V21H5.75033V9Z" fill="#C9CBCD"/>
              <path d="M3.83366 6C4.8922 6 5.75033 5.10457 5.75033 4C5.75033 2.89543 4.8922 2 3.83366 2C2.77511 2 1.91699 2.89543 1.91699 4C1.91699 5.10457 2.77511 6 3.83366 6Z" fill="#C9CBCD"/>
            </svg>   
          </a>       
        </li>
        <li *ngIf="site_link?.youtube_link">
          <a href="{{ site_link.youtube_link }}" target="_blank">
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.6011 6.42C21.4872 5.94541 21.2554 5.51057 20.9289 5.15941C20.6025 4.80824 20.193 4.55318 19.7419 4.42C18.0936 4 11.5002 4 11.5002 4C11.5002 4 4.90689 4 3.25856 4.46C2.80742 4.59318 2.39795 4.84824 2.07151 5.19941C1.74508 5.55057 1.51323 5.98541 1.39939 6.46C1.09772 8.20556 0.950157 9.97631 0.958556 11.75C0.947802 13.537 1.09537 15.3213 1.39939 17.08C1.52489 17.5398 1.76193 17.9581 2.08761 18.2945C2.41329 18.6308 2.81659 18.8738 3.25856 19C4.90689 19.46 11.5002 19.46 11.5002 19.46C11.5002 19.46 18.0936 19.46 19.7419 19C20.193 18.8668 20.6025 18.6118 20.9289 18.2606C21.2554 17.9094 21.4872 17.4746 21.6011 17C21.9004 15.2676 22.048 13.5103 22.0419 11.75C22.0526 9.96295 21.9051 8.1787 21.6011 6.42Z" fill="#C9CBCD"/>
              <path d="M9.34375 15.02L14.8542 11.75L9.34375 8.48001V15.02Z" fill="#080808"/>
            </svg>     
          </a>     
        </li>
        <li *ngIf="site_link?.twitter_link">
          <a href="{{ site_link.twitter_link }}" target="_blank">
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.0413 2.99998C21.1236 3.67546 20.1075 4.19209 19.0322 4.52999C18.455 3.8375 17.688 3.34668 16.8347 3.12391C15.9815 2.90115 15.0834 2.95718 14.2617 3.28444C13.44 3.6117 12.7344 4.19439 12.2404 4.9537C11.7465 5.71302 11.4879 6.61232 11.4997 7.52998V8.52998C9.81553 8.57555 8.14673 8.1858 6.64189 7.39543C5.13705 6.60506 3.84289 5.43862 2.87467 3.99998C2.87467 3.99998 -0.958658 13 7.66634 17C5.69268 18.398 3.34153 19.0989 0.958008 19C9.58301 24 20.1247 19 20.1247 7.49998C20.1238 7.22144 20.0981 6.94358 20.048 6.66999C21.0261 5.66348 21.7163 4.3927 22.0413 2.99998Z" fill="#C9CBCD"/>
            </svg>     
          </a>     
        </li>
        <li *ngIf="site_link?.twitch_link">
          <a href="{{ site_link.twitch_link }}" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M4.3 3H21v11.7l-4.7 4.7h-3.9l-2.5 2.4H7v-2.4H3V6.2L4.3 3zM5 17.4h4v2.4h.095l2.5-2.4h3.877L19 13.872V5H5v12.4zM15 8h2v4.7h-2V8zm0 0h2v4.7h-2V8zm-5 0h2v4.7h-2V8z" fill="#C9CBCD"/></svg>
          </a>
        </li>
        <li *ngIf="site_link?.tiktok_link">
          <a href="{{ site_link.tiktok_link }}" target="_blank">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.35108 0.0165217C10.4333 0 11.5072 0.00826087 12.5811 0C12.6472 1.26391 13.1015 2.55261 14.0267 3.44478C14.952 4.36174 16.2572 4.78304 17.5293 4.92348V8.25261C16.3398 8.2113 15.142 7.96348 14.0598 7.4513C13.5889 7.23652 13.1511 6.96391 12.7215 6.68304C12.7133 9.09522 12.7298 11.5074 12.705 13.9113C12.6389 15.0678 12.2589 16.2161 11.5898 17.1661C10.5076 18.7522 8.63239 19.7848 6.70761 19.8178C5.5263 19.8839 4.345 19.5617 3.33717 18.967C1.66848 17.9839 0.495432 16.183 0.321954 14.25C0.305432 13.837 0.297172 13.4239 0.313693 13.0191C0.462389 11.4496 1.23891 9.94609 2.445 8.92174C3.8163 7.73217 5.73282 7.16217 7.52543 7.50087C7.54195 8.72348 7.49239 9.94609 7.49239 11.1687C6.67456 10.9043 5.7163 10.9787 4.99761 11.4743C4.47717 11.813 4.08065 12.3335 3.87413 12.92C3.70065 13.3413 3.75021 13.8039 3.75848 14.25C3.95674 15.6048 5.26195 16.7448 6.64978 16.6209C7.575 16.6126 8.45891 16.0757 8.93804 15.2909C9.095 15.0183 9.26847 14.7374 9.27673 14.4152C9.35934 12.9365 9.3263 11.4661 9.33456 9.98739C9.34282 6.65826 9.3263 3.33739 9.35108 0.0165217Z" fill="#C9CBCD"/>
            </svg>               
          </a>     
        </li>
      </ul>
      <div class="f-footer">
        <ul class="footer-text custom-foot">
          <li *ngFor="let link of cmsLink">
            <a href="javascript:void(0)" routerLink="content/{{ link.slug }}">{{ link.title }}</a>
          </li>
          <li>
            <a *ngIf="contact" (click)="configureCookies()">Configure Cookies</a>
          </li>
        </ul>
        <div class="foot-new-right">
          <ul class="footer-text">
            <li *ngIf="contact?.maestros">
              <a href="{{ contact?.maestros }}">Maestros</a>
            </li>
            <li *ngIf="contact?.mentores">
              <a href="{{ contact?.mentores }}">Mentores</a>
            </li>
            <li *ngIf="contact?.academias">
              <a href="{{ contact?.academias }}">Academias</a>
            </li>
            <li *ngIf="contact?.coming_soon">
              <a href="{{ contact?.coming_soon }}">Próximamente</a>
            </li>
            <li *ngIf="contact?.envivo">
              <a href="{{ contact?.envivo }}">{{ 'FOOTER.LIVE' | translate }}</a>
            </li>
          </ul>
          <ul class="footer-text">
            <li *ngIf="contact?.collaborators">
              <a href="{{ contact?.collaborators }}">Colaboradores</a>
            </li>
            <li *ngIf="contact?.university_certificates">
              <a href="{{ contact?.university_certificates }}">Certificados Universitarios</a>
            </li>
            <li *ngIf="contact?.plans">
              <a href="{{ contact?.plans }}">Suscripciones / Precios</a>
            </li>
            <li class="email">
              <a *ngIf="contact?.email !== ''" [href]="'mailto:' + contact?.email" [ngStyle]="{ cursor: 'pointer' }">
                Contacto
              </a>
            </li>
          </ul>
          <ul *ngIf="contact" class="app-links">
            <li>
              <div class="social-icons">
                <a href="https://prelaunch.maestrosdeldeporte.com/app" target="_blank">
                  <img src="../../../../assets/images/logo/play.png" alt="">            
                </a>
                <a href="https://prelaunch.maestrosdeldeporte.com/app" target="_blank">
                  <img src="../../../../assets/images/logo/app.png" alt="">              
                </a>
              </div>
              <p>
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.4013 3.2967C5.30875 3.01343 5.14967 2.79365 4.92408 2.63736C4.69848 2.47619 4.41215 2.3956 4.06508 2.3956C3.79899 2.3956 3.5705 2.442 3.37961 2.5348C3.18872 2.62759 3.03254 2.75214 2.91106 2.90842C2.78959 3.05983 2.69993 3.23321 2.64208 3.42857C2.58424 3.61905 2.55531 3.81197 2.55531 4.00733C2.55531 4.20757 2.58134 4.40293 2.63341 4.59341C2.69125 4.78388 2.78091 4.95482 2.90239 5.10623C3.02386 5.25763 3.18004 5.37973 3.37093 5.47253C3.56182 5.56044 3.7932 5.6044 4.06508 5.6044C4.45264 5.6044 4.76211 5.51404 4.99349 5.33333C5.23066 5.15263 5.38106 4.90354 5.44469 4.58608H6C5.95372 4.79609 5.87853 4.98901 5.7744 5.16483C5.67607 5.33578 5.54591 5.48474 5.38395 5.61172C5.22777 5.73382 5.03977 5.82906 4.81996 5.89744C4.60014 5.96581 4.34852 6 4.06508 6C3.70644 6 3.39696 5.94872 3.13666 5.84615C2.87636 5.74359 2.66233 5.6044 2.49458 5.42857C2.32683 5.24786 2.20246 5.03541 2.12148 4.79121C2.04049 4.54701 2 4.28571 2 4.00733C2 3.74359 2.04338 3.49206 2.13015 3.25275C2.2227 3.00855 2.35575 2.79365 2.52928 2.60806C2.70282 2.42247 2.91685 2.27595 3.17137 2.1685C3.43167 2.05617 3.72957 2 4.06508 2C4.58568 2 5.00506 2.10501 5.32321 2.31502C5.64714 2.52503 5.8496 2.85226 5.93059 3.2967H5.4013Z" fill="#D3D8DE"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.5C5.933 7.5 7.5 5.933 7.5 4C7.5 2.067 5.933 0.5 4 0.5C2.067 0.5 0.5 2.067 0.5 4C0.5 5.933 2.067 7.5 4 7.5ZM4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z" fill="#D3D8DE"/>
                </svg>
                  Maestros del Deporte
              </p>
            </li>
          </ul>
        </div>
      </div> 
    </div>
  </div>
</footer>