<div class="registers">
  <div class="form">
    <form (ngSubmit)="Login()" #form="ngForm" autocomplete="nope" id="login-form">
      <h2>{{ 'SIGN_IN.LOG_IN' | translate }}<span>.</span></h2>
      <span class="error">
      {{ commonError }}
      </span>
      <div class="groups">
        <label>{{ 'SIGN_IN.EMAIL' | translate }}</label>
        <input name="email" type="email" [(ngModel)]="user.email" #email="ngModel" required
        pattern=".+@.+\..+" (focus)="onFocus()"/>
        <span class="error"
          *ngIf="!error?.email && email.invalid && email.touched && email.errors?.required">
          {{ 'SIGN_IN.EMAIL_REQUIRED_ERROR' | translate }}
        </span>
        <span class="error" *ngIf="!error?.email && email.errors?.pattern && email.touched">
          {{ 'SIGN_IN.EMAIL_VALID_ERROR' | translate }}
        </span>
        <span class="error" *ngIf="error?.email">
          {{error.email}}
        </span>
      </div>
      <div class="groups">
        <label>{{ 'SIGN_IN.PASSWORD' | translate }}</label>
        <input type="password" name="password" [(ngModel)]="user.password" id="input-password"
        #password="ngModel" required/>
        <span class="error"
          *ngIf="!error?.password && password.invalid && password.touched && password.errors?.required">
          {{ 'SIGN_IN.PASSWORD_REQUIRED_ERROR' | translate:{pwdMinLength: 6, pwdMaxlength: 60}
          }}
        </span>
        <span class="error"
          *ngIf="!error?.password && password.invalid && password.touched && (password.errors?.minlength || password.errors?.maxlength)">
          {{ 'SIGN_IN.PASSWORD_LENGTH_ERROR' | translate:{pwdMinLength: 6, pwdMaxlength: 60}
          }}
        </span>
        <span class="error" *ngIf="error?.password">
          {{error.password}}
        </span>
      </div>
        <button type="submit" [disabled]="submitted">{{ 'SIGN_IN.LOG_IN' | translate }}</button>
        <div class="grouping-select check">
          <div class="form-groups">
            <label class="container">
              <input name="termsChecked" type="checkbox" >
              <span class="checkmark"></span>
            </label>  
            <p>{{ 'SIGN_IN.REMEMBER' | translate }} <a routerLink="/forgot-password">{{ 'SIGN_IN.FORGET_PASSWORD' | translate }}</a></p>
          </div>
        </div>
    </form>
    <p class="or">&nbsp;</p>
    <h4>{{ 'SIGN_IN.LOGIN_WITH' | translate }}</h4>
    <app-social-login></app-social-login>
    <h3>{{ 'SIGN_IN.NO_ACCOUNT' | translate }}    <a routerLink="/register">{{ 'SIGN_IN.CREATE_ACCOUNT' | translate }}</a></h3>
  </div>
</div>

<!-- Verification mail popup -->
<div class="welcome-ovelay no-connetion" [class.active]='verifyMailPopup'>
  <div class="welcome-popup-wrap">
    <img class="pop-bg" src="../assets/images/logo/verifation-icn.svg" alt="">
    <div class="welcome-popup-content">
      <h4>{{ 'EMAIL_VERIFICATION.ALMOST_THERE' | translate }}</h4>
      <h2>{{ 'EMAIL_VERIFICATION.CHECK_EMAIL' | translate }}<span>.</span></h2>
      <p>{{ 'EMAIL_VERIFICATION.LINK_SENT_1' | translate }} <span>{{ 'EMAIL_VERIFICATION.LINK_SENT_2' | translate }} </span><span> {{ 'EMAIL_VERIFICATION.ACTIVATE' | translate }}</span></p>
      <div class="popu-btns-wrap">
        <a href="https://www.gmail.com" target="_blank" class="pop-new-btn">{{ 'EMAIL_VERIFICATION.OPEN_MAIL' | translate }}</a>
        <a (click)="resendEmail()" class="pop-new-btn transp">{{ 'EMAIL_VERIFICATION.SEND_AGAIN' | translate }}</a>
      </div>
    </div>
    <svg (click)="closePopup()" class="close" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6773 1.36396L7.72754 6.31371L2.77779 1.36396C2.42424 1.01041 2.07068 0.656854 1.36358 1.36396C0.656471 2.07107 1.01002 2.42462 1.36358 2.77817L6.31333 7.72792L1.36358 12.6777C1.01002 13.0312 0.797893 13.5262 1.36358 14.0919C2.07068 14.799 2.42424 14.4454 2.77779 14.0919L7.72754 9.14214L12.6773 14.0919C13.0308 14.4454 13.3844 14.799 14.0915 14.0919C14.7986 13.3848 14.4451 13.0312 14.0915 12.6777L9.14175 7.72792L14.0915 2.77817C14.4451 2.42462 14.7986 2.07107 14.0915 1.36396C13.3844 0.656854 13.0308 1.01041 12.6773 1.36396Z" fill="white"/>
    </svg>
  </div>
</div>