import { Component, OnInit, Input } from '@angular/core';
import videoJs from '../../../assets/js/play.es.js';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/services/api.service';
import { TranslateService } from '@ngx-translate/core';

/* tslint:disable:component-selector */
@Component({
  selector: '[slide]',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {
  @Input() slide: any;
  @Input() place: string;
  @Input() most_viewed: boolean;
  @Input() chapterNumber: number;
  @Input() course: any;
  slideType: any = '';
  chapterOrder: any = '';
  player: any;
  playerVideo: any;
  decrypted: any;
  encrypted: any;
  plainText: any;
  cipherText: any;
  trailerHoverTimer;
  timeouts = [];
  videoPlayHoverState = true;
  isTrailerHovered = false;
  isTrailerMuted = true;
  instructorFirstName = '';
  instructorLastName = '';
  subTitle = '';
  slideTitle = '';
  thumbnailImage = '';
  thumbnailInnerImage = '';
  is_subscribed: any = 0;
  isLogged = false;
  subscriptionPlanId: number;

  constructor(
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService,
    private translate: TranslateService
  ) {
    setInterval(() => {
      if (localStorage.getItem('currentUser')) {
        this.isLogged = true;
        const userValues = localStorage.getItem('currentUser');
        const userData = this.authService.decryption(userValues);
        this.is_subscribed = userData['response'].is_subscribed;
        this.subscriptionPlanId = userData['response'].subscription_plan_id;
        if (this.subscriptionPlanId === undefined) {
          this.subscriptionPlanId = userData['response'].subscribed_plan.pivot.subscription_plan_id;
        }
      }
    }, 100);
  }

  ngOnInit() {
    if (this.slide.instructor_type_id !== undefined) {
      if (this.slide.instructor_type_id === 1) {
        this.slideType = (this.slide.genre_subtitle) ? this.slide.genre_subtitle : this.slide.sport;
        if (this.slide.season_name !== undefined) {
          this.slideType = this.slide.season_name;
        }
      } else if (this.slide.instructor_type_id === 2 || this.slide.instructor_type_id === 3) {
        this.slideType = (this.slide.genre_subtitle) ? this.slide.genre_subtitle : this.slide.genre;
        if (this.slide.season_name !== undefined) {
          this.slideType = this.slide.season_name;
        }
      }
      if (this.slide.sub_title !== undefined) {
        this.subTitle = this.slide.sub_title;
      }
      if (this.slide.type_id === 3) {
        if (this.slide.season_name === 'Preguntas de alumnos') {
          this.chapterOrder = 'Preguntas de alumnos';
        } else {
          this.translate.get('MAESTROS.CHAPTER').subscribe(data => this.chapterOrder = data);
          this.chapterOrder = this.chapterOrder + ' ' + this.slide.episode_order;
        }
      }
      if (this.slide.title !== undefined) {
        this.slideTitle = this.slide.title;
      }
      if (this.slide.instructor_first_name !== undefined) {
        this.instructorFirstName = this.slide.instructor_first_name;
      }
      if (this.slide.instructor_last_name !== undefined) {
        this.instructorLastName = this.slide.instructor_last_name;
      }
      this.thumbnailImage = this.slide.thumbnail_image;
      if (this.slide.thumbnail_image === null) {
        this.thumbnailImage = '../../../assets/images/no-thumbnail.png';
      }
    } else {
      this.slideType = this.slide.sport;
      this.slideTitle = this.slide.description;
      this.instructorFirstName = this.slide.title;
      this.thumbnailImage = this.slide.poster_image;
      this.thumbnailInnerImage = this.slide.thumbnail_image;
    }
  }

  instructorType(slide: any) {
    let style = '';
    if (slide.instructor_type_id !== undefined) {
      switch (slide.instructor_type_id) {
        case 1: style = style + ' oro-active'; break;
        case 2: style = style + ' plata-active'; break;
        case 3: {
          style = style + ' bronce-active';
          if (slide.is_pill) {
            style = style + ' active-pild';
          }
        }break;
      }
    }
    if (slide.trailer_id !== undefined) {
      style = style + ' bronce-active active-pild';
    }
    if (slide.season_name === undefined) {
      style = style + ' without-progress';
    }
    return style;
  }

  playTeaserNew(slide: any) {
    if (this.isTrailerHovered) {
      return;
    }
    let videoSelector = 'trailer-video-' + slide.slug;
    if (this.place) {
      videoSelector = 'trailer-video-' + this.place + '-' + slide.slug;
    }
    const video = document.getElementById(videoSelector);
    this.isTrailerHovered = true;
    const elems = document.querySelectorAll('.common-slider');
    elems.forEach((elem) => {
      elem.classList.add('move-back');
    });
    if (video !== null) {
      if (slide.trailer.hls_playlist_url !== null && slide.trailer.hls_playlist_url !== '' && slide.trailer.transcode_status === 'Complete') {
        const url = 'v2/watchvideo/' + slide.trailer.slug;
        this.apiService.callGetAPI(url).subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              this.playerVideo = data['response'].videos;
              this.decrypt(video);
            }
          },
          (error: any) => {
          }
        );
      }
    }
  }

  hexToBinary(num) {
    const hex = num,
      bytes = [];
    for (let i = 0; i < hex.length - 1; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return String.fromCharCode.apply(String, bytes);
  }
  d2h(d) {
    return d.toString(16);
  }
  binaryToHex(num) {
    let str = '',
      i = 0;
    const tmp_len = num.length;
    let c;
    for (; i < tmp_len; i += 1) {
      c = num.charCodeAt(i);
      str += this.d2h(c);
    }
    return str;
  }
  decrypt(video) {
    if (typeof this.playerVideo.passphrase !== 'undefined') {
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(this.playerVideo.passphrase)
      });
      this.decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        CryptoJS.enc.Hex.parse(environment.crypto_key),
        {
          keySize: 256,
          iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
          mode: CryptoJS.mode.CBC
        }
      );
      const change = this.decrypted.toString(CryptoJS.enc.Utf8);
      this.plainText = parseInt(this.hexToBinary(change), 10);
      this.setTeaserOneConfig(video);
    }
  }
  encrypt() {
    const utf8 = CryptoJS.enc.Utf8.parse('vplayed/' + this.plainText);
    const hex_utf = utf8.toString(CryptoJS.enc.Utf8);
    const hex = this.binaryToHex(hex_utf);
    this.encrypted = CryptoJS.AES.encrypt(
      hex,
      CryptoJS.enc.Hex.parse(environment.crypto_key),
      {
        keySize: 256,
        iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
        mode: CryptoJS.mode.CBC
      }
    );
    this.cipherText = this.encrypted.ciphertext.toString(CryptoJS.enc.utf8);
    return this.cipherText;
  }
  setTeaserOneConfig(video) {
    videoJs.Hls.xhr.beforeRequest = (options) => {
      options.headers = [];
      if (this.playerVideo.is_live === 0) {
        options.headers['Title'] = this.encrypt();
      }
    };
    this.player = videoJs(video, {
      muted: this.isTrailerMuted === true ? true : false,
      controls: false,
      loop: true,
      bigPlayButton: false,
      preload: 'auto',
      fluid: true,
      autoplay: false,
      progressControl: {
        seekBar: false
      },
      html5: {
        hls: {
          customDrm: {
            keys: 'MjMyOGMwYWM4MmNhM2ZjNmY4ZDcyNzI0NWQzMDgzYmUxMzU3ZjA0NGZmNWNiNGMzYTExNDJiMDkzY2YyZjNjOTAwNTM0MjE4NTUxMmM5ODlhMWYwMzI3YjQwYmY3YmY1YjE2MDBkMTYzYTA1ZTFkYzE4OWJhMjUwOGM1MTIwODksNDg3MGVjMDY0YzEwODE0MmNjMGZmYmQ2ZmZkZjFlMDhlNzU2YTZkZWYyYzc4MDQ2ODlmZTRhZTBmYzk4NTIyMSxiMmJmNWE5NzAwNGJiMzFkNmY1YTljMmM4NDQ0OTc1OQ'
          }
        }
      }
    });
    this.player.src({
      src: this.playerVideo.hls_playlist_url,
      type: 'application/x-mpegURL'
    });
    this.player.play();
  }

  resetTeaserNew(slide: any) {
    const videoSelector = 'trailer-video-' + slide.slug;
    const video = document.getElementById(videoSelector);
    this.isTrailerHovered = false;
    const elems = document.querySelectorAll('.common-slider');
    elems.forEach((elem) => {
      elem.classList.remove('move-back');
    });
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i]);
    }
    this.timeouts = [];
    if (slide.trailer.hls_playlist_url !== null && slide.trailer.hls_playlist_url !== '' && slide.trailer.transcode_status === 'Complete') {
      this.videoPlayHoverState = true;
      this.player.reset();
    }
  }


  // playTeaser(slide: any) {
  //   const videoSelector = 'trailer-video-' + slide.slug;
  //   const video = document.getElementById(videoSelector);
  //   this.isTrailerHovered = true;
  //   const elems = document.querySelectorAll('.common-slider');
  //   elems.forEach((elem) => {
  //     elem.classList.add('move-back');
  //   });
  //   if (video != null) {
  //     if (slide.trailer_hls_url !== null && slide.trailer_hls_url !== '' && slide.trailer_status === 'Complete') {
  //       this.playerConfiguration(video);
  //       this.trailerHoverTimer = setTimeout(function () {
  //         this.player.src({
  //           src: slide.trailer_hls_url,
  //           type: 'application/x-mpegURL'
  //         });
  //         this.player.load();
  //         this.player.on('play', function (e) {
  //           this.videoPlayHoverState = false;
  //         }.bind(this));
  //         this.player.on('ended', function (e) {
  //           this.videoPlayHoverState = true;
  //           this.player.pause();
  //         }.bind(this));
  //       }.bind(this), 1000, video, slide);
  //       this.timeouts.push(this.trailerHoverTimer);
  //     }
  //   }
  // }

  // playerConfiguration(videoElem) {
  //   this.player = videoJs(videoElem, {
  //     autoplay: true,
  //     controls: false,
  //     preload: true,
  //     bigPlayButton: false,
  //     muted: this.isTrailerMuted === true ? true : false
  //   });
  // }

  // resetTeaser(slide: any) {
  //   const videoSelector = 'trailer-video-' + slide.slug;
  //   const video = document.getElementById(videoSelector);
  //   this.isTrailerHovered = false;
  //   const elems = document.querySelectorAll('.common-slider');
  //   elems.forEach((elem) => {
  //     elem.classList.remove('move-back');
  //   });
  //   for (let i = 0; i < this.timeouts.length; i++) {
  //     clearTimeout(this.timeouts[i]);
  //   }
  //   this.timeouts = [];
  //   if (slide.trailer_hls_url !== null && slide.trailer_hls_url !== '' && slide.trailer_status === 'Complete') {
  //     this.playerConfiguration(video);
  //     this.videoPlayHoverState = true;
  //     this.player.reset();
  //   }
  // }

  soundTrailer() {
    if (this.isTrailerMuted) {
      this.player.muted(true);
    } else {
      this.player.muted(false);
    }
  }

  onCourseClick() {
    if (this.slide.video_duration !== undefined) {
      if (this.slide.is_free && this.subscriptionPlanId === 22) {
        const url = 'watch/' + this.slide.slug;
        this.router.navigate([url]);
      }
      if (this.course.is_premium === 1 || this.course.price > 0) {
        if ((this.course.is_premium === 1 && this.subscriptionPlanId === 22) &&
        (this.course.price > 0 && this.course.payment_info.is_bought === 0)) {
          return false;
        } else if (this.course.is_premium === 1 && (this.subscriptionPlanId === 2 || this.subscriptionPlanId === 10) ||
        (this.course.price > 0 && this.course.payment_info.is_bought === 1)) {
          const url = 'watch/' + this.slide.slug;
          this.router.navigate([url]);
        } else {
          return false;
        }
      } else {
        const url = 'watch/' + this.slide.slug;
        this.router.navigate([url]);
      }
    } else if (this.slide.instructor_type_id !== undefined) {
      const url = 'webseries/' + this.slide.slug;
      this.router.navigate([url]);
    } else {
      const url = 'academias-detail/' + this.slide.slug;
      this.router.navigate([url]);
    }
  }

  isChapterAllowed(slide) {
    if ('is_free' in slide) {
      if (slide.is_free && this.subscriptionPlanId === 22) {
        return true;
      }
      if (this.course.is_premium === 1 || this.course.price > 0) {
        if ((this.course.is_premium === 1 && this.subscriptionPlanId === 22) &&
        (this.course.price > 0 && this.course.payment_info.is_bought === 0)) {
          return false;
        } else if (this.course.is_premium === 1 && (this.subscriptionPlanId === 2 || this.subscriptionPlanId === 10) ||
        (this.course.price > 0 && this.course.payment_info.is_bought === 1)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

}
