<!-- <div class="loader-ring" *ngIf="!videos">
  <div class="loader-ring-light"></div>
  <div class="loader-ring-track"></div>
</div> -->

<div class="player-skel" *ngIf="!videos">
  <div class="player-left-skel">
      <div class="player-skel-bg skeleton-custm"></div>
  </div>
  <div class="player-right-skel">
      <div class="play-pro-skel">
        <div class="ply-pro-icn skeleton-custm"></div>
        <div class="play-pro-name">
          <h2 class="play-pro-skel-name skeleton-custm"></h2>
          <h2 class="play-pro-skel-desc skeleton-custm"></h2>
        </div>
      </div>
      <h4 class="chap-titl-skel skeleton-custm"></h4>
      <h6 class="chap-count-skel skeleton-custm"></h6>
      <div class="line-skel skeleton-custm"></div>
      <h3 class="acc-title-skel skeleton-custm"></h3>
      <div class="acc-thum-wrap">
        <div class="acc-thum-skel skeleton-custm"></div>
        <h6 class="acc-thum-title skeleton-custm"></h6>
      </div>
      <div class="acc-thum-wrap">
        <div class="acc-thum-skel skeleton-custm"></div>
        <h6 class="acc-thum-title skeleton-custm"></h6>
      </div>
      <div class="acc-thum-wrap">
        <div class="acc-thum-skel skeleton-custm"></div>
        <h6 class="acc-thum-title skeleton-custm"></h6>
      </div>
  </div>
</div>

<app-player *ngIf="videos" class="video-player-center title-grd" (livchatStatus)="toggleLiveChat($event)"
  [ngClass]="{ 'player-live-chat': isMobile || liveChatStatus }">
</app-player>


<div class="revoke-popup-overlay active" *ngIf="videoTitle">
  <div class="revoke-popup">
    <h2 class="revoke-title">
      {{ 'VIDEO_DETAIL.STREAMING_ERROR' | translate }}
      <svg (click)="goHome()" width="512" height="512" x="0" y="0" viewBox="0 0 357 357"
        style="enable-background: new 0 0 512 512">
        <g>
          <g xmlns="http://www.w3.org/2000/svg">
            <g id="close">
              <polygon
                points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3     214.2,178.5   "
                fill="#3b3a36" data-original="#000000" />
            </g>
          </g>
        </g>
      </svg>
    </h2>
    <div class="revoke-content">
      <p>
        {{ 'VIDEO_DETAIL.STREAMING_ERROR_DESC' | translate }}
      </p>
    </div>
  </div>
</div>