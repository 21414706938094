/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/router";
import * as i5 from "./banner.component";
import * as i6 from "../../../core/services/api.service";
import * as i7 from "../../../core/auth/auth.service";
import * as i8 from "../../../core/services/header.service";
var styles_BannerComponent = [i0.styles];
var RenderType_BannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BannerComponent, data: {} });
export { RenderType_BannerComponent as RenderType_BannerComponent };
function View_BannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "main-banner-caption"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 21, "div", [["class", "banner-cap-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ", ", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 3, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [["routerLink", "/plans"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(16, null, ["", " \u20AC"])), (_l()(), i1.ɵted(17, null, [" ", ""])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "a", [["class", "common-bt"], ["routerLink", "/register"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(20, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(22, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, ["*", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "main-banner-caption"; var currVal_1 = ((_co.currentUserName != undefined) ? "active" : ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_7 = "/plans"; _ck(_v, 15, 0, currVal_7); var currVal_12 = "/register"; _ck(_v, 19, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("BANNER.GREETING")); var currVal_3 = _co.currentUserName; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.bannerConfiguration.banner_title; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.bannerConfiguration.banner_info_1; _ck(_v, 11, 0, currVal_5); var currVal_6 = _co.bannerConfiguration.banner_info_2_p1; _ck(_v, 13, 0, currVal_6); var currVal_8 = _co.bannerConfiguration.banner_info_plan; _ck(_v, 16, 0, currVal_8); var currVal_9 = _co.bannerConfiguration.banner_info_2_p2; _ck(_v, 17, 0, currVal_9); var currVal_10 = i1.ɵnov(_v, 19).target; var currVal_11 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_10, currVal_11); var currVal_13 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("BANNER.JOIN_FREE")); _ck(_v, 20, 0, currVal_13); var currVal_14 = _co.bannerConfiguration.register_button_subtitle; _ck(_v, 23, 0, currVal_14); }); }
export function View_BannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", "main-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "video", [["id", "banner_video"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannerComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [["class", "scroll-dwn-icn"], ["fill", "none"], ["height", "9"], ["viewBox", "0 0 68 9"], ["width", "68"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["d", "M66.8823 1L33.9412 8L1 1"], ["stroke", "white"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "volume-wrp"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "volume-mute"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.isBannerMuted = !_co.isBannerMuted;
        var pd_0 = (_co.soundBanner() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "volume-unmute"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.isBannerMuted = !_co.isBannerMuted;
        var pd_0 = (_co.soundBanner() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bannerConfiguration; _ck(_v, 3, 0, currVal_0); var currVal_1 = "volume-wrp"; var currVal_2 = ((_co.isBannerMuted === false) ? "active" : ""); _ck(_v, 7, 0, currVal_1, currVal_2); }, null); }
export function View_BannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banner", [], null, null, null, View_BannerComponent_0, RenderType_BannerComponent)), i1.ɵdid(1, 114688, null, 0, i5.BannerComponent, [i6.ApiService, i7.AuthService, i8.HeaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BannerComponentNgFactory = i1.ɵccf("app-banner", i5.BannerComponent, View_BannerComponent_Host_0, { bannerTeasers: "bannerTeasers" }, {}, []);
export { BannerComponentNgFactory as BannerComponentNgFactory };
