<div class="contus-slider-outer">
    <div class="contus-slider" [id]="id">
        <ng-content></ng-content>
    </div>
</div>
<div class="owl-nav">
    <button *ngIf="previousBtn" (click)="previous()" type="button" role="presentation" class="owl-prev">
      <span aria-label="Previous">‹</span>
    </button>
    <button *ngIf="nextBtn" (click)="next()" type="button" role="presentation" class="owl-next">
      <span aria-label="Next">›</span>
    </button>
</div>