import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../../core/services/user.service';
import { UtilityService } from '../../core/services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../core/services/api.service';
import { environment } from '../../../environments/environment';
import { HeaderService } from '../../core/services/header.service';
import { UserDataService } from '../../core/services/user-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  submitted = false;
  LoggedIn = localStorage.getItem('currentUser');
  user: any = {};
  error: any = {};
  commonError: string;
  paymentData: any = {};
  verifyMailPopup: boolean;
  userData: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private toasterService: ToastrService,
    private utilityService: UtilityService,
    private apiService: ApiService,
    private headerService: HeaderService,
    private userDataService: UserDataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.LoggedIn) {
      this.router.navigate(['home']);
    }
    this.userService.verifyPopup.subscribe(
      data => this.verifyMailPopup = data
    );
    this.userDataService.userData.subscribe(
      data => this.userData = data
    );
    window.scroll({ top: 0, left: 0 });
  }

  Login() {
    this.submitted = true;
    this.user.provider = 'NORMAL';
    this.authService.login(this.user)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.userService.changeLogoutHeader(false);
          if (data['response'].name) {
            const userData = this.authService.encryption(data);
            localStorage.setItem('currentUser', userData);
          }
          if (data['response'].is_subscribed === 0) {
            this.paymentData.subscription_plan_id = 'silver-1';
            this.paymentData.price = 0;
            this.paymentData.payment_method_id = 1;
            this.paymentData.payment_type = 'net-banking';
            this.paymentData.card_number = '';
            this.paymentData.name = '';
            this.paymentData.month = '';
            this.paymentData.year = '';
            this.paymentData.cvv = '';
            this.paymentData.coupon_code = null;
            this.paymentData.is_save = false;
            this.apiService.callPostAPI(
              'v2/subscription/add',
              this.paymentData,
              environment.paymentApiUrl
            ).subscribe(
              (data: any) => {
                const userValues = localStorage.getItem('currentUser');
                if (userValues) {
                  const userData = this.authService.decryption(userValues);
                  userData['response'].is_subscribed = 1;
                  userData['response'].subscription_plan_id = data.response.transaction.subscription_plan_id;
                  const userNewData = this.authService.encryption(userData);
                  localStorage.setItem('currentUser', userNewData);
                }
                localStorage.setItem('transaction_id', data['response'].transaction.transaction_id);
                this.router.navigate(['home']);
                this.userService.showWelcomePopup(true);
              },
              error => {
              }
            );
          } else if (data['response'].is_subscribed === 1) {
            data['response'].subscription_plan_id = data['response'].subscribed_plan.pivot.subscription_plan_id;
            this.headerService.setsubscriptionPlanId(data['response'].subscribed_plan.pivot.subscription_plan_id);
          }
          this.userService.nextPage.subscribe(
            nextUrl => {
              if (!localStorage.getItem('myUrl')) {
                if (nextUrl.indexOf('video') !== -1) {
                  window.location.reload();
                } else {
                  this.router.navigate([nextUrl]);
                }
              } else if (localStorage.getItem('myUrl')) {
                if (data['response'].is_subscribed === 0) {
                  localStorage.setItem('subscribeUrl', window.location.pathname);
                  this.router.navigate(['subscription']);
                  localStorage.removeItem('myUrl');
                } else if (data['response'].is_subscribed === 1) {
                  this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                  localStorage.removeItem('myUrl');
                }
              }
            }
          );
        }
      },
      error => {
        this.submitted = false;
        if (error.error.statusCode === 422) {
          if (typeof error.error.message === 'string' || error.error.message instanceof String) {
            this.commonError = (error.error.message) ? error.error.message : error.message;
            this.utilityService.VplyToaster();
          } else {
            this.error = error.error.message;
          }
        } else {
          this.error = {};
          this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
        }
      }
    );
  }

  onFocus() {
    this.error = {};
  }

  closePopup() {
    this.userService.showVerifyMailPopup(false);
    this.verifyMailPopup = false;
  }

  resendEmail() {
    this.apiService.callPostAPI('v2/auth/resend_email',
    {
      email: this.userData.email,
    }, environment.userApiUrl)
    .subscribe(
      data => {
        this.translate.get('EMAIL_VERIFICATION.RESEND_SUCCESS').subscribe((res) => {
          this.toasterService.success('', res);
        });
      }
    );
  }

}
