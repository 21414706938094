import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpClientModule
} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as cryptoJS from 'crypto-js';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import Fingerprint2 from '@fingerprintjs/fingerprintjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  deviceId: string;
  operatingSystem: string;
  browserName: string;
  apiHeaders: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private toasterService: ToastrService,
    private userService: UserService,
    private translate: TranslateService
  ) {
    this.getUniqueBrowserValue().then(
      (data: { deviceId: string; platform: string }) => {
        this.deviceId = data.deviceId;
        this.operatingSystem = data.platform;
      }
    );

    this.browserName = this.getBrowserName();
  }

  login(data: any) {
    this.apiHeaders = new HttpHeaders();
    this.apiHeaders.headers['Accept'] = 'application/json';
    this.apiHeaders.headers['X-REQUEST-TYPE'] = 'web';
    this.apiHeaders.headers['X-DEVICE-NAME'] = this.browserName;
    this.apiHeaders.headers['X-DEVICE-OS'] = this.operatingSystem
      ? this.operatingSystem
      : '';
    this.apiHeaders.headers['X-DEVICE-ID'] = this.deviceId ? this.deviceId : '';
    this.apiHeaders.headers['X-LANGUAGE-CODE'] = localStorage.getItem(
      'currentLanguage'
    )
      ? localStorage.getItem('currentLanguage')
      : 'es';

    const provider =
      data.provider === 'NORMAL'
        ? 'normal'
        : data.provider === 'FACEBOOK'
        ? 'fb'
        : data.providerId === 'apple.com'
        ? 'apple'
        : data.provider === undefined
        ? 'normal'
        : 'google+';

        let authToken;
        let socialUserId;
        if (data.provider) {
          authToken = data.provider === 'NORMAL' ? '' : data.authToken;
          socialUserId = data.provider === 'NORMAL' ? '' : data.id;
        } else if (data.providerId === 'apple.com') {
          authToken = data.uid;
          socialUserId = data.uid;
        } else if (data.provider === undefined) {
          authToken = data.provider === 'NORMAL' || data.provider === undefined ? '' : data.authToken;
          socialUserId = data.provider === 'NORMAL' || data.provider === undefined ? '' : data.authToken;
        }
    return this.http
      .post<any>(
        environment.userApiUrl + 'v2/auth/login',
        {
          login_type: provider,
          acesstype: 'web',
          email: data.email,
          password: data.password ? data.password : '',
          social_user_id: socialUserId,
          token: authToken,
          name: data.name ? data.name : '',
          profile_picture: data.photoUrl ? data.photoUrl : ''
        },
        this.apiHeaders
      )
      .pipe(
        map((user: any) => {
          if (user['statusCode'] === 200 && user['error'] === false) {
            if (data.provider !== 'NORMAL') {
              user.response.name = data.name;
            }
            const userData = this.encryption(user);
            this.userService.userData(userData);
          }
          return user;
        })
      );
  }

  logout(showToaster = true) {
    // console.log('logout service');
    const authorization: any = this.decryption();
    this.apiHeaders = new HttpHeaders();
    this.apiHeaders.headers['Accept'] = 'application/json';
    this.apiHeaders.headers['X-REQUEST-TYPE'] = 'web';
    this.apiHeaders.headers['X-DEVICE-NAME'] = this.browserName;
    this.apiHeaders.headers['X-DEVICE-OS'] = this.operatingSystem
      ? this.operatingSystem
      : '';
    this.apiHeaders.headers['X-DEVICE-ID'] = this.deviceId ? this.deviceId : '';

    if (authorization.hasOwnProperty('response')) {
      this.apiHeaders.headers['Authorization'] =
        'Bearer ' + authorization.response.access_token;
    }
    return this.http
      .post<any>(environment.userApiUrl + 'v2/auth/logout', {}, this.apiHeaders)
      .subscribe(() => {
        this.logoutCallBack();
      }, (error) => {
        this.logoutCallBack();
        });
  }
  logoutCallBack() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      this.userService.userData('');
      this.router.navigate(['/']);
      this.userService.changeLogoutHeader(true);
      let getMessage;
      this.translate.get('LOGOUT.LOGOUT_MESSAGE').subscribe((res) => {
        getMessage = res;
      });
      this.toasterService.success('', getMessage);
      if (this.apiHeaders.headers.hasOwnProperty('Authorization')) {
        delete this.apiHeaders.headers['Authorization'];
      }

    }
  encryption(user) {
    return cryptoJS.AES.encrypt(
      JSON.stringify(user),
      environment.encryption_key
    );
  }

  decryption(data = null) {
    const currentUser =
      data === null ? localStorage.getItem('currentUser') : data;
    let decryptUser = {};
    if (currentUser) {
      decryptUser = cryptoJS.AES.decrypt(
        currentUser.toString(),
        environment.encryption_key
      );
      decryptUser = JSON.parse(
        (decryptUser as Buffer).toString(cryptoJS.enc.Utf8)
      );
    }
    return decryptUser;
  }

  getUniqueBrowserValue() {
    return new Promise((res) => {
      if (localStorage.getItem('device-id')) {
        res({
          deviceId: localStorage.getItem('device-id'),
          platform: localStorage.getItem('platform')
        });
      }

      Fingerprint2.get(function (components) {
        const values = components.map(function (component) {
          return component.value;
        });
        const deviceId = Fingerprint2.x64hash128(values.join(''), 31);
        const platform = components[16].value;

        localStorage.setItem('device-id', deviceId);
        localStorage.setItem('platform', components[16].value);

        res({ deviceId, platform });
      });
    });
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1 || agent.indexOf('Edg') > -1 || agent.indexOf('edg') > -1:
        return 'Edge';
      case agent.indexOf('opr') > -1 && !!(window as any).opr:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return 'Chrome';
      case agent.indexOf('trident') > -1:
        return 'Internet Explore';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'Other';
    }
  }
}
