/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./register.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../register-email/register-email.component.ngfactory";
import * as i3 from "../register-email/register-email.component";
import * as i4 from "../../core/services/api.service";
import * as i5 from "ngx-toastr";
import * as i6 from "../../core/services/user-data.service";
import * as i7 from "../register-pass/register-pass.component.ngfactory";
import * as i8 from "../register-pass/register-pass.component";
import * as i9 from "../register-data/register-data.component.ngfactory";
import * as i10 from "../register-data/register-data.component";
import * as i11 from "../../core/auth/auth.service";
import * as i12 from "angularx-social-login";
import * as i13 from "../../core/services/user.service";
import * as i14 from "@angular/router";
import * as i15 from "../../core/services/header.service";
import * as i16 from "@angular/common";
import * as i17 from "./register.component";
var styles_RegisterComponent = [i0.styles];
var RenderType_RegisterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegisterComponent, data: {} });
export { RenderType_RegisterComponent as RenderType_RegisterComponent };
function View_RegisterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-register-email", [], null, [[null, "showPassPageEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showPassPageEvent" === en)) {
        var pd_0 = (_co.showPassPage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RegisterEmailComponent_0, i2.RenderType_RegisterEmailComponent)), i1.ɵdid(1, 114688, null, 0, i3.RegisterEmailComponent, [i4.ApiService, i5.ToastrService, i6.UserDataService], null, { showPassPageEvent: "showPassPageEvent" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_RegisterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-register-pass", [], null, [[null, "showDataPageEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showDataPageEvent" === en)) {
        var pd_0 = (_co.showDataPage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_RegisterPassComponent_0, i7.RenderType_RegisterPassComponent)), i1.ɵdid(1, 114688, null, 0, i8.RegisterPassComponent, [i6.UserDataService, i4.ApiService, i5.ToastrService], null, { showDataPageEvent: "showDataPageEvent" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_RegisterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-register-data", [], null, null, null, i9.View_RegisterDataComponent_0, i9.RenderType_RegisterDataComponent)), i1.ɵdid(1, 114688, null, 0, i10.RegisterDataComponent, [i11.AuthService, i12.AuthService, i13.UserService, i6.UserDataService, i4.ApiService, i5.ToastrService, i14.Router, i15.HeaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_RegisterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterComponent_1)), i1.ɵdid(1, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterComponent_2)), i1.ɵdid(3, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterComponent_3)), i1.ɵdid(5, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.register_email; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.register_pass; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.register_data; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_RegisterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-register", [], null, null, null, View_RegisterComponent_0, RenderType_RegisterComponent)), i1.ɵdid(1, 114688, null, 0, i17.RegisterComponent, [i6.UserDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegisterComponentNgFactory = i1.ɵccf("app-register", i17.RegisterComponent, View_RegisterComponent_Host_0, {}, {}, []);
export { RegisterComponentNgFactory as RegisterComponentNgFactory };
