<div class="video-player-center title-grd">

<video id="videos" *ngIf="!notSubscribed"></video>
<div class="video-cs-blk">
    <div class="movie-title title-grd">
        <h2>{{ videos?.title }}</h2>
        <h3 *ngIf="videos && videos?.season_name">{{ videos?.season_name }}</h3>
    </div>
</div>
<div class="initimation-subscribe" id="demo" *ngIf="notSubscribed">
    <div class="initimation-subscribe-inner">
        <p>Subscribe to watch the video</p>
        <a href="{{ href }}" target="_blank()" class="org-btn wave make-subscription" ui-sref="subscribeinfo">subscribe now</a>
    </div>
</div>
<div class="initimation-subscribe" id="demo" *ngIf="payMethod === 2">
    <div class="initimation-subscribe-inner">
        <p>Buy Now to watch the video</p>
        <a href="{{ href }}" target="_blank()" class="org-btn wave make-subscription" ui-sref="subscribeinfo">Buy now</a>
    </div>
</div>
<div class="initimation-subscribe" id="demo" *ngIf="geoStatus">
        <div class="geofence-region" id ="geofence-region">
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 330 330">
                    <path d="M165,0C74.019,0,0,74.02,0,165.001C0,255.982,74.019,330,165,330s165-74.018,165-164.999C330,74.02,255.981,0,165,0z
                            M165,300c-74.44,0-135-60.56-135-134.999C30,90.562,90.56,30,165,30s135,60.562,135,135.001C300,239.44,239.439,300,165,300z"/>
                    <path d="M164.998,70c-11.026,0-19.996,8.976-19.996,20.009c0,11.023,8.97,19.991,19.996,19.991
                        c11.026,0,19.996-8.968,19.996-19.991C184.994,78.976,176.024,70,164.998,70z"/>
                    <path d="M165,140c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-90C180,146.716,173.284,140,165,140z
                        "/>
                </svg>
            
                <span>This video is not available in your region</span>
        </div>    
    </div>
<div>
    <span id='online-toast' [style.display]="status=='OFFLINE' ? 'block' : 'none'" class="online-toast">You are not connected to the internet</span>
</div>
    </div>