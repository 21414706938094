import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { WatchVideoComponent } from './modules/watch-video/watch-video.component';
import { EmbedComponent } from './modules/embed/embed.component';
import { RegisterComponent } from './modules/register/register.component';
import { PlansComponent } from './modules/plans/plans.component';
import { LoginComponent } from './modules/login/login.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { MaestrosComponent } from './maestros/maestros.component';
import { MentoresComponent } from './modules/mentores/mentores.component';
import { AcademiasComponent } from './modules/academias/academias.component';
import { AcademiasDetailComponent } from './modules/academias-detail/academias-detail.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'plans',
    component: PlansComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'act/:token',
    component: HomeComponent,
  },
  {
    path: 'watch/:video_id',
    component: WatchVideoComponent,
  },
  {
    path: 'embed/:video_id',
    component: EmbedComponent,
  },
  {
    path: 'watch/:video_id/:playlist_id/:name',
    component: WatchVideoComponent,
  },
  {
    path: 'maestros',
    component: MaestrosComponent,
  },
  {
    path: 'mentores',
    component: MentoresComponent,
  },
  {
    path: 'academias',
    component: AcademiasComponent,
  },
  {
    path: 'academias-detail/:slug',
    component: AcademiasDetailComponent,
  },
  {
    path: 'profile',
    loadChildren: './modules/profile/profile.module#ProfileModule',
  },
  {
    path: 'playlist',
    loadChildren: './modules/playlist/playlist.module#PlaylistModule',
  },
  {
    path: 'webseries/:seriesslug',
    loadChildren:
      './modules/series-detail/series-detail.module#SeriesDetailModule',
  },
  {
    path: 'content/:slug',
    loadChildren: './modules/cms/cms.module#CmsModule',
  },
  {
    path: 'search',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule',
  },
  {
    path: 'reset-password/:id',
    loadChildren:
      './modules/reset-password/reset-password.module#ResetPasswordModule',
  },
  {
    path: 'save-playlist',
    loadChildren:
      './modules/profile/save-playlist/save-playlist.module#SavePlaylistModule',
  },
  {
    path: 'card-payment/:plan',
    loadChildren:
      './modules/card-payment/card-payment.module#CardPaymentModule',
  },
  {
    path: 'subscription',
    loadChildren:
      './modules/subscription/subscription.module#SubscriptionModule',
  },
  {
    path: 'video-not-found',
    loadChildren:
      './modules/video-not-found/video-not-found.module#VideoNotFoundModule',
  },
  {
    path: 'checkout/:type/:slug',
    loadChildren: './modules/checkout/checkout.module#CheckoutModule',
  },
  {
    path: '**',
    loadChildren:
      './modules/page-not-found/page-not-found.module#PageNotFoundModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
