import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }
    // Observable User details
    private currentUser = new BehaviorSubject<any>(localStorage.getItem('currentUser'));
    localUser = this.currentUser.asObservable();

    // Observable popup details
    private popup = new BehaviorSubject<any>('close');
    display = this.popup.asObservable();

    // Observable popup details
    private sharePopup = new BehaviorSubject<any>('close');
    displayShare = this.sharePopup.asObservable();


    // Observable popup details
    private url = new BehaviorSubject<any>('home');
    nextPage = this.url.asObservable();

    private welcomePopup = new BehaviorSubject<any>(false);
    showPopup = this.welcomePopup.asObservable();

    private verifyMailPopup = new BehaviorSubject<any>(false);
    verifyPopup = this.verifyMailPopup.asObservable();

    private showLogoutHeader = new BehaviorSubject<any>(true);
    getLogoutHeader = this.showLogoutHeader.asObservable();

    changeLogoutHeader(data: any) {
      this.showLogoutHeader.next(data);
    }

    // user details
    userData(data: any) {
      this.currentUser.next(data);
    }

    // login, register and forget password popup
    displayPopup(data: any) {
      this.popup.next(data);
    }

    // login, register and forget password popup
    displaySharePopup(data: any) {
      this.sharePopup.next(data);
    }

    // redirect next page
    redirectUrl(data: any) {
      this.url.next(data);
    }

    showWelcomePopup(data: any) {
      this.welcomePopup.next(data);
    }

    showVerifyMailPopup(data: any) {
      this.verifyMailPopup.next(data);
    }

    //
}
