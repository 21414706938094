<app-header *ngIf="headerFlag"></app-header>
<div class="min-content">
  <router-outlet></router-outlet>
  <div class="welcome-ovelay no-connetion" [class.active]="noConnectionActive" *ngIf="!status">
    <div class="welcome-popup-wrap">
      <svg width="333" height="266" viewBox="0 0 333 266" fill="none" xmlns="http://www.w3.org/2000/svg" class="pop-bg">
        <g opacity="0.35" clip-path="url(#clip90)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.81043 26.9632C0.738291 29.713 1.28765 33.622 4.03747 35.6941L303.101 261.055C305.851 263.127 309.76 262.577 311.832 259.827L329.341 236.592C331.413 233.842 330.864 229.933 328.114 227.861L252.048 170.541C252.729 170.17 253.367 169.701 253.941 169.133L271.834 151.474C275.242 148.118 275.123 142.611 271.543 139.447C239.52 111.127 198.628 97.9689 158.363 99.9452L112.605 65.4637C176.903 47.5848 248.505 62.5084 300.616 110.249C303.931 113.289 309.064 113.216 312.254 110.052L330.042 92.4035C333.383 89.0837 333.315 83.665 329.855 80.4647C258.891 14.8274 157.546 -0.186737 72.6817 35.3794L29.0504 2.50086C26.3006 0.428716 22.3916 0.978085 20.3195 3.72791L2.81043 26.9632Z" fill="url(#paint10_linear)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5247 70.1733C10.4793 73.4533 6.51682 76.884 2.64495 80.4653C-0.81513 83.6656 -0.882669 89.0843 2.46311 92.4042L20.2519 110.053C23.4418 113.222 28.5748 113.289 31.8894 110.25C37.4042 105.198 43.1375 100.513 49.0575 96.1957L14.5247 70.1733ZM83.9959 122.524C75.9326 127.387 68.216 133.028 60.9571 139.448C57.3724 142.617 57.2581 148.119 60.661 151.475L78.5537 169.134C81.6708 172.209 86.6895 172.417 90.0093 169.549C99.4521 161.387 109.914 154.995 120.952 150.372L83.9959 122.524ZM164.17 182.939C146.774 184.013 133.001 198.459 133.001 216.125C133.001 234.491 147.885 249.375 166.251 249.375C184.616 249.375 199.501 234.491 199.501 216.125C199.501 213.674 199.235 211.284 198.732 208.983L164.17 182.939Z" fill="url(#paint11_linear)"/>
        </g>
        <defs>
        <linearGradient id="paint10_linear" x1="170.148" y1="265.426" x2="169.551" y2="1.24645" gradientUnits="userSpaceOnUse">
        <stop stop-color="#603511"/>
        <stop offset="0.0292541" stop-color="#A8751B"/>
        <stop offset="0.1787" stop-color="#CA9619"/>
        <stop offset="0.3327" stop-color="#D2A52C"/>
        <stop offset="0.637" stop-color="#E8CD5A"/>
        <stop offset="0.939801" stop-color="#EAD160"/>
        <stop offset="1" stop-color="#DDB942"/>
        </linearGradient>
        <linearGradient id="paint11_linear" x1="101.632" y1="251.514" x2="101.165" y2="70.1745" gradientUnits="userSpaceOnUse">
        <stop stop-color="#603511"/>
        <stop offset="0.0292541" stop-color="#A8751B"/>
        <stop offset="0.1787" stop-color="#CA9619"/>
        <stop offset="0.3327" stop-color="#D2A52C"/>
        <stop offset="0.637" stop-color="#E8CD5A"/>
        <stop offset="0.939801" stop-color="#EAD160"/>
        <stop offset="1" stop-color="#DDB942"/>
        </linearGradient>
        <clipPath id="clip90">
        <rect width="332.5" height="266" fill="white"/>
        </clipPath>
        </defs>
      </svg>
      <div class="welcome-popup-content">
        <h4>{{ 'NO_CONNECTION.ALERT' | translate }}</h4>
        <h2>{{ 'NO_CONNECTION.CONNECTION_ERROR' | translate }}<span>.</span></h2>
        <p>{{ 'NO_CONNECTION.NO_CONNECT' | translate }}<span>{{ 'NO_CONNECTION.CHECK_CONNECTION' | translate }}</span> <span>{{ 'NO_CONNECTION.TRY_AGAIN' | translate }}</span></p>
      </div>
      <svg (click)="noConnectionActive = !noConnectionActive" class="close" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.6773 1.36396L7.72754 6.31371L2.77779 1.36396C2.42424 1.01041 2.07068 0.656854 1.36358 1.36396C0.656471 2.07107 1.01002 2.42462 1.36358 2.77817L6.31333 7.72792L1.36358 12.6777C1.01002 13.0312 0.797893 13.5262 1.36358 14.0919C2.07068 14.799 2.42424 14.4454 2.77779 14.0919L7.72754 9.14214L12.6773 14.0919C13.0308 14.4454 13.3844 14.799 14.0915 14.0919C14.7986 13.3848 14.4451 13.0312 14.0915 12.6777L9.14175 7.72792L14.0915 2.77817C14.4451 2.42462 14.7986 2.07107 14.0915 1.36396C13.3844 0.656854 13.0308 1.01041 12.6773 1.36396Z" fill="white"/>
      </svg>
    </div>
  </div>
</div>
<app-footer *ngIf="footerFlag"></app-footer>