import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayerService } from '../../core/services/player.service';
import { PlatformLocation } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'app-watch-video',
  templateUrl: './watch-video.component.html',
  styleUrls: ['./watch-video.component.scss']
})
export class WatchVideoComponent implements OnInit, AfterViewInit, OnDestroy {
  player: any;
  params: any;
  videos: any;
  live: boolean;
  fullParams: any;
  liveChatStatus: Boolean = false;
  videoTitle: String;
  x: any;
  isMobile: Boolean = true;
  webseries: any;
  subscribedPlan: any;
  isLogged: Boolean = false;
  user: any = {};
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    event.preventDefault();
    // setTimeout(() => {
    //   if (this.fullParams['playlist_id'] !== undefined) {
    //     this.router.navigate([
    //       'video/' +
    //         this.fullParams['video_id'] +
    //         '/' +
    //         this.fullParams['playlist_id'] +
    //         '/' +
    //         this.fullParams['name']
    //     ]);
    //   } else {
    //     this.router.navigate(['video/' + this.fullParams['video_id']]);
    //   }
    // }, 0.1);
  }
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private playerService: PlayerService,
    private authService: AuthService,
    private location: PlatformLocation,
    private router: Router,
    private el: ElementRef,
    private titleService: Title
  ) {
    this.stopHeartBeat();
    this.route.params.subscribe((params) => {
      this.fullParams = params;
      this.params = params['video_id'];
        this.getVideos();
    });
    this.getWindowSize();
    window.addEventListener('resize', this.getWindowSize);
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0 });
    this.user = this.authService.decryption();
    if (localStorage.getItem('currentUser')) {
      this.isLogged = true;
      this.subscribedPlan = this.user['response'].subscription_plan_id;
      if (this.subscribedPlan === undefined) {
        this.subscribedPlan = this.user['response'].subscribed_plan.pivot.subscription_plan_id;
      }
    }
    document.getElementsByTagName('body')[0].removeAttribute('style');
    if (localStorage.getItem('theme')) {
      if (localStorage.getItem('theme') === 'light') {
        document.body.classList.add('main-dark');
      }
      if (localStorage.getItem('theme') === 'dark') {
        document.body.classList.add('main-dark');
      }
    } else {
      document.body.classList.add('main-dark');
    }
  }
  ngAfterViewInit() {
    this.el.nativeElement.ownerDocument.body.style.backgroundColor = '#111418';
  }
  videoPlay(event) {
    this.player = event;
  }
  getVideos() {
    const url = 'v2/watchvideo/';
    this.apiService.callGetAPI(url + this.fullParams['video_id']).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          const videosDetail = data['response'];
          this.apiService.callGetAPI('v2/course_detail/' + videosDetail.videos.video_category_slug).subscribe(
            (webdata: any) => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                this.webseries = webdata['response'];
                this.videos = data['response'];
                this.playerService.videoData(this.videos.videos);
                this.playerService.videoPaymentData(this.videos.payment_info);
                // tslint:disable-next-line:max-line-length
                const videoMetaTitle = (this.videos.video_meta_data && this.videos.video_meta_data.hasOwnProperty('title') && this.videos.video_meta_data.title) ? this.videos.video_meta_data.title : this.videos.videos.title;
                this.titleService.setTitle(
                  'Maestros | ' + videoMetaTitle
                );
              }
              if (
                this.videos.videos.scheduledStartTime !== '' &&
                this.videos.videos.scheduledStartTime > new Date().getTime()
              ) {
                if (this.fullParams['playlist_id'] !== undefined) {
                  this.router.navigate([
                    'video/' +
                      this.fullParams['video_id'] +
                      '/' +
                      this.fullParams['playlist_id'] +
                      '/' +
                      this.fullParams['name']
                  ]);
                } else {
                  this.router.navigate(['webseries/' + this.videos.videos.video_category_slug]);
                }
              }
              if (this.videos.videos.is_free === 1 && this.subscribedPlan === 22) {
                return true;
              }
              if (this.videos.videos.type_id === 1) {
                return true;
              }
              if (this.videos.videos.type_id === 2) {
                if (this.isLogged) {
                  return true;
                } else {
                  this.router.navigate(['webseries/' + this.videos.videos.video_category_slug]);
                }
              }
              if (this.webseries.is_premium === 1 || this.webseries.price > 0) {
                if ((this.webseries.is_premium === 1 && this.subscribedPlan === 22) &&
                (this.webseries.price > 0 && this.webseries.payment_info.is_bought === 0)) {
                  this.router.navigate(['webseries/' + this.videos.videos.video_category_slug]);
                } else if (this.webseries.is_premium === 1 && (this.subscribedPlan === 2 || this.subscribedPlan === 10) ||
                (this.webseries.price > 0 && this.webseries.payment_info.is_bought === 1)) {
                  return true;
                } else {
                  this.router.navigate(['webseries/' + this.videos.videos.video_category_slug]);
                }
              } else {
                return true;
              }
              if (this.videos.videos.is_premium === 1) {

              } else {
                this.storeVideoAnalytics();
              }
            }
          );
        }
      },
      (error: any) => {
        if (
          error.status === 403 &&
          error &&
          error.error &&
          error.error.response &&
          error.error.response.data &&
          error.error.response.data.title
        ) {
          this.videoTitle = error.error.response.data.title;
          return;
        }

        if (this.fullParams['playlist_id'] !== undefined) {
          this.router.navigate([
            'video/' +
              this.fullParams['video_id'] +
              '/' +
              this.fullParams['playlist_id'] +
              '/' +
              this.fullParams['name']
          ]);
        }
        if (this.fullParams['video_id'] !== undefined) {
          this.router.navigate(['video/' + this.fullParams['video_id']]);
        }

        if (error.status === 404) {
          this.router.navigate(['video-not-found']);
        } else {
          this.apiService.failureResponse(error);
        }
      }
    );
  }

  goHome() {
    this.router.navigate(['home']);
  }

  PageBack() {
    if (localStorage.getItem('previousUrl') === null && localStorage.getItem('previousUrl') !== undefined) {
      this.router.navigate(['webseries/' + localStorage.getItem('previousUrl')]);
      localStorage.removeItem('previousUrl');
      return;
    } else {
      this.router.navigate(['home']);
      localStorage.removeItem('previousUrl');
    }
  }

  ngOnDestroy() {
    this.el.nativeElement.ownerDocument.body.style.backgroundColor = '';
  }
  // Live Chat Configuration
  /**
   *  Toggle Live chat(Open/close)
   */
  toggleLiveChat(status) {
    this.liveChatStatus = status === 'open' ? true : false;
  }
  /**
   * Get window size for configuration
   */
  getWindowSize() {
    if (window.innerWidth >= 1199) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
  stopHeartBeat() {
    this.apiService
      .callPostAPI('v2/stop-heart-beat', environment.analyticsApiUrl)
      .subscribe();
  }
  /**
   * Stores the video analytics related data which includes view count, user watch history and
   * continue watching
   *
   * @returns void
   *
   */
  storeVideoAnalytics() {
    const isWatchHistoryActive = 1;
    let isContinueWatchingHistoryActive;
    let seconds;
    if (this.videos.videos.hasOwnProperty('recently_watched')) {
      // tslint:disable-next-line:max-line-length
      isContinueWatchingHistoryActive = (this.videos.videos.recently_watched.length > 0 && this.videos.videos.recently_watched[0].hasOwnProperty('continue_watching_is_active')) ? this.videos.videos.recently_watched[0].continue_watching_is_active : 0;
      // tslint:disable-next-line:max-line-length
      seconds = (this.videos.videos.recently_watched.length > 0 && this.videos.videos.recently_watched[0].hasOwnProperty('seconds')) ? this.videos.videos.recently_watched[0].seconds : 0;
    } else {
      isContinueWatchingHistoryActive = 0;
      seconds = 0;
    }
    this.apiService
    .callPostAPI(
      'v2/videoanalytics',
      { video_slug: this.videos.videos.slug, isWatchHistoryActive: isWatchHistoryActive, isContinueWatchingHistoryActive: isContinueWatchingHistoryActive, seconds: seconds},
      environment.analyticsApiUrl
    )
    .subscribe();
  }
}
