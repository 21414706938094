<div class="registers">
  <h2>{{ 'SIGN_UP.CREATE_ACCOUNT_STEPS' | translate }}<span>.</span></h2>
  <h5>{{ 'SIGN_UP.CREATE_ACCOUNT_DESC' | translate }}</h5>
  <div class="form">    
    <form #regEmailForm="ngForm" (ngSubmit)="onSubmit(regEmailForm)">
      <div class="groups">
        <label>{{ 'SIGN_UP.EMAIL' | translate }}</label>
        <input type="text" required pattern=".+@.+\..+" name="email" type="email"
        #userEmail="ngModel" [(ngModel)]="email" (focus)="onFocus()"/>
        <span class="error" *ngIf="userEmail.errors?.required && ( touched || userEmail?.touched )">
          {{ 'SIGN_UP.EMAIL_REQUIRED_ERROR' | translate }}
        </span>
        <span class="error" *ngIf="!error?.userEmail && userEmail.errors?.pattern">
          {{ 'SIGN_UP.EMAIL_VALID_ERROR' | translate }}
        </span>
        <span class="error" *ngIf="error?.email">
          {{ error.email }}
        </span>
      </div>
      <div class="grouping-select check">
        <div class="form-groups">
          <label class="container">
            <input name="terms" type="checkbox" #termsChecked="ngModel" [(ngModel)]="terms" required>
            <span class="checkmark"></span>
          </label>
          <p>{{ 'SIGN_UP.ACCEPT_1' | translate }} <a href="content/terms-and-conditions" target="_blank">{{ 'SIGN_UP.ACCEPT_2' | translate }}</a> {{ 'SIGN_UP.ACCEPT_3' | translate }} <a href="content/politica-de-privacidad" target="_blank">{{ 'SIGN_UP.ACCEPT_4' | translate }}</a></p>
          <div class="error" *ngIf="termsChecked.errors?.required && (touched || termsChecked?.touched)">
            {{ 'SIGN_UP.TERMS_CONDITIONS_REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <button [disabled]="submitted" type="submit">{{ 'SIGN_UP.SIGN_UP' | translate }}</button>
     </form>
    <p class="or">{{ 'SIGN_UP.PREFER' | translate }}</p>
    <h4>{{ 'SIGN_UP.SIGN_UP_WITH' | translate }}</h4>
    <app-social-login></app-social-login>
    <h3>{{ 'SIGN_UP.HAVE_ACCOUNT' | translate }} <a routerLink="/login">{{ 'SIGN_UP.SIGN_IN' | translate }}</a></h3>
  </div>
</div>