import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { environment } from '../../../environments/environment';
import videoJs from '../../../assets/js/play.es.js';
import * as CryptoJS from 'crypto-js';
import { Router, ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../core/services/header.service';

@Component({
  selector: 'app-academias-detail',
  templateUrl: './academias-detail.component.html',
  styleUrls: ['./academias-detail.component.scss']
})
export class AcademiasDetailComponent implements OnInit {

  academyDetail: any;
  academyCourses: any = [];
  pageSlug = '';
  bannerVideo: any;
  bannerPlayer: any;
  decrypted: any;
  encrypted: any;
  plainText: any;
  cipherText: any;
  isBannerMuted = true;
  bannerTeasers: any;
  bannerVideoPlaying = false;
  pageScolled = false;
  pageBottom = true;
  page = 1;
  academyDetailThumbnail = '';
  slides2 = [
    {img: '../../../assets/images/liv-img.png'},
    {img: '../../../assets/images/liv-img.png'},
    {img: '../../../assets/images/liv-img.png'},
  ];
  slideConfig2 = {'slidesToShow': 1, 'slidesToScroll': 1, 'infinite': false, 'dots': true};
  academyTrailerSlug: string;

  @HostListener('window:scroll', ['$event'])
  onscroll() {
    if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 200)) {
      if (this.pageBottom === false) {
        this.pageBottom = true;
        this.getCourses();
      }
    }
  }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.route.params.subscribe(
      (data) => {
        this.pageSlug = data.slug;
      }
    );
    this.apiService.callGetAPI('v2/academias_details/' + this.pageSlug + '?page=' + this.page).subscribe(
      (data: any) => {
        this.academyDetail = data.response.data.academy;
        if (this.academyTrailerSlug === undefined && this.academyDetail.trailer) {
          this.academyTrailerSlug = this.academyDetail.trailer.slug;
          this.getBannerVideo();
        }
        this.headerService.changeBreadcrumb(this.academyDetail.name);
        this.academyDetailThumbnail = this.academyDetail.thumbnail_image;
        if (this.academyDetail.thumbnail_image === null) {
          this.academyDetailThumbnail = '../../../assets/images/inner-pag/aca-ban-logo.png';
        }
        if (this.pageScolled === false) {
          this.pageScolled = true;
          this.getCourses();
        }
      }
    );
  }

  getCourses() {
    this.apiService.callGetAPI('v2/academias_details/' + this.pageSlug + '?page=' + this.page).subscribe(
      (data: any) => {
        this.academyCourses.push(...data.response.data.academy.courses.data);
        if (data.response.data.academy.courses.next_page_url !== null) {
          this.pageBottom = false;
          this.page = data.response.data.academy.courses.current_page + 1;
        }
      }
    );
  }

  getBannerVideo() {
    const url = 'v2/watchvideo/' + this.academyTrailerSlug;
    this.apiService.callGetAPI(url).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.bannerVideo = data['response'].videos;
          this.decrypt();
        }
      },
      (error: any) => {
        if (
          error.status === 403 &&
          error &&
          error.error &&
          error.error.response &&
          error.error.response.data &&
          error.error.response.data.title
        ) {
          // this.videoTitle = error.error.response.data.title;
          return;
        }
      }
    );
  }
  hexToBinary(num) {
    const hex = num,
      bytes = [];
    for (let i = 0; i < hex.length - 1; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return String.fromCharCode.apply(String, bytes);
  }
  d2h(d) {
    return d.toString(16);
  }
  binaryToHex(num) {
    let str = '',
      i = 0;
    const tmp_len = num.length;
    let c;
    for (; i < tmp_len; i += 1) {
      c = num.charCodeAt(i);
      str += this.d2h(c);
    }
    return str;
  }
  decrypt() {
    if (typeof this.bannerVideo.passphrase !== 'undefined') {
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(this.bannerVideo.passphrase)
      });
      this.decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        CryptoJS.enc.Hex.parse(environment.crypto_key),
        {
          keySize: 256,
          iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
          mode: CryptoJS.mode.CBC
        }
      );
      const change = this.decrypted.toString(CryptoJS.enc.Utf8);
      this.plainText = parseInt(this.hexToBinary(change), 10);
      this.setTeaserOneConfig();
    }
  }
  encrypt() {
    const utf8 = CryptoJS.enc.Utf8.parse('vplayed/' + this.plainText);
    const hex_utf = utf8.toString(CryptoJS.enc.Utf8);
    const hex = this.binaryToHex(hex_utf);
    this.encrypted = CryptoJS.AES.encrypt(
      hex,
      CryptoJS.enc.Hex.parse(environment.crypto_key),
      {
        keySize: 256,
        iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
        mode: CryptoJS.mode.CBC
      }
    );
    this.cipherText = this.encrypted.ciphertext.toString(CryptoJS.enc.utf8);
    return this.cipherText;
  }
  setTeaserOneConfig() {
    const banner_video = document.getElementById('banner_video');
    videoJs.Hls.xhr.beforeRequest = (options) => {
      options.headers = [];
      if (this.bannerVideo.is_live === 0) {
        options.headers['Title'] = this.encrypt();
      }
    };
    this.bannerPlayer = videoJs(banner_video, {
      muted: this.isBannerMuted === true ? true : false,
      bigPlayButton: false,
      controls: false,
      loop: true,
      preload: 'auto',
      fluid: true,
      autoplay: false,
      progressControl: {
        seekBar: false
      },
      html5: {
        hls: {
          customDrm: {
            keys: 'MjMyOGMwYWM4MmNhM2ZjNmY4ZDcyNzI0NWQzMDgzYmUxMzU3ZjA0NGZmNWNiNGMzYTExNDJiMDkzY2YyZjNjOTAwNTM0MjE4NTUxMmM5ODlhMWYwMzI3YjQwYmY3YmY1YjE2MDBkMTYzYTA1ZTFkYzE4OWJhMjUwOGM1MTIwODksNDg3MGVjMDY0YzEwODE0MmNjMGZmYmQ2ZmZkZjFlMDhlNzU2YTZkZWYyYzc4MDQ2ODlmZTRhZTBmYzk4NTIyMSxiMmJmNWE5NzAwNGJiMzFkNmY1YTljMmM4NDQ0OTc1OQ'
          }
        }
      }
    });
    this.bannerPlayer.src({
      src: this.bannerVideo.hls_playlist_url,
      type: 'application/x-mpegURL'
    });
    this.bannerPlayer.play();
    this.bannerVideoPlaying = true;
  }

  onBannerSoundClick() {
    if (this.isBannerMuted) {
      this.bannerPlayer.muted(true);
    } else {
      this.bannerPlayer.muted(false);
    }
  }

}
