<section class="inner-common-banner mentores">
  <video id="banner_video"></video>
  <img class="inner-ban-background" src="{{ bannerTeasers.response.data.banner_teasers[5].poster_image }}" alt="" [ngStyle]="{ display: isBannerTextHidden ? 'none' : '' }">
  <img class="bann-log-com" src="../../assets/images/inner-pag/men-ban-cap-logo.png" alt="" [ngStyle]="{ display: isBannerTextHidden ? 'none' : '' }">
  <div class="inncer-common-caption" *ngIf="bannerConfiguration" [ngStyle]="{ display: isBannerTextHidden ? 'none' : '' }">
    <h2>{{ 'MENTORES.MAIN_TITLE' | translate }}<span> .</span></h2>
    <h4>{{ bannerConfiguration.mentores_page_title }}</h4>
    <p>{{ bannerConfiguration.mentores_page_info }}</p>
    <a (click)="showBannerVideo()" class="inner-comm-btn">
      <img src="../../assets/images/svg/com-play.svg" alt="">      
      <span>{{ 'MENTORES.SEE_PRESENTATION' | translate }}</span>
    </a>
  </div>
  <div class="contrl-wrp">
    <div class="ply-wrp">
      <div *ngIf="bannerVideoPlaying" (click)="onStopButtonClick()" class="vid-contrlproperty-1-multi-property-2-pause" [ngClass]="{ 'active' : bannerVideoPlaying }"></div>
    </div>
    <div class="volume-wrp">
      <div *ngIf="bannerVideoPlaying" (click)="isBannerMuted = !isBannerMuted; onBannerSoundClick()" class="vlm-wrpproperty-1-opciones-property-2-off" [ngClass]="{ 'active': isBannerMuted }"></div>
      <div *ngIf="bannerVideoPlaying" (click)="isBannerMuted = !isBannerMuted; onBannerSoundClick()" class="vlm-wrpproperty-1-opciones-property-2-on" [ngClass]="{ 'active': !isBannerMuted }"></div>
    </div>
  </div>
</section>

<div class="common-slider bnner-sld" *ngIf="mostViewedCourses.length > 0">
  <div class="common-slider-head">
    <h2>{{ 'MENTORES.MOST_VIEWED_HEADER' | translate }}</h2>
  </div>
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div [slide]="slide" [most_viewed]="true" slide ngxSlickItem class="slide" *ngFor="let slide of mostViewedCourses">
    </div>
  </ngx-slick-carousel>
</div>


<section class="categories-main" >
  <a *ngIf="selectedCategory" (click)="getCategoryCourses('', 1)" class="view-ll-card">{{ 'MENTORES.CATEGORIES_SHOW_ALL' | translate }}
  </a>
  <div class="common-slider-head">
    <h2>{{ 'MENTORES.CATEGORIES_TITLE' | translate }}</h2>
  </div>
  <ul #target class="categories-tab-wrap" id="categories-tab-wrap">
    <li *ngFor="let category of categoriesList" [ngClass]="{ 'active': category.slug === selectedCategory }">
      <a (click)="getCategoryCourses(category, 1)">{{ category.name }}</a>
    </li>
  </ul>
  <div class="common-slider-head">
    <h2>{{ selectedCategoryName }}</h2>
  </div>
  <ul class="cat-cardlist-wrap mentors-bottom">
    <li *ngFor="let slide of categoryCourses">
      <div [slide]="slide" [place]="'bottom'" slide class="slide">
      </div>
    </li>
  </ul>
  <div class="skel-card-wrp" *ngIf="!isCourseLoaded">
    <div class="skel-cards-set skeleton-custm"></div>
    <div class="skel-cards-set skeleton-custm"></div>
    <div class="skel-cards-set skeleton-custm"></div>
    <div class="skel-cards-set skeleton-custm"></div>
  </div>
  </section>
  <div class="tab-slider" [ngClass]="{ 'active': isTabVisible, '': !isTabVisible }">
    <div class="tab-slider-head">
      <h2>{{ 'MENTORES.CATEGORIES_TITLE' | translate }}</h2>
      <a *ngIf="selectedCategory" (click)="getCategoryCourses('', 1); scrollToView(target)" class="view-ll-card">{{ 'MENTORES.CATEGORIES_SHOW_ALL' | translate }}
      </a>
    </div>
    <ngx-slick-carousel class="carousel" 
                          #slickModal="slick-carousel" 
                          [config]="slideConfigs">
          <div ngxSlickItem class="slides" *ngFor="let category of categoriesList">
            <a (click)="getCategoryCourses(category, 1); scrollToView(target)" class="tab-slider-list" [ngClass]="{ 'active': category.slug === selectedCategory }">{{ category.name }}</a>
          </div>
      </ngx-slick-carousel>
  </div>
  
