import { Component, OnInit, Input } from '@angular/core';
import videoJs from '../../../../assets/js/play.es-current.js';
import * as CryptoJS from 'crypto-js';
import { ApiService } from '../../../core/services/api.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/auth/auth.service';
import { HeaderService } from '../../../core/services/header.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() bannerTeasers;
  bannerVideo: any;
  bannerPlayer: any;
  decrypted: any;
  encrypted: any;
  plainText: any;
  cipherText: any;
  isBannerMuted = true;
  currentUserName: string;
  bannerConfiguration: any;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    const bannerConfig = this.headerService.getBannerConfig();
    if (bannerConfig) {
      this.setBannerConfig(bannerConfig);
    } else if (!bannerConfig) {
      this.apiService.callGetAPI('v2/home-page-configuration', environment.commonApiUrl).subscribe(
        (data: any) => {
          this.headerService.setBannerConfig(data);
          this.setBannerConfig(data);
        }
      );
    }
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
    const userValues = localStorage.getItem('currentUser');
    if (userValues) {
      const userData = this.authService.decryption(userValues);
      if (userData['response'].name) {
        this.currentUserName = userData['response'].name;
      } else {
        this.currentUserName = '';
      }
    }
    this.getBannerVideo();
  }

  setBannerConfig(data) {
    data.response.forEach(elem => {
      const object = {
        [elem.name_slug]: elem.name_text,
      };
      this.bannerConfiguration = {
        ...this.bannerConfiguration, ...object
      };
    });
    this.bannerConfiguration.banner_info_2_p1 = this.bannerConfiguration.banner_info_2.substring(0, this.bannerConfiguration.banner_info_2.indexOf('##PLAN##'));
    this.bannerConfiguration.banner_info_2_p2 = this.bannerConfiguration.banner_info_2.substring(this.bannerConfiguration.banner_info_2.indexOf('##PLAN##') + 8);
  }

  getBannerVideo() {
    const url = 'v2/watchvideo/' + this.bannerTeasers.response.data.banner_teasers[0].slug;
    this.apiService.callGetAPI(url).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.bannerVideo = data['response'].videos;
          this.decrypt();
        }
      },
      (error: any) => {
        if (
          error.status === 403 &&
          error &&
          error.error &&
          error.error.response &&
          error.error.response.data &&
          error.error.response.data.title
        ) {
          // this.videoTitle = error.error.response.data.title;
          return;
        }
      }
    );
  }

  hexToBinary(num) {
    const hex = num,
      bytes = [];
    for (let i = 0; i < hex.length - 1; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return String.fromCharCode.apply(String, bytes);
  }

  d2h(d) {
    return d.toString(16);
  }

  binaryToHex(num) {
    let str = '',
      i = 0;
    const tmp_len = num.length;
    let c;
    for (; i < tmp_len; i += 1) {
      c = num.charCodeAt(i);
      str += this.d2h(c);
    }
    return str;
  }

  decrypt() {
    if (typeof this.bannerVideo.passphrase !== 'undefined') {
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(this.bannerVideo.passphrase)
      });
      this.decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        CryptoJS.enc.Hex.parse(environment.crypto_key),
        {
          keySize: 256,
          iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
          mode: CryptoJS.mode.CBC
        }
      );
      const change = this.decrypted.toString(CryptoJS.enc.Utf8);
      this.plainText = parseInt(this.hexToBinary(change), 10);
      this.setTeaserOneConfig();
    }
  }

  encrypt() {
    const utf8 = CryptoJS.enc.Utf8.parse('vplayed/' + this.plainText);
    const hex_utf = utf8.toString(CryptoJS.enc.Utf8);
    const hex = this.binaryToHex(hex_utf);
    this.encrypted = CryptoJS.AES.encrypt(
      hex,
      CryptoJS.enc.Hex.parse(environment.crypto_key),
      {
        keySize: 256,
        iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
        mode: CryptoJS.mode.CBC
      }
    );
    this.cipherText = this.encrypted.ciphertext.toString(CryptoJS.enc.utf8);
    return this.cipherText;
  }

  setTeaserOneConfig() {
    const banner_video = document.getElementById('banner_video');
    videoJs.Hls.xhr.beforeRequest = (options) => {
      options.headers = [];
      if (this.bannerVideo.is_live === 0) {
        options.headers['Title'] = this.encrypt();
      }
    };
    this.bannerPlayer = videoJs(banner_video, {
      muted: this.isBannerMuted === true ? true : false,
      controls: false,
      bigPlayButton: false,
      preload: 'auto',
      fluid: true,
      autoplay: false,
      progressControl: {
        seekBar: false
      },
      html5: {
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        nativeTextTracks: false,
        hls: {
          overrideNative: true,
          // tslint:disable-next-line:max-line-length
          customDrm: {
            // tslint:disable-next-line: max-line-length
            keys: 'MjMyOGMwYWM4MmNhM2ZjNmY4ZDcyNzI0NWQzMDgzYmUxMzU3ZjA0NGZmNWNiNGMzYTExNDJiMDkzY2YyZjNjOTAwNTM0MjE4NTUxMmM5ODlhMWYwMzI3YjQwYmY3YmY1YjE2MDBkMTYzYTA1ZTFkYzE4OWJhMjUwOGM1MTIwODksNDg3MGVjMDY0YzEwODE0MmNjMGZmYmQ2ZmZkZjFlMDhlNzU2YTZkZWYyYzc4MDQ2ODlmZTRhZTBmYzk4NTIyMSxiMmJmNWE5NzAwNGJiMzFkNmY1YTljMmM4NDQ0OTc1OQ'
            // 'uri': 'http://d1xxzl3j6dvosn.cloudfront.net/costarica/encrypted.key',
          }
        }
      },
      plugins: {
        hlsQualitySelector: {},
        keyboardShortCuts: {},
        spriteThumbnails: {}
      }
    });
    this.bannerPlayer.src({
      src: this.bannerVideo.hls_playlist_url,
      type: 'application/x-mpegURL'
    });
    this.bannerPlayer.play();
    this.bannerPlayer.on('ended', () => {
      this.bannerPlayer.currentTime(0);
      this.bannerPlayer.play();
    });
  }

  soundBanner() {
    if (this.isBannerMuted) {
      this.bannerPlayer.muted(true);
    } else {
      this.bannerPlayer.muted(false);
    }
  }
}
