import {
  Component,
  OnInit,
  OnDestroy,
  AfterContentInit,
  HostListener,
  ViewChild
} from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import disableScroll from 'disable-scroll';
import { HeaderService } from 'src/app/core/services/header.service';
import { AuthService } from '../../core/auth/auth.service';
import { VideoShareService } from '../../core/services/video-share.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/core/services/user.service';
import videoJs from '../../../assets/js/play.es.js';
import * as CryptoJS from 'crypto-js';

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentInit {
  trending: any = [];
  new: any = [];
  webseries: any = [];
  banner: any = [];
  section: any = [];
  genre: any = [];
  home: any = [];
  routeSub: any;
  load: Boolean;
  defaultOwlCarousel: any = [];
  scrollEvent = true;
  viewPortEnable: Boolean = false;
  moved: any = 7;
  page = 1;
  newNextPage: Boolean = true;
  trendingNextPage: Boolean = true;
  previousPage: Boolean = false;
  sliderBox: any;
  nextMove: any;
  activeItem: any;
  continueWatchingData: any;
  ItemNumber = 7;
  trendingPage = 1;
  loadingSlider: any = [];
  sectionPage: any = [];
  sectionNextPage: any = [];
  categoriesVideos: any = [];
  maestrosCategoriesVideos: any = [];
  search: any = false;
  site_link: any = [];
  getHomeCategory;
  getCategories;
  getHomeCategoryCourses;
  getRecommendedVideos;
  currentDecryptUser: any;
  sliderMovedData: any;
  sliderRowItem: any;
  error: any = {};
  welcomePopup: any = false;
  homeCategories: any;
  homeCategory: any;
  categoryCourses: any;
  selectedCategory: string;
  selectedCategoryName: string;
  selectedCategoryHasCourses: boolean;
  teaserOneVideo: any;
  teaserOnePlayer: any;
  teaserTwoVideo: any;
  teaserTwoPlayer: any;
  teaserThreeVideo: any;
  teaserThreePlayer: any;
  isTeaserOneMuted = true;
  isTeaserTwoMuted = true;
  isTeaserThreeMuted = true;
  decrypted: any;
  encrypted: any;
  plainText: any;
  cipherText: any;
  bannerTeasers: any;
  isSlickInit = false;
  bannerConfiguration: any;
  sponsors: any;
  slides = [
    {img: '../../../assets/images/brands/1.png'},
    {img: '../../../assets/images/brands/2.png'},
    {img: '../../../assets/images/brands/3.png'},
    {img: '../../../assets/images/brands/4.png'},
    {img: '../../../assets/images/brands/5.png'},
    {img: '../../../assets/images/brands/6.png'},
    {img: '../../../assets/images/brands/7.png'},
    {img: '../../../assets/images/brands/8.png'}
  ];
  slideConfig = {'slidesToShow': 4, 'slidesToScroll': 4, 'infinite': false, 'dots': false,
  responsive: [
    {
    breakpoint: 1200,
    settings: {
    slidesToShow: 3,
    slidesToScroll: 3
    }
    },
    {
    breakpoint: 901,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
    },
    {
      breakpoint: 501,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
  };
  slides2 = [
    {img: '../../../assets/images/liv-img.png'},
    {img: '../../../assets/images/liv-img.png'},
    {img: '../../../assets/images/liv-img.png'},
  ];
  slideConfig2 = {'slidesToShow': 1, 'slidesToScroll': 1, 'infinite': false, 'dots': true};
  slideConfig3 = {'slidesToShow': 6, 'slidesToScroll': 1, 'infinite': true, 'dots': false,
  responsive: [
    {
    breakpoint: 1200,
    settings: {
    slidesToShow: 4,
    slidesToScroll: 1
    }
    },
    {
    breakpoint: 641,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1
    }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
    ]
};
  // @HostListener('window:scroll', ['$event'])
  // onscroll() {
  //   const scrollPosY = window.pageYOffset || document.body.scrollTop;
  // }
  constructor(
    private apiService: ApiService,
    private router: Router,
    private headerService: HeaderService,
    private authService: AuthService,
    private videoShareService: VideoShareService,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private userService: UserService
  ) {
    this.continueWatchingData = [];

    localStorage.setItem('previousUrl', this.router.url);
    this.routeSub = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        this.fetchwatchVideo();
      }
    });
  }
  ngOnInit() {
    this.apiService.callGetAPI('v2/home-page-configuration', environment.commonApiUrl).subscribe(
      (data: any) => {
        data.response.forEach(elem => {
          const object = {
            [elem.name_slug]: elem.name_text,
          };
          this.bannerConfiguration = {
            ...this.bannerConfiguration, ...object
          };
        });
      }
    );
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.route.params.subscribe((params) => {
      if (params && params['token']) {
        this.activateUser(params['token']);
      }
    });
    this.currentDecryptUser = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
    this.getHomeCategory = this.headerService.getHomeCategory();
    this.getCategories = this.headerService.getHomeCategories();
    this.getHomeCategoryCourses = this.headerService.getHomeCategoryCourses();
    if (!this.getHomeCategory) {
      this.getHome();
      this.getHomeCategories();
      this.getAllCategoryCourses();
    }
    this.genre.data = [];
    this.new.data = [];
    this.webseries.data = [];
    this.categoriesVideos = [];
    this.sectionPage = [];
    this.sectionNextPage = [];
    this.trending.data = [];
    this.loadingSlider.data = [];
    for (let i = 0; i <= 3; i++) {
      this.loadingSlider.data.push({
        thumbnail_image: environment.defaultImage
      });
    }
    this.getSiteLinks();
    this.headerService.newData$.subscribe((data) => {
      this.new = data;
    });
    this.headerService.webseriesData$.subscribe((data) => {
      this.webseries = data;
    });
    if (this.getHomeCategory) {
      this.getHomeAfterData(this.getHomeCategory);
      this.getHomeCategoriesAfterData(this.getCategories);
      this.getAllCategoryCoursesAfterData(this.getHomeCategoryCourses);
    }
    this.userService.showPopup.subscribe(data => this.welcomePopup = data);
    this.apiService.callGetAPI('v2/home_page_categories').subscribe(
      (data: any) => {
        this.bannerTeasers = data;
        this.getTeaserOne();
      }
    );
    this.apiService.callGetAPI('v2/sponsors', environment.commonApiUrl).subscribe(
      (data) => {
        this.sponsors = data['response'];
      }
    );
  }
  ngAfterContentInit() {
    this.videoShareService.sliderMovedItems.subscribe((data) => {
      this.sliderMovedData = data;
    });
    this.videoShareService.sliderRowItemsCount.subscribe((data) => {
      this.sliderRowItem = data;
    });
  }

  onVideoClick(videoSlug: string) {
    this.router.navigate(['watch/' + videoSlug]);
  }

  getHome() {
    this.load = false;
    this.apiService.callGetAPI('v2/home_page').subscribe(
      (data) => {
        this.headerService.setHomeCategory(data);
        this.getHomeAfterData(data);
      },
      (error) => {
        this.apiService.failureResponse(error, true);
      }
    );
  }

  getHomeAfterData(data) {
    if (data['statusCode'] === 200 && data['error'] === false) {
      let index = 0;
      for (const datas of data['response'].home_content) {
        this.categoriesVideos.push(datas);
        this.sectionPage.push(1);
        if (datas.total === this.categoriesVideos[index].data.length) {
          this.sectionNextPage[index] = false;
        } else {
          this.sectionNextPage[index] = true;
        }
        index++;
      }
      this.load = true;
    }
  }

  next(url) {
    if (url === 'new') {
      this.nextObj();
    } else if (url === 'trending') {
      this.nextObjTrending();
    }
  }
  nextObj() {
    this.page++;
    if (!(this.new.data.length === this.new.total)) {
      this.apiService
        .callGetAPI('v2/home_more?type=new&page=' + this.page)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.new.data.length) {
                this.newNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.new.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    } else {
    }
  }

  nextObjTrending() {
    this.trendingPage++;
    if (this.trendingNextPage) {
      this.apiService
        .callGetAPI('v2/home_more?type=trending&page=' + this.trendingPage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.trending.data.length) {
                this.trendingNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.trending.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }
  sectionNext(section, index) {
    if (this.sectionNextPage[index] === true) {
      this.sectionPage[index] += 1;
      this.apiService
        .callGetAPI(
          'v2/home_more?type=' + section + '&page=' + this.sectionPage[index]
        )
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              data['response'].data.forEach((value) => {
                this.categoriesVideos[index].data.push(value);
              });
              if (
                data['response'].total ===
                this.categoriesVideos[index].data.length
              ) {
                this.sectionNextPage[index] = false;
              }
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }

  getSiteLinks() {
    this.headerService.apiData$.subscribe((data) => {
      if (data !== null) {
        this.site_link = data['response'].site_link;
      }
    });
    if (this.site_link === null) {
      setTimeout(() => {
        this.getSiteLinks();
      }, 1000);
    }
  }
  ngOnDestroy() {
    disableScroll.off();
    this.routeSub.unsubscribe();
  }

  google_play_clicked() {
    if (this.site_link.android_app_link) {
      const a = document.createElement('a');
      a.href = this.site_link.android_app_link
        ? this.site_link.android_app_link
        : undefined;
      a.click();
    }
  }
  activateUser(key) {
    this.apiService
      .callPostAPI(
        'v2/auth/check_activation_code',
        {
          login_type: 'normal',
          acesstype: 'web',
          key: key
        },
        environment.userApiUrl
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            // this.userService.displayPopup('sign-in');
            this.toasterService.success('', data['message']);
            disableScroll.off();
            this.router.navigate(['/login']);
          }
        },
        (error) => {
          if (error.error.statusCode === 422) {
            this.error = error.error.message;
            this.toasterService.error('', this.error);
            this.router.navigate(['/home']);
          } else {
            this.error = {};
            this.toasterService.error(
              '',
              error.error.message ? error.error.message : error.message
            );
            this.router.navigate(['/home']);
          }
        }
      );
  }
  fetchwatchVideo() {
    if (localStorage.getItem('currentUser')) {
      // If logged in then hit the API
      this.apiService.callGetAPI('v2/continue_watching').subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.continueWatchingData = data['response'];
          }
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
    } else {
      this.continueWatchingData = [];
    }

  }

  closePopup() {
    this.userService.showWelcomePopup(false);
    this.userService.showPopup.subscribe(data => this.welcomePopup = data);
  }

  getHomeCategories() {
        this.load = false;
        this.apiService.callGetAPI('v2/home_page_categories').subscribe(
        (data) => {
        this.headerService.setHomeCategories(data);
        this.getHomeCategoriesAfterData(data);
      }
    );
  }

  getHomeCategoriesAfterData(data: any) {
    this.homeCategories = data.response.data.genres;
    this.load = true;
  }

  getAllCategoryCourses() {
    this.apiService.callGetAPI('v2/category_courses').subscribe(
      (data) => {
        this.headerService.setHomeCategoryCourses(data);
        this.getAllCategoryCoursesAfterData(data);
        this.selectedCategory = '';
        this.selectedCategoryHasCourses = false;
        this.selectedCategoryName = '';
      }
    );
  }

  getAllCategoryCoursesAfterData(data: any) {
    this.categoryCourses = data.response.data;
  }

  getCategoryCourses(category: any) {
    this.apiService.callGetAPI('v2/category_courses/' + category.slug).subscribe(
      (data) => {
        this.getCategoryCoursesAfterData(data);
        this.selectedCategory = category.slug;
        this.selectedCategoryHasCourses = category.has_courses;
        this.selectedCategoryName = category.name;
      }
    );
  }

  getCategoryCoursesAfterData(data: any) {
    this.categoryCourses = data.response.data;
  }

  getTeaserOne() {
    const url = 'v2/watchvideo/' + this.bannerTeasers.response.data.banner_teasers[1].slug;
    this.apiService.callGetAPI(url).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.teaserOneVideo = data['response'].videos;
          this.decrypt(1);
        }
      },
      (error: any) => {
        if (
          error.status === 403 &&
          error &&
          error.error &&
          error.error.response &&
          error.error.response.data &&
          error.error.response.data.title
        ) {
          // this.videoTitle = error.error.response.data.title;
          return;
        }
      }
    );
  }
  getTeaserTwo() {
    const url = 'v2/watchvideo/' + this.bannerTeasers.response.data.banner_teasers[2].slug;
    this.apiService.callGetAPI(url).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.teaserTwoVideo = data['response'].videos;
          this.decrypt(2);
        }
      },
      (error: any) => {
        if (
          error.status === 403 &&
          error &&
          error.error &&
          error.error.response &&
          error.error.response.data &&
          error.error.response.data.title
        ) {
          // this.videoTitle = error.error.response.data.title;
          return;
        }
      }
    );
  }
  getTeaserThree() {
    const url = 'v2/watchvideo/' + this.bannerTeasers.response.data.banner_teasers[3].slug;
    this.apiService.callGetAPI(url).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.teaserThreeVideo = data['response'].videos;
          this.decrypt(3);
        }
      },
      (error: any) => {
        if (
          error.status === 403 &&
          error &&
          error.error &&
          error.error.response &&
          error.error.response.data &&
          error.error.response.data.title
        ) {
          // this.videoTitle = error.error.response.data.title;
          return;
        }
      }
    );
  }
  hexToBinary(num) {
    const hex = num,
      bytes = [];
    for (let i = 0; i < hex.length - 1; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return String.fromCharCode.apply(String, bytes);
  }
  d2h(d) {
    return d.toString(16);
  }
  binaryToHex(num) {
    let str = '',
      i = 0;
    const tmp_len = num.length;
    let c;
    for (; i < tmp_len; i += 1) {
      c = num.charCodeAt(i);
      str += this.d2h(c);
    }
    return str;
  }
  decrypt(num: number) {
    if (typeof this.teaserOneVideo.passphrase !== 'undefined' || typeof this.teaserTwoVideo.passphrase !== 'undefined') {
      let cipherParams;
      if (num === 1) {
        cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Hex.parse(this.teaserOneVideo.passphrase)
        });
      } else if (num === 2) {
        cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Hex.parse(this.teaserTwoVideo.passphrase)
        });
      } else if (num === 3) {
        cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Hex.parse(this.teaserThreeVideo.passphrase)
        });
      }
      this.decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        CryptoJS.enc.Hex.parse(environment.crypto_key),
        {
          keySize: 256,
          iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
          mode: CryptoJS.mode.CBC
        }
      );
      const change = this.decrypted.toString(CryptoJS.enc.Utf8);
      this.plainText = parseInt(this.hexToBinary(change), 10);
      if (num === 1) {
        this.setTeaserOneConfig();
      } else if (num === 2) {
        this.setTeaserTwoConfig();
      } else if (num === 3) {
        this.setTeaserThreeConfig();
      }
    }
  }
  encrypt() {
    const utf8 = CryptoJS.enc.Utf8.parse('vplayed/' + this.plainText);
    const hex_utf = utf8.toString(CryptoJS.enc.Utf8);
    const hex = this.binaryToHex(hex_utf);
    this.encrypted = CryptoJS.AES.encrypt(
      hex,
      CryptoJS.enc.Hex.parse(environment.crypto_key),
      {
        keySize: 256,
        iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
        mode: CryptoJS.mode.CBC
      }
    );
    this.cipherText = this.encrypted.ciphertext.toString(CryptoJS.enc.utf8);
    return this.cipherText;
  }
  setTeaserOneConfig() {
    const teaser_one = document.getElementById('teaser_one');
    videoJs.Hls.xhr.beforeRequest = (options) => {
      options.headers = [];
      if (this.teaserOneVideo.is_live === 0) {
        options.headers['Title'] = this.encrypt();
      }
    };
    this.teaserOnePlayer = videoJs(teaser_one, {
      controls: false,
      loop: true,
      preload: 'auto',
      muted: this.isTeaserOneMuted === true ? true : false,
      fluid: true,
      autoplay: false,
      progressControl: {
        seekBar: false
      },
      html5: {
        hls: {
          customDrm: {
            keys: 'MjMyOGMwYWM4MmNhM2ZjNmY4ZDcyNzI0NWQzMDgzYmUxMzU3ZjA0NGZmNWNiNGMzYTExNDJiMDkzY2YyZjNjOTAwNTM0MjE4NTUxMmM5ODlhMWYwMzI3YjQwYmY3YmY1YjE2MDBkMTYzYTA1ZTFkYzE4OWJhMjUwOGM1MTIwODksNDg3MGVjMDY0YzEwODE0MmNjMGZmYmQ2ZmZkZjFlMDhlNzU2YTZkZWYyYzc4MDQ2ODlmZTRhZTBmYzk4NTIyMSxiMmJmNWE5NzAwNGJiMzFkNmY1YTljMmM4NDQ0OTc1OQ'
          }
        }
      }
    });
    this.teaserOnePlayer.src({
      src: this.teaserOneVideo.hls_playlist_url,
      type: 'application/x-mpegURL'
    });
    this.getTeaserTwo();
  }
  teaserOneplayPauseVideo() {
    if (this.teaserOnePlayer.paused()) {
      this.teaserOnePlayer.play();
    } else {
      this.teaserOnePlayer.pause();
    }
  }
  setTeaserTwoConfig() {
    const teaser_two = document.getElementById('teaser_two');
    videoJs.Hls.xhr.beforeRequest = (options) => {
      options.headers = [];
      if (this.teaserTwoVideo.is_live === 0) {
        options.headers['Title'] = this.encrypt();
      }
    };
    this.teaserTwoPlayer = videoJs(teaser_two, {
      controls: false,
      loop: true,
      preload: 'auto',
      muted: this.isTeaserTwoMuted === true ? true : false,
      fluid: true,
      autoplay: false,
      progressControl: {
        seekBar: false
      },
      html5: {
        hls: {
          customDrm: {
            keys: 'MjMyOGMwYWM4MmNhM2ZjNmY4ZDcyNzI0NWQzMDgzYmUxMzU3ZjA0NGZmNWNiNGMzYTExNDJiMDkzY2YyZjNjOTAwNTM0MjE4NTUxMmM5ODlhMWYwMzI3YjQwYmY3YmY1YjE2MDBkMTYzYTA1ZTFkYzE4OWJhMjUwOGM1MTIwODksNDg3MGVjMDY0YzEwODE0MmNjMGZmYmQ2ZmZkZjFlMDhlNzU2YTZkZWYyYzc4MDQ2ODlmZTRhZTBmYzk4NTIyMSxiMmJmNWE5NzAwNGJiMzFkNmY1YTljMmM4NDQ0OTc1OQ'
          }
        }
      }
    });
    this.teaserTwoPlayer.src({
      src: this.teaserTwoVideo.hls_playlist_url,
      type: 'application/x-mpegURL'
    });
    this.getTeaserThree();
  }
  teaserTwoplayPauseVideo() {
    if (this.teaserTwoPlayer.paused()) {
      this.teaserTwoPlayer.play();
    } else {
      this.teaserTwoPlayer.pause();
    }
  }
  setTeaserThreeConfig() {
    const teaser_three = document.getElementById('teaser_three');
    videoJs.Hls.xhr.beforeRequest = (options) => {
      options.headers = [];
      if (this.teaserThreeVideo.is_live === 0) {
        options.headers['Title'] = this.encrypt();
      }
    };
    this.teaserThreePlayer = videoJs(teaser_three, {
      controls: false,
      loop: true,
      preload: 'auto',
      muted: this.isTeaserThreeMuted === true ? true : false,
      fluid: true,
      autoplay: false,
      progressControl: {
        seekBar: false
      },
      html5: {
        hls: {
          customDrm: {
            keys: 'MjMyOGMwYWM4MmNhM2ZjNmY4ZDcyNzI0NWQzMDgzYmUxMzU3ZjA0NGZmNWNiNGMzYTExNDJiMDkzY2YyZjNjOTAwNTM0MjE4NTUxMmM5ODlhMWYwMzI3YjQwYmY3YmY1YjE2MDBkMTYzYTA1ZTFkYzE4OWJhMjUwOGM1MTIwODksNDg3MGVjMDY0YzEwODE0MmNjMGZmYmQ2ZmZkZjFlMDhlNzU2YTZkZWYyYzc4MDQ2ODlmZTRhZTBmYzk4NTIyMSxiMmJmNWE5NzAwNGJiMzFkNmY1YTljMmM4NDQ0OTc1OQ'
          }
        }
      }
    });
    this.teaserThreePlayer.src({
      src: this.teaserThreeVideo.hls_playlist_url,
      type: 'application/x-mpegURL'
    });
  }
  teaserThreeplayPauseVideo() {
    if (this.teaserThreePlayer.paused()) {
      this.teaserThreePlayer.play();
    } else {
      this.teaserThreePlayer.pause();
    }
  }
  teaserOneSound() {
    if (this.isTeaserOneMuted) {
      this.teaserOnePlayer.muted(true);
    } else {
      this.teaserOnePlayer.muted(false);
    }
  }
  teaserTwoSound() {
    if (this.isTeaserTwoMuted) {
      this.teaserTwoPlayer.muted(true);
    } else {
      this.teaserTwoPlayer.muted(false);
    }
  }
  teaserThreeSound() {
    if (this.isTeaserThreeMuted) {
      this.teaserThreePlayer.muted(true);
    } else {
      this.teaserThreePlayer.muted(false);
    }
  }
  onTeaserOneVisible(event) {
    if (event.visible === true) {
      this.teaserOnePlayer.play();
    } else if (event.visible === false) {
      this.teaserOnePlayer.pause();
    }
  }
  onTeaserTwoVisible(event) {
    if (event.visible === true) {
      this.teaserTwoPlayer.play();
    } else if (event.visible === false) {
      this.teaserTwoPlayer.pause();
    }
  }
  onTeaserThreeVisible(event) {
    if (event.visible === true) {
      this.teaserThreePlayer.play();
    } else if (event.visible === false) {
      this.teaserThreePlayer.pause();
    }
  }
  slickInit(event) {
    this.isSlickInit = true;
  }
}
